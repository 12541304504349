$primary:   #0d6efd;
$secondary: #6c757d;
$tertiary:  #F18F01;
$success:   #198754;
$info:      #0DCAf0;
$warning:   #FFCA2C;
$danger:    #DC3545;
$light:     #F8F9FA;
$dark:      #212529;
$black:     #000000;
$gray:      #7F7F7F;
$white:     #FFFFFF;

$grid-breakpoints: (
        xxxs: 0,
        xxs: 320px,
        xs: 568px,
        sm: 667px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1600px,
);

$container-max-widths: (
        xxxs: 0,
        xxs: 320px,
        xs: 568px,
        sm: 667px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1600px,
);

/* Shades  /
**********/
$primary-light: lighten($primary, 40%);
$primary-100: lighten($primary, 32%);
$primary-200: lighten($primary, 25%);
$primary-300: lighten($primary, 17%);
$primary-400: lighten($primary, 10%);
$primary-500: $primary;
$primary-600: darken($primary, 10%);
$primary-700: darken($primary, 17%);
$primary-800: darken($primary, 25%);
$primary-900: darken($primary, 32%);
$primary-dark: darken($primary, 40%);

$secondary-light: lighten($secondary, 40%);
$secondary-100: lighten($secondary, 32%);
$secondary-200: lighten($secondary, 25%);
$secondary-300: lighten($secondary, 17%);
$secondary-400: lighten($secondary, 10%);
$secondary-500: $secondary;
$secondary-600: darken($secondary, 10%);
$secondary-700: darken($secondary, 17%);
$secondary-800: darken($secondary, 25%);
$secondary-900: darken($secondary, 32%);
$secondary-dark: darken($secondary, 40%);

$tertiary-light: lighten($tertiary, 40%);
$tertiary-100: lighten($tertiary, 32%);
$tertiary-200: lighten($tertiary, 25%);
$tertiary-300: lighten($tertiary, 17%);
$tertiary-400: lighten($tertiary, 10%);
$tertiary-500: $tertiary;
$tertiary-600: darken($tertiary, 10%);
$tertiary-700: darken($tertiary, 17%);
$tertiary-800: darken($tertiary, 25%);
$tertiary-900: darken($tertiary, 32%);
$tertiary-dark: darken($tertiary, 40%);

$info-light: lighten($info, 40%);
$info-100: lighten($info, 32%);
$info-200: lighten($info, 25%);
$info-300: lighten($info, 17%);
$info-400: lighten($info, 10%);
$info-500: $info;
$info-600: darken($info, 10%);
$info-700: darken($info, 17%);
$info-800: darken($info, 25%);
$info-900: darken($info, 32%);
$info-dark: darken($info, 40%);

$success-light: lighten($success, 40%);
$success-100: lighten($success, 32%);
$success-200: lighten($success, 25%);
$success-300: lighten($success, 17%);
$success-400: lighten($success, 10%);
$success-500: $success;
$success-600: darken($success, 10%);
$success-700: darken($success, 17%);
$success-800: darken($success, 25%);
$success-900: darken($success, 32%);
$success-dark: darken($success, 40%);

$warning-light: lighten($warning, 40%);
$warning-100: lighten($warning, 32%);
$warning-200: lighten($warning, 25%);
$warning-300: lighten($warning, 17%);
$warning-400: lighten($warning, 10%);
$warning-500: $warning;
$warning-600: darken($warning, 10%);
$warning-700: darken($warning, 17%);
$warning-800: darken($warning, 25%);
$warning-900: darken($warning, 32%);
$warning-dark: darken($warning, 40%);

$danger-light: lighten($danger, 40%);
$danger-100: lighten($danger, 32%);
$danger-200: lighten($danger, 25%);
$danger-300: lighten($danger, 17%);
$danger-400: lighten($danger, 10%);
$danger-500: $danger;
$danger-600: darken($danger, 10%);
$danger-700: darken($danger, 17%);
$danger-800: darken($danger, 25%);
$danger-900: darken($danger, 32%);
$danger-dark: darken($danger, 40%);

$gray-light: lighten($gray, 40%);
$gray-100: lighten($gray, 32%);
$gray-200: lighten($gray, 25%);
$gray-300: lighten($gray, 17%);
$gray-400: lighten($gray, 10%);
$gray-500: $gray;
$gray-600: darken($gray, 10%);
$gray-700: darken($gray, 17%);
$gray-800: darken($gray, 25%);
$gray-900: darken($gray, 32%);
$gray-dark: darken($gray, 40%);

$theme-colors: (
        "primary": $primary,
        "primary-light": $primary-light,
        "primary-1": $primary-100,
        "primary-2": $primary-200,
        "primary-3": $primary-300,
        "primary-4": $primary-400,
        "primary-5": $primary-500,
        "primary-6": $primary-600,
        "primary-7": $primary-700,
        "primary-8": $primary-800,
        "primary-9": $primary-900,
        "primary-dark": $primary-dark,
        "secondary": $secondary,
        "secondary-light": $secondary-light,
        "secondary-1": $secondary-100,
        "secondary-2": $secondary-200,
        "secondary-3": $secondary-300,
        "secondary-4": $secondary-400,
        "secondary-5": $secondary-500,
        "secondary-6": $secondary-600,
        "secondary-7": $secondary-700,
        "secondary-8": $secondary-800,
        "secondary-9": $secondary-900,
        "secondary-dark": $secondary-dark,
        "tertiary": $tertiary,
        "tertiary-light": $tertiary-light,
        "tertiary-1": $tertiary-100,
        "tertiary-2": $tertiary-200,
        "tertiary-3": $tertiary-300,
        "tertiary-4": $tertiary-400,
        "tertiary-5": $tertiary-500,
        "tertiary-6": $tertiary-600,
        "tertiary-7": $tertiary-700,
        "tertiary-8": $tertiary-800,
        "tertiary-9": $tertiary-900,
        "tertiary-dark": $tertiary-dark,
        "success": $success,
        "success-light": $success-light,
        "success-1": $success-100,
        "success-2": $success-200,
        "success-3": $success-300,
        "success-4": $success-400,
        "success-5": $success-500,
        "success-6": $success-600,
        "success-7": $success-700,
        "success-8": $success-800,
        "success-9": $success-900,
        "success-dark": $success-dark,
        "info": $info,
        "info-light": $info-light,
        "info-1": $info-100,
        "info-2": $info-200,
        "info-3": $info-300,
        "info-4": $info-400,
        "info-5": $info-500,
        "info-6": $info-600,
        "info-7": $info-700,
        "info-8": $info-800,
        "info-9": $info-900,
        "info-dark": $info-dark,
        "warning": $warning,
        "warning-light": $warning-light,
        "warning-1": $warning-100,
        "warning-2": $warning-200,
        "warning-3": $warning-300,
        "warning-4": $warning-400,
        "warning-5": $warning-500,
        "warning-6": $warning-600,
        "warning-7": $warning-700,
        "warning-8": $warning-800,
        "warning-9": $warning-900,
        "warning-dark": $warning-dark,
        "danger": $danger,
        "danger-light": $danger-light,
        "danger-1": $danger-100,
        "danger-2": $danger-200,
        "danger-3": $danger-300,
        "danger-4": $danger-400,
        "danger-5": $danger-500,
        "danger-6": $danger-600,
        "danger-7": $danger-700,
        "danger-8": $danger-800,
        "danger-9": $danger-900,
        "danger-dark": $danger-dark,
        "gray": $gray,
        "gray-light": $gray-light,
        "gray-1": $gray-100,
        "gray-2": $gray-200,
        "gray-3": $gray-300,
        "gray-4": $gray-400,
        "gray-5": $gray-500,
        "gray-6": $gray-600,
        "gray-7": $gray-700,
        "gray-8": $gray-800,
        "gray-9": $gray-900,
        "gray-dark": $gray-dark,
        "light": $light,
        "dark": $dark,
        "white": $white,
        "black": $black,
);

/* Breakpoint "hidden" utility classes  /
************************************************/

/* Breakpoint XXXS */
@media (max-width: 319px) {
  .hidden-xxxs-down,
  .hidden-xxs-down,
  .hidden-xs-down,
  .hidden-sm-down,
  .hidden-md-down,
  .hidden-lg-down,
  .hidden-xl-down,
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-unless-xxs,
  .hidden-unless-xs,
  .hidden-unless-sm,
  .hidden-unless-md,
  .hidden-unless-lg,
  .hidden-unless-xl,
  .hidden-unless-xxl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint XXS */
@media (min-width: 320px) and (max-width: 567px) {
  .hidden-xxs-down,
  .hidden-xs-down,
  .hidden-sm-down,
  .hidden-md-down,
  .hidden-lg-down,
  .hidden-xl-down,
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-unless-xxxs,
  .hidden-unless-xs,
  .hidden-unless-sm,
  .hidden-unless-md,
  .hidden-unless-lg,
  .hidden-unless-xl,
  .hidden-unless-xxl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint XS */
@media (min-width: 568px) and (max-width: 666px) {
  .hidden-xs-down,
  .hidden-sm-down,
  .hidden-md-down,
  .hidden-lg-down,
  .hidden-xl-down,
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-xs-up,
  .hidden-unless-xxxs,
  .hidden-unless-xxs,
  .hidden-unless-sm,
  .hidden-unless-md,
  .hidden-unless-lg,
  .hidden-unless-xl,
  .hidden-unless-xxl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint SM */
@media (min-width: 667px) and (max-width: 767px) {
  .hidden-sm-down,
  .hidden-md-down,
  .hidden-lg-down,
  .hidden-xl-down,
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-unless-xxxs,
  .hidden-unless-xxs,
  .hidden-unless-xs,
  .hidden-unless-md,
  .hidden-unless-lg,
  .hidden-unless-xl,
  .hidden-unless-xxl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md-down,
  .hidden-lg-down,
  .hidden-xl-down,
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,
  .hidden-unless-xxxs,
  .hidden-unless-xxs,
  .hidden-unless-xs,
  .hidden-unless-sm,
  .hidden-unless-lg,
  .hidden-unless-xl,
  .hidden-unless-xxl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg-down,
  .hidden-xl-down,
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,
  .hidden-lg-up,
  .hidden-unless-xxxs,
  .hidden-unless-xxs,
  .hidden-unless-xs,
  .hidden-unless-sm,
  .hidden-unless-md,
  .hidden-unless-xl,
  .hidden-unless-xxl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint XL */
@media (min-width: 1200px) and (max-width: 1439px) {
  .hidden-xl-down,
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,
  .hidden-lg-up,
  .hidden-xl-up,
  .hidden-unless-xxxs,
  .hidden-unless-xxs,
  .hidden-unless-xs,
  .hidden-unless-sm,
  .hidden-unless-md,
  .hidden-unless-lg,
  .hidden-unless-xxl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint XXL */
@media (min-width: 1440px) and (max-width: 1599px) {
  .hidden-xxl-down,
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,
  .hidden-lg-up,
  .hidden-xl-up,
  .hidden-xxl-up,
  .hidden-unless-xxxs,
  .hidden-unless-xxs,
  .hidden-unless-xs,
  .hidden-unless-sm,
  .hidden-unless-md,
  .hidden-unless-lg,
  .hidden-unless-xl,
  .hidden-unless-xxxl {
    display: none !important;
  }
}

/* Breakpoint XXXL */
@media (min-width: 1600px) {
  .hidden-xxxl-down,
  .hidden-xxxs-up,
  .hidden-xxs-up,
  .hidden-xs-up,
  .hidden-sm-up,
  .hidden-md-up,
  .hidden-lg-up,
  .hidden-xl-up,
  .hidden-xxl-up,
  .hidden-xxxl-up,
  .hidden-unless-xxxs,
  .hidden-unless-xxs,
  .hidden-unless-xs,
  .hidden-unless-sm,
  .hidden-unless-md,
  .hidden-unless-lg,
  .hidden-unless-xl,
  .hidden-unless-xxl {
    display: none !important;
  }
}
