header {
  position: fixed;

  top: 0px;
  left: 0px;
  right: 0px;

  max-width: 100%;
  z-index:999;

  background-color: white;
  background-attachment: fixed;
  background-size: cover;
}

.nav-link.focused{
  font-weight: bold;
}

.skip-nav-link {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #916DD5;
  color: #FFFFFF;
  text-decoration: none;
  border: 2px solid #000;
  transition: background-color 1s ease;
  position: absolute;
  font-weight: 500;
  background-color: #fff8;
  transform: translateY(-120%);
}
.skip-nav-link:hover,
.skip-nav-link:focus {
  color: white;
  margin-left: 1vw;
  margin-top: 1vw;
  background-color: #000;
  transform: translateY(0);
}

.nav-container
{
  width:100%;
  height:100%;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr max-content 1fr;
  display: grid;
}
@media (max-width: 1260px) {
  header {
    position: unset;
  }
}

.nav-side {
  display: flex;
  justify-content: space-around;
}

.nav-side-left
{
  justify-content: end;
}
.nav-side-left > a
{
  margin-inline:auto;
}


.nav-side-right
{
  justify-content: start;
}

.nav-side-right > a
{
  margin-inline:auto;
}

.nav-item
{
  grid-column: span 2;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  text-transform: uppercase;
}

@media screen and (min-width: 1200px) {
  .navbar-nav .nav-link {
    font-size: 1.0rem;
  }
}

@media screen and (min-width: 1350px) {
  .navbar-nav .nav-link {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1450px) {
  .navbar-nav .nav-link {
    font-size: 1.2rem;
  }
}

@media screen and (min-width:1610px)
{
  .navbar-nav .nav-link {
    font-size: 1.28rem;
  }
}

@media screen and (min-width:1690px)
{
  .navbar-nav .nav-link {
    font-size: 1.56rem;
    color: #000;
  }
}


.main-navbar {
  background-color: rgba(245,245,245,0.6);
  padding-inline: 8vw;

  background-color:#FFFFFF;

  .navbar-nav {
    height: 100%;
    list-style-type: none;

    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
  img
  {
    height: 42px;
  }
}

.nav-item {

  a {
    color: #{$clr_gray};
    font-weight: 300;
    font-size: #{$fs_h3};
    font-family: #{$font-stack};
    text-transform: uppercase;
    border-bottom: 3px solid transparent;

    &:hover {
      border-bottom: 3px solid transparent;
      color: #{$clr_dark_blue};
    }
  }
}

/*Mobile Menu*/
/*****************************************************************************************************/
.mobile-nav-menu {
  display:none;
  position:fixed;
  background-color: #916DD5;
  left: 0px;
  right: 0px;
  z-index: 999;
  flex-direction:column;
  height:50vh;
  height: min-content;
  padding-block: 4rem;

  .mobile-nav-item{
    text-align:center;
    width:100%;

    color: #000;

    font-size: 4vw;
    font-weight: 700;
    text-transform: uppercase;

    padding-top:20px;
    padding-bottom:20px;
  }
}

.mobile-nav-item.focused {
  text-align:center;
  width:100%;

  color: #482e6e;

  font-size: 5vw;
  font-weight: 800;
  text-transform: uppercase;

  padding-top:20px;
  padding-bottom:20px;
}

@media (min-width: 1261px) {
  .mobile-nav-menu {
    display: none;
    position: unset;
  }
}
.mobile-close{
  display:none;
}
.mobile-button, .mobile-close{
  position: absolute;
  top: 2rem;
  right: 2rem;

  height:25px;
  width:25px;
  padding-top:0px;
  z-index:1000;

  cursor:pointer;

  img{
    width:100%;
  }
}
.mobile-brand {
  position: absolute;
  top: 2.5rem;
  left: calc(50% - 100px);
}

@media screen and (max-width:1260px){
  .main-navbar {
    .navbar-nav {
      display: none;
    }
  }
  header {
    background-color: transparent;
    background-image: none;
    height: #{$header_mobile_height};
  }
}

@media screen and (max-width:767px){
  .navbar-brand img {
    max-width:80%;
  }
}

@media screen and (max-width:376px) {

}

body header {
  .mobile-brand.dark,
  .navbar-brand.dark {
    display: none;
  }

  .mobile-brand.light,
  .navbar-brand.light {
    display: block
  }

  .nav-link {
    color: #{$clr_black};
    transition: color 200ms ease-in-out;
  }
}

header {
  .nav-link {
    color: #{$clr_black};
  }
  .nav-link:hover {
    filter: opacity(.9);
  }
}

@media (min-width: 992px) {
  header {
    .nav-item + .nav-item {
      margin-inline-start: 1rem;
    }
  }
}
