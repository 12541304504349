/*Footer*/
/*****************************************************************************************************/
#footer{
  position:relative;
  bottom:0;
  width:100%;

  height: #{$footer_height};
  margin-top:0;

  max-width:100vw;

  font-size: #{$fs_small_text};
  font-weight: 700;
  color: #{$clr_black};

  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;

  * {
    text-underline-position: under;
  }

  .footer-link.focused {
    font-weight: bold;
  }

  .footer-content{
    max-width:100vw;
  }

  a{
    font-size: #{$fs_small_text};
    font-weight: 700;
    color: #{$clr_black};
    transition: color 200ms ease-in-out;
    &:hover{
      filter: opacity(.9);
      text-decoration: none;
    }
  }

  .footer-logo {
    width:100%;

    img{
      height: 100px;
    }
  }

  .footer-links{
    margin-top:25px;
    display:flex;
    flex-direction:row;
    justify-content: center;

    .footer-link{
      padding: 0 25px;
    }
  }

  .footer-copy{
    p {
      font-size: 0.9375rem;
      color: #{$clr_black};
      font-weight:400;
      width:100%;
      margin-bottom:0px;
    }

    a {
      font-size: 0.9375rem;
      font-weight:400;
      text-decoration:underline;
    }

  }
}

@media screen and (max-width:1060px){
  #footer{
    height:#{$footer_mobile_height};

    .footer-content{
      max-width:90vw;
    }

    .footer-links{
      flex-direction:column;
      .footer-link{
        padding: 0;
      }
    }

    .footer-copy {
      p {
        width: 100%;
      }
    }
  }
}

body.light-bg footer#footer {
  a:hover {
    filter: opacity(.9);
  }
}
