@charset "UTF-8";
/* Bootstrap */
/* Shades  /
**********/
/* Breakpoint "hidden" utility classes  /
************************************************/
/* Breakpoint XXXS */
@media (max-width: 319px) {
  .hidden-xxxs-down,
.hidden-xxs-down,
.hidden-xs-down,
.hidden-sm-down,
.hidden-md-down,
.hidden-lg-down,
.hidden-xl-down,
.hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-unless-xxs,
.hidden-unless-xs,
.hidden-unless-sm,
.hidden-unless-md,
.hidden-unless-lg,
.hidden-unless-xl,
.hidden-unless-xxl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint XXS */
@media (min-width: 320px) and (max-width: 567px) {
  .hidden-xxs-down,
.hidden-xs-down,
.hidden-sm-down,
.hidden-md-down,
.hidden-lg-down,
.hidden-xl-down,
.hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-unless-xxxs,
.hidden-unless-xs,
.hidden-unless-sm,
.hidden-unless-md,
.hidden-unless-lg,
.hidden-unless-xl,
.hidden-unless-xxl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint XS */
@media (min-width: 568px) and (max-width: 666px) {
  .hidden-xs-down,
.hidden-sm-down,
.hidden-md-down,
.hidden-lg-down,
.hidden-xl-down,
.hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-xs-up,
.hidden-unless-xxxs,
.hidden-unless-xxs,
.hidden-unless-sm,
.hidden-unless-md,
.hidden-unless-lg,
.hidden-unless-xl,
.hidden-unless-xxl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint SM */
@media (min-width: 667px) and (max-width: 767px) {
  .hidden-sm-down,
.hidden-md-down,
.hidden-lg-down,
.hidden-xl-down,
.hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-xs-up,
.hidden-sm-up,
.hidden-unless-xxxs,
.hidden-unless-xxs,
.hidden-unless-xs,
.hidden-unless-md,
.hidden-unless-lg,
.hidden-unless-xl,
.hidden-unless-xxl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md-down,
.hidden-lg-down,
.hidden-xl-down,
.hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-xs-up,
.hidden-sm-up,
.hidden-md-up,
.hidden-unless-xxxs,
.hidden-unless-xxs,
.hidden-unless-xs,
.hidden-unless-sm,
.hidden-unless-lg,
.hidden-unless-xl,
.hidden-unless-xxl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg-down,
.hidden-xl-down,
.hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-xs-up,
.hidden-sm-up,
.hidden-md-up,
.hidden-lg-up,
.hidden-unless-xxxs,
.hidden-unless-xxs,
.hidden-unless-xs,
.hidden-unless-sm,
.hidden-unless-md,
.hidden-unless-xl,
.hidden-unless-xxl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint XL */
@media (min-width: 1200px) and (max-width: 1439px) {
  .hidden-xl-down,
.hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-xs-up,
.hidden-sm-up,
.hidden-md-up,
.hidden-lg-up,
.hidden-xl-up,
.hidden-unless-xxxs,
.hidden-unless-xxs,
.hidden-unless-xs,
.hidden-unless-sm,
.hidden-unless-md,
.hidden-unless-lg,
.hidden-unless-xxl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint XXL */
@media (min-width: 1440px) and (max-width: 1599px) {
  .hidden-xxl-down,
.hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-xs-up,
.hidden-sm-up,
.hidden-md-up,
.hidden-lg-up,
.hidden-xl-up,
.hidden-xxl-up,
.hidden-unless-xxxs,
.hidden-unless-xxs,
.hidden-unless-xs,
.hidden-unless-sm,
.hidden-unless-md,
.hidden-unless-lg,
.hidden-unless-xl,
.hidden-unless-xxxl {
    display: none !important;
  }
}
/* Breakpoint XXXL */
@media (min-width: 1600px) {
  .hidden-xxxl-down,
.hidden-xxxs-up,
.hidden-xxs-up,
.hidden-xs-up,
.hidden-sm-up,
.hidden-md-up,
.hidden-lg-up,
.hidden-xl-up,
.hidden-xxl-up,
.hidden-xxxl-up,
.hidden-unless-xxxs,
.hidden-unless-xxs,
.hidden-unless-xs,
.hidden-unless-sm,
.hidden-unless-md,
.hidden-unless-lg,
.hidden-unless-xl,
.hidden-unless-xxl {
    display: none !important;
  }
}
/*!
 * Bootstrap Utilities v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #FFFFFF;
  --bs-gray: #666666;
  --bs-gray-dark: #3f3f3f;
  --bs-gray-100: #d1d1d1;
  --bs-gray-200: #bfbfbf;
  --bs-gray-300: #aaaaaa;
  --bs-gray-400: #999999;
  --bs-gray-500: #7F7F7F;
  --bs-gray-600: #666666;
  --bs-gray-700: #545454;
  --bs-gray-800: #3f3f3f;
  --bs-gray-900: #2d2d2d;
  --bs-primary: #0d6efd;
  --bs-primary-light: #d7e7ff;
  --bs-primary-1: #afcffe;
  --bs-primary-2: #8bbafe;
  --bs-primary-3: #63a2fe;
  --bs-primary-4: #408cfd;
  --bs-primary-5: #0d6efd;
  --bs-primary-6: #0257d5;
  --bs-primary-7: #0149b2;
  --bs-primary-8: #013889;
  --bs-primary-9: #012a66;
  --bs-primary-dark: #01193d;
  --bs-secondary: #6c757d;
  --bs-secondary-light: #d8dbdd;
  --bs-secondary-1: #c2c6ca;
  --bs-secondary-2: #afb5ba;
  --bs-secondary-3: #99a0a7;
  --bs-secondary-4: #868e96;
  --bs-secondary-5: #6c757d;
  --bs-secondary-6: #545b62;
  --bs-secondary-7: #44494e;
  --bs-secondary-8: #313539;
  --bs-secondary-9: #202325;
  --bs-secondary-dark: #0d0f10;
  --bs-tertiary: #F18F01;
  --bs-tertiary-light: #ffe5bf;
  --bs-tertiary-1: #ffd497;
  --bs-tertiary-2: #fec673;
  --bs-tertiary-3: #feb54a;
  --bs-tertiary-4: #fea627;
  --bs-tertiary-5: #F18F01;
  --bs-tertiary-6: #be7101;
  --bs-tertiary-7: #9b5c01;
  --bs-tertiary-8: #724400;
  --bs-tertiary-9: #4e2f00;
  --bs-tertiary-dark: #261600;
  --bs-success: #198754;
  --bs-success-light: #84e8ba;
  --bs-success-1: #61e2a6;
  --bs-success-2: #43dc95;
  --bs-success-3: #27d082;
  --bs-success-4: #21b26f;
  --bs-success-5: #198754;
  --bs-success-6: #115c39;
  --bs-success-7: #0b3e26;
  --bs-success-8: #051b11;
  --bs-success-9: black;
  --bs-success-dark: black;
  --bs-info: #0DCAf0;
  --bs-info-light: #cdf4fc;
  --bs-info-1: #a6ecfa;
  --bs-info-2: #84e5f8;
  --bs-info-3: #5dddf6;
  --bs-info-4: #3cd5f4;
  --bs-info-5: #0DCAf0;
  --bs-info-6: #0aa1c0;
  --bs-info-7: #09859e;
  --bs-info-8: #066477;
  --bs-info-9: #054855;
  --bs-info-dark: #03272e;
  --bs-warning: #FFCA2C;
  --bs-warning-light: #fffdf8;
  --bs-warning-1: #fff3cf;
  --bs-warning-2: #ffeaac;
  --bs-warning-3: #ffe083;
  --bs-warning-4: #ffd75f;
  --bs-warning-5: #FFCA2C;
  --bs-warning-6: #f8ba00;
  --bs-warning-7: #d49f00;
  --bs-warning-8: #ac8000;
  --bs-warning-9: #886600;
  --bs-warning-dark: #5f4700;
  --bs-danger: #DC3545;
  --bs-danger-light: #fae3e5;
  --bs-danger-1: #f4c0c5;
  --bs-danger-2: #efa2a9;
  --bs-danger-3: #e97f89;
  --bs-danger-4: #e4606d;
  --bs-danger-5: #DC3545;
  --bs-danger-6: #bd2130;
  --bs-danger-7: #9f1c28;
  --bs-danger-8: #7c151f;
  --bs-danger-9: #5e1018;
  --bs-danger-dark: #3b0a0f;
  --bs-gray: #7F7F7F;
  --bs-gray-light: #e5e5e5;
  --bs-gray-1: #d1d1d1;
  --bs-gray-2: #bfbfbf;
  --bs-gray-3: #aaaaaa;
  --bs-gray-4: #999999;
  --bs-gray-5: #7F7F7F;
  --bs-gray-6: #666666;
  --bs-gray-7: #545454;
  --bs-gray-8: #3f3f3f;
  --bs-gray-9: #2d2d2d;
  --bs-gray-dark: #191919;
  --bs-light: #F8F9FA;
  --bs-dark: #212529;
  --bs-white: #FFFFFF;
  --bs-black: #000000;
  --bs-primary-rgb: 13, 110, 253;
  --bs-primary-light-rgb: 215, 231, 255;
  --bs-primary-1-rgb: 175, 207, 254;
  --bs-primary-2-rgb: 139, 186, 254;
  --bs-primary-3-rgb: 99, 162, 254;
  --bs-primary-4-rgb: 64, 140, 253;
  --bs-primary-5-rgb: 13, 110, 253;
  --bs-primary-6-rgb: 2, 87, 213;
  --bs-primary-7-rgb: 1, 73, 178;
  --bs-primary-8-rgb: 1, 56, 137;
  --bs-primary-9-rgb: 1, 42, 102;
  --bs-primary-dark-rgb: 1, 25, 61;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-secondary-light-rgb: 216, 219, 221;
  --bs-secondary-1-rgb: 194, 198, 202;
  --bs-secondary-2-rgb: 175, 181, 186;
  --bs-secondary-3-rgb: 153, 160, 167;
  --bs-secondary-4-rgb: 134, 142, 150;
  --bs-secondary-5-rgb: 108, 117, 125;
  --bs-secondary-6-rgb: 84, 91, 98;
  --bs-secondary-7-rgb: 68, 73, 78;
  --bs-secondary-8-rgb: 49, 53, 57;
  --bs-secondary-9-rgb: 32, 35, 37;
  --bs-secondary-dark-rgb: 13, 15, 16;
  --bs-tertiary-rgb: 241, 143, 1;
  --bs-tertiary-light-rgb: 255, 229, 191;
  --bs-tertiary-1-rgb: 255, 212, 151;
  --bs-tertiary-2-rgb: 254, 198, 115;
  --bs-tertiary-3-rgb: 254, 181, 74;
  --bs-tertiary-4-rgb: 254, 166, 39;
  --bs-tertiary-5-rgb: 241, 143, 1;
  --bs-tertiary-6-rgb: 190, 113, 1;
  --bs-tertiary-7-rgb: 155, 92, 1;
  --bs-tertiary-8-rgb: 114, 68, 0;
  --bs-tertiary-9-rgb: 78, 47, 0;
  --bs-tertiary-dark-rgb: 38, 22, 0;
  --bs-success-rgb: 25, 135, 84;
  --bs-success-light-rgb: 132, 232, 186;
  --bs-success-1-rgb: 97, 226, 166;
  --bs-success-2-rgb: 67, 220, 149;
  --bs-success-3-rgb: 39, 208, 130;
  --bs-success-4-rgb: 33, 178, 111;
  --bs-success-5-rgb: 25, 135, 84;
  --bs-success-6-rgb: 17, 92, 57;
  --bs-success-7-rgb: 11, 62, 38;
  --bs-success-8-rgb: 5, 27, 17;
  --bs-success-9-rgb: 0, 0, 0;
  --bs-success-dark-rgb: 0, 0, 0;
  --bs-info-rgb: 13, 202, 240;
  --bs-info-light-rgb: 205, 244, 252;
  --bs-info-1-rgb: 166, 236, 250;
  --bs-info-2-rgb: 132, 229, 248;
  --bs-info-3-rgb: 93, 221, 246;
  --bs-info-4-rgb: 60, 213, 244;
  --bs-info-5-rgb: 13, 202, 240;
  --bs-info-6-rgb: 10, 161, 192;
  --bs-info-7-rgb: 9, 133, 158;
  --bs-info-8-rgb: 6, 100, 119;
  --bs-info-9-rgb: 5, 72, 85;
  --bs-info-dark-rgb: 3, 39, 46;
  --bs-warning-rgb: 255, 202, 44;
  --bs-warning-light-rgb: 255, 253, 248;
  --bs-warning-1-rgb: 255, 243, 207;
  --bs-warning-2-rgb: 255, 234, 172;
  --bs-warning-3-rgb: 255, 224, 131;
  --bs-warning-4-rgb: 255, 215, 95;
  --bs-warning-5-rgb: 255, 202, 44;
  --bs-warning-6-rgb: 248, 186, 0;
  --bs-warning-7-rgb: 212, 159, 0;
  --bs-warning-8-rgb: 172, 128, 0;
  --bs-warning-9-rgb: 136, 102, 0;
  --bs-warning-dark-rgb: 95, 71, 0;
  --bs-danger-rgb: 220, 53, 69;
  --bs-danger-light-rgb: 250, 227, 229;
  --bs-danger-1-rgb: 244, 192, 197;
  --bs-danger-2-rgb: 239, 162, 169;
  --bs-danger-3-rgb: 233, 127, 137;
  --bs-danger-4-rgb: 228, 96, 109;
  --bs-danger-5-rgb: 220, 53, 69;
  --bs-danger-6-rgb: 189, 33, 48;
  --bs-danger-7-rgb: 159, 28, 40;
  --bs-danger-8-rgb: 124, 21, 31;
  --bs-danger-9-rgb: 94, 16, 24;
  --bs-danger-dark-rgb: 59, 10, 15;
  --bs-gray-rgb: 127, 127, 127;
  --bs-gray-light-rgb: 229, 229, 229;
  --bs-gray-1-rgb: 209, 209, 209;
  --bs-gray-2-rgb: 191, 191, 191;
  --bs-gray-3-rgb: 170, 170, 170;
  --bs-gray-4-rgb: 153, 153, 153;
  --bs-gray-5-rgb: 127, 127, 127;
  --bs-gray-6-rgb: 102, 102, 102;
  --bs-gray-7-rgb: 84, 84, 84;
  --bs-gray-8-rgb: 63, 63, 63;
  --bs-gray-9-rgb: 45, 45, 45;
  --bs-gray-dark-rgb: 25, 25, 25;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #665112;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #545454;
  --bs-dark-text-emphasis: #545454;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff4d5;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #e8e8e8;
  --bs-dark-bg-subtle: #999999;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffeaab;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #bfbfbf;
  --bs-dark-border-subtle: #7F7F7F;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #2d2d2d;
  --bs-body-color-rgb: 45, 45, 45;
  --bs-body-bg: #FFFFFF;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(45, 45, 45, 0.75);
  --bs-secondary-color-rgb: 45, 45, 45;
  --bs-secondary-bg: #bfbfbf;
  --bs-secondary-bg-rgb: 191, 191, 191;
  --bs-tertiary-color: rgba(45, 45, 45, 0.5);
  --bs-tertiary-color-rgb: 45, 45, 45;
  --bs-tertiary-bg: #d1d1d1;
  --bs-tertiary-bg-rgb: 209, 209, 209;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #2d2d2d;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #aaaaaa;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #DC3545;
  --bs-form-invalid-border-color: #DC3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #aaaaaa;
  --bs-body-color-rgb: 170, 170, 170;
  --bs-body-bg: #2d2d2d;
  --bs-body-bg-rgb: 45, 45, 45;
  --bs-emphasis-color: #FFFFFF;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(170, 170, 170, 0.75);
  --bs-secondary-color-rgb: 170, 170, 170;
  --bs-secondary-bg: #3f3f3f;
  --bs-secondary-bg-rgb: 63, 63, 63;
  --bs-tertiary-color: rgba(170, 170, 170, 0.5);
  --bs-tertiary-color-rgb: 170, 170, 170;
  --bs-tertiary-bg: #363636;
  --bs-tertiary-bg-rgb: 54, 54, 54;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffdf80;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #d1d1d1;
  --bs-dark-text-emphasis: #aaaaaa;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332809;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #3f3f3f;
  --bs-dark-bg-subtle: #202020;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #99791a;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #545454;
  --bs-dark-border-subtle: #3f3f3f;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #aaaaaa;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #545454;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-primary-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-primary-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-primary-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-primary-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-primary-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-5 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-6 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-7 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-8 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-primary-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-secondary-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-secondary-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-secondary-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-secondary-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-secondary-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-5 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-6 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-7 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-8 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-secondary-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-5 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-6 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tertiary-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-7 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-tertiary-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-8 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-tertiary-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-tertiary-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-tertiary-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-5 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-6 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-7 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-8 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-success-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-5 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-6 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-7 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-8 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-info-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-info-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-info-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-5 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-6 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-7 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-8 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-warning-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-warning-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-5 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-6 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-7 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-8 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-danger-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-1-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-2-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-3 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-3-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-4 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-4-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-5 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-5-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-6 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-6-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-7 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-7-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-8 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-8-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-9 {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-9-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-gray-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #FFFFFF !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-light {
  color: RGBA(var(--bs-primary-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-light:hover, .link-primary-light:focus {
  color: RGBA(223, 236, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(223, 236, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-1 {
  color: RGBA(var(--bs-primary-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-1:hover, .link-primary-1:focus {
  color: RGBA(191, 217, 254, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(191, 217, 254, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-2 {
  color: RGBA(var(--bs-primary-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-2:hover, .link-primary-2:focus {
  color: RGBA(162, 200, 254, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(162, 200, 254, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-3 {
  color: RGBA(var(--bs-primary-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-3:hover, .link-primary-3:focus {
  color: RGBA(130, 181, 254, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(130, 181, 254, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-4 {
  color: RGBA(var(--bs-primary-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-4:hover, .link-primary-4:focus {
  color: RGBA(102, 163, 253, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(102, 163, 253, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-5 {
  color: RGBA(var(--bs-primary-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-5:hover, .link-primary-5:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-6 {
  color: RGBA(var(--bs-primary-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-6:hover, .link-primary-6:focus {
  color: RGBA(2, 70, 170, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(2, 70, 170, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-7 {
  color: RGBA(var(--bs-primary-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-7:hover, .link-primary-7:focus {
  color: RGBA(1, 58, 142, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(1, 58, 142, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-8 {
  color: RGBA(var(--bs-primary-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-8:hover, .link-primary-8:focus {
  color: RGBA(1, 45, 110, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(1, 45, 110, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-9 {
  color: RGBA(var(--bs-primary-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-9:hover, .link-primary-9:focus {
  color: RGBA(1, 34, 82, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(1, 34, 82, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary-dark {
  color: RGBA(var(--bs-primary-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary-dark:hover, .link-primary-dark:focus {
  color: RGBA(1, 20, 49, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(1, 20, 49, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-light {
  color: RGBA(var(--bs-secondary-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-light:hover, .link-secondary-light:focus {
  color: RGBA(224, 226, 228, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(224, 226, 228, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-1 {
  color: RGBA(var(--bs-secondary-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-1:hover, .link-secondary-1:focus {
  color: RGBA(206, 209, 213, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(206, 209, 213, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-2 {
  color: RGBA(var(--bs-secondary-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-2:hover, .link-secondary-2:focus {
  color: RGBA(191, 196, 200, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(191, 196, 200, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-3 {
  color: RGBA(var(--bs-secondary-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-3:hover, .link-secondary-3:focus {
  color: RGBA(173, 179, 185, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(173, 179, 185, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-4 {
  color: RGBA(var(--bs-secondary-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-4:hover, .link-secondary-4:focus {
  color: RGBA(158, 165, 171, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(158, 165, 171, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-5 {
  color: RGBA(var(--bs-secondary-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-5:hover, .link-secondary-5:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-6 {
  color: RGBA(var(--bs-secondary-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-6:hover, .link-secondary-6:focus {
  color: RGBA(67, 73, 78, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(67, 73, 78, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-7 {
  color: RGBA(var(--bs-secondary-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-7:hover, .link-secondary-7:focus {
  color: RGBA(54, 58, 62, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(54, 58, 62, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-8 {
  color: RGBA(var(--bs-secondary-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-8:hover, .link-secondary-8:focus {
  color: RGBA(39, 42, 46, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(39, 42, 46, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-9 {
  color: RGBA(var(--bs-secondary-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-9:hover, .link-secondary-9:focus {
  color: RGBA(26, 28, 30, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 28, 30, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-dark {
  color: RGBA(var(--bs-secondary-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-dark:hover, .link-secondary-dark:focus {
  color: RGBA(10, 12, 13, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 12, 13, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary {
  color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary:hover, .link-tertiary:focus {
  color: RGBA(244, 165, 52, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(244, 165, 52, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-light {
  color: RGBA(var(--bs-tertiary-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-light:hover, .link-tertiary-light:focus {
  color: RGBA(255, 234, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 234, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-1 {
  color: RGBA(var(--bs-tertiary-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-1:hover, .link-tertiary-1:focus {
  color: RGBA(255, 221, 172, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 221, 172, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-2 {
  color: RGBA(var(--bs-tertiary-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-2:hover, .link-tertiary-2:focus {
  color: RGBA(254, 209, 143, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(254, 209, 143, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-3 {
  color: RGBA(var(--bs-tertiary-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-3:hover, .link-tertiary-3:focus {
  color: RGBA(254, 196, 110, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(254, 196, 110, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-4 {
  color: RGBA(var(--bs-tertiary-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-4:hover, .link-tertiary-4:focus {
  color: RGBA(254, 184, 82, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(254, 184, 82, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-5 {
  color: RGBA(var(--bs-tertiary-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-5:hover, .link-tertiary-5:focus {
  color: RGBA(244, 165, 52, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(244, 165, 52, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-6 {
  color: RGBA(var(--bs-tertiary-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-6:hover, .link-tertiary-6:focus {
  color: RGBA(203, 141, 52, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(203, 141, 52, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-7 {
  color: RGBA(var(--bs-tertiary-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-7:hover, .link-tertiary-7:focus {
  color: RGBA(124, 74, 1, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(124, 74, 1, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-8 {
  color: RGBA(var(--bs-tertiary-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-8:hover, .link-tertiary-8:focus {
  color: RGBA(91, 54, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(91, 54, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-9 {
  color: RGBA(var(--bs-tertiary-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-9:hover, .link-tertiary-9:focus {
  color: RGBA(62, 38, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(62, 38, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary-dark {
  color: RGBA(var(--bs-tertiary-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary-dark:hover, .link-tertiary-dark:focus {
  color: RGBA(30, 18, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 18, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-light {
  color: RGBA(var(--bs-success-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-light:hover, .link-success-light:focus {
  color: RGBA(157, 237, 200, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(157, 237, 200, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-1 {
  color: RGBA(var(--bs-success-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-1:hover, .link-success-1:focus {
  color: RGBA(129, 232, 184, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(129, 232, 184, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-2 {
  color: RGBA(var(--bs-success-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-2:hover, .link-success-2:focus {
  color: RGBA(105, 227, 170, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(105, 227, 170, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-3 {
  color: RGBA(var(--bs-success-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-3:hover, .link-success-3:focus {
  color: RGBA(82, 217, 155, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(82, 217, 155, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-4 {
  color: RGBA(var(--bs-success-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-4:hover, .link-success-4:focus {
  color: RGBA(77, 193, 140, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(77, 193, 140, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-5 {
  color: RGBA(var(--bs-success-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-5:hover, .link-success-5:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-6 {
  color: RGBA(var(--bs-success-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-6:hover, .link-success-6:focus {
  color: RGBA(14, 74, 46, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(14, 74, 46, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-7 {
  color: RGBA(var(--bs-success-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-7:hover, .link-success-7:focus {
  color: RGBA(9, 50, 30, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(9, 50, 30, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-8 {
  color: RGBA(var(--bs-success-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-8:hover, .link-success-8:focus {
  color: RGBA(4, 22, 14, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(4, 22, 14, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-9 {
  color: RGBA(var(--bs-success-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-9:hover, .link-success-9:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success-dark {
  color: RGBA(var(--bs-success-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success-dark:hover, .link-success-dark:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-light {
  color: RGBA(var(--bs-info-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-light:hover, .link-info-light:focus {
  color: RGBA(215, 246, 253, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(215, 246, 253, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-1 {
  color: RGBA(var(--bs-info-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-1:hover, .link-info-1:focus {
  color: RGBA(184, 240, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(184, 240, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-2 {
  color: RGBA(var(--bs-info-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-2:hover, .link-info-2:focus {
  color: RGBA(157, 234, 249, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(157, 234, 249, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-3 {
  color: RGBA(var(--bs-info-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-3:hover, .link-info-3:focus {
  color: RGBA(125, 228, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(125, 228, 248, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-4 {
  color: RGBA(var(--bs-info-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-4:hover, .link-info-4:focus {
  color: RGBA(99, 221, 246, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(99, 221, 246, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-5 {
  color: RGBA(var(--bs-info-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-5:hover, .link-info-5:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-6 {
  color: RGBA(var(--bs-info-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-6:hover, .link-info-6:focus {
  color: RGBA(59, 180, 205, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(59, 180, 205, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-7 {
  color: RGBA(var(--bs-info-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-7:hover, .link-info-7:focus {
  color: RGBA(58, 157, 177, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(58, 157, 177, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-8 {
  color: RGBA(var(--bs-info-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-8:hover, .link-info-8:focus {
  color: RGBA(5, 80, 95, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(5, 80, 95, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-9 {
  color: RGBA(var(--bs-info-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-9:hover, .link-info-9:focus {
  color: RGBA(4, 58, 68, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(4, 58, 68, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info-dark {
  color: RGBA(var(--bs-info-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info-dark:hover, .link-info-dark:focus {
  color: RGBA(2, 31, 37, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(2, 31, 37, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 213, 86, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 213, 86, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-light {
  color: RGBA(var(--bs-warning-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-light:hover, .link-warning-light:focus {
  color: RGBA(255, 253, 249, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 253, 249, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-1 {
  color: RGBA(var(--bs-warning-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-1:hover, .link-warning-1:focus {
  color: RGBA(255, 245, 217, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 245, 217, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-2 {
  color: RGBA(var(--bs-warning-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-2:hover, .link-warning-2:focus {
  color: RGBA(255, 238, 189, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 238, 189, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-3 {
  color: RGBA(var(--bs-warning-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-3:hover, .link-warning-3:focus {
  color: RGBA(255, 230, 156, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 230, 156, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-4 {
  color: RGBA(var(--bs-warning-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-4:hover, .link-warning-4:focus {
  color: RGBA(255, 223, 127, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 223, 127, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-5 {
  color: RGBA(var(--bs-warning-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-5:hover, .link-warning-5:focus {
  color: RGBA(255, 213, 86, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 213, 86, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-6 {
  color: RGBA(var(--bs-warning-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-6:hover, .link-warning-6:focus {
  color: RGBA(249, 200, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 200, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-7 {
  color: RGBA(var(--bs-warning-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-7:hover, .link-warning-7:focus {
  color: RGBA(221, 178, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(221, 178, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-8 {
  color: RGBA(var(--bs-warning-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-8:hover, .link-warning-8:focus {
  color: RGBA(189, 153, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(189, 153, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-9 {
  color: RGBA(var(--bs-warning-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-9:hover, .link-warning-9:focus {
  color: RGBA(109, 82, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(109, 82, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning-dark {
  color: RGBA(var(--bs-warning-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning-dark:hover, .link-warning-dark:focus {
  color: RGBA(76, 57, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(76, 57, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-light {
  color: RGBA(var(--bs-danger-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-light:hover, .link-danger-light:focus {
  color: RGBA(251, 233, 234, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(251, 233, 234, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-1 {
  color: RGBA(var(--bs-danger-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-1:hover, .link-danger-1:focus {
  color: RGBA(246, 205, 209, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(246, 205, 209, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-2 {
  color: RGBA(var(--bs-danger-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-2:hover, .link-danger-2:focus {
  color: RGBA(242, 181, 186, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(242, 181, 186, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-3 {
  color: RGBA(var(--bs-danger-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-3:hover, .link-danger-3:focus {
  color: RGBA(237, 153, 161, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(237, 153, 161, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-4 {
  color: RGBA(var(--bs-danger-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-4:hover, .link-danger-4:focus {
  color: RGBA(233, 128, 138, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(233, 128, 138, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-5 {
  color: RGBA(var(--bs-danger-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-5:hover, .link-danger-5:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-6 {
  color: RGBA(var(--bs-danger-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-6:hover, .link-danger-6:focus {
  color: RGBA(151, 26, 38, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(151, 26, 38, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-7 {
  color: RGBA(var(--bs-danger-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-7:hover, .link-danger-7:focus {
  color: RGBA(127, 22, 32, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(127, 22, 32, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-8 {
  color: RGBA(var(--bs-danger-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-8:hover, .link-danger-8:focus {
  color: RGBA(99, 17, 25, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(99, 17, 25, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-9 {
  color: RGBA(var(--bs-danger-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-9:hover, .link-danger-9:focus {
  color: RGBA(75, 13, 19, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(75, 13, 19, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger-dark {
  color: RGBA(var(--bs-danger-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger-dark:hover, .link-danger-dark:focus {
  color: RGBA(47, 8, 12, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(47, 8, 12, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray {
  color: RGBA(var(--bs-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray:hover, .link-gray:focus {
  color: RGBA(153, 153, 153, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(153, 153, 153, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-light {
  color: RGBA(var(--bs-gray-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-light:hover, .link-gray-light:focus {
  color: RGBA(234, 234, 234, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(234, 234, 234, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-1 {
  color: RGBA(var(--bs-gray-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-1:hover, .link-gray-1:focus {
  color: RGBA(218, 218, 218, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(218, 218, 218, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-2 {
  color: RGBA(var(--bs-gray-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-2:hover, .link-gray-2:focus {
  color: RGBA(204, 204, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 204, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-3 {
  color: RGBA(var(--bs-gray-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-3:hover, .link-gray-3:focus {
  color: RGBA(187, 187, 187, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(187, 187, 187, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-4 {
  color: RGBA(var(--bs-gray-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-4:hover, .link-gray-4:focus {
  color: RGBA(173, 173, 173, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(173, 173, 173, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-5 {
  color: RGBA(var(--bs-gray-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-5:hover, .link-gray-5:focus {
  color: RGBA(153, 153, 153, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(153, 153, 153, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-6 {
  color: RGBA(var(--bs-gray-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-6:hover, .link-gray-6:focus {
  color: RGBA(82, 82, 82, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(82, 82, 82, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-7 {
  color: RGBA(var(--bs-gray-7-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-7-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-7:hover, .link-gray-7:focus {
  color: RGBA(67, 67, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(67, 67, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-8 {
  color: RGBA(var(--bs-gray-8-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-8-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-8:hover, .link-gray-8:focus {
  color: RGBA(50, 50, 50, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(50, 50, 50, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-9 {
  color: RGBA(var(--bs-gray-9-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-9-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-9:hover, .link-gray-9:focus {
  color: RGBA(36, 36, 36, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(36, 36, 36, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-dark {
  color: RGBA(var(--bs-gray-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-dark:hover, .link-gray-dark:focus {
  color: RGBA(20, 20, 20, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 20, 20, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-black:hover, .link-black:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 320px) {
  .sticky-xxs-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxs-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 568px) {
  .sticky-xs-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xs-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 667px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1600px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-light {
  --bs-focus-ring-color: rgba(var(--bs-primary-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-1 {
  --bs-focus-ring-color: rgba(var(--bs-primary-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-2 {
  --bs-focus-ring-color: rgba(var(--bs-primary-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-3 {
  --bs-focus-ring-color: rgba(var(--bs-primary-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-4 {
  --bs-focus-ring-color: rgba(var(--bs-primary-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-5 {
  --bs-focus-ring-color: rgba(var(--bs-primary-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-6 {
  --bs-focus-ring-color: rgba(var(--bs-primary-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-7 {
  --bs-focus-ring-color: rgba(var(--bs-primary-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-8 {
  --bs-focus-ring-color: rgba(var(--bs-primary-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-9 {
  --bs-focus-ring-color: rgba(var(--bs-primary-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary-dark {
  --bs-focus-ring-color: rgba(var(--bs-primary-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-light {
  --bs-focus-ring-color: rgba(var(--bs-secondary-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-1 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-2 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-3 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-4 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-5 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-6 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-7 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-8 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-9 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-dark {
  --bs-focus-ring-color: rgba(var(--bs-secondary-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-light {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-1 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-2 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-3 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-4 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-5 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-6 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-7 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-8 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-9 {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary-dark {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-light {
  --bs-focus-ring-color: rgba(var(--bs-success-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-1 {
  --bs-focus-ring-color: rgba(var(--bs-success-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-2 {
  --bs-focus-ring-color: rgba(var(--bs-success-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-3 {
  --bs-focus-ring-color: rgba(var(--bs-success-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-4 {
  --bs-focus-ring-color: rgba(var(--bs-success-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-5 {
  --bs-focus-ring-color: rgba(var(--bs-success-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-6 {
  --bs-focus-ring-color: rgba(var(--bs-success-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-7 {
  --bs-focus-ring-color: rgba(var(--bs-success-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-8 {
  --bs-focus-ring-color: rgba(var(--bs-success-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-9 {
  --bs-focus-ring-color: rgba(var(--bs-success-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success-dark {
  --bs-focus-ring-color: rgba(var(--bs-success-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-light {
  --bs-focus-ring-color: rgba(var(--bs-info-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-1 {
  --bs-focus-ring-color: rgba(var(--bs-info-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-2 {
  --bs-focus-ring-color: rgba(var(--bs-info-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-3 {
  --bs-focus-ring-color: rgba(var(--bs-info-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-4 {
  --bs-focus-ring-color: rgba(var(--bs-info-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-5 {
  --bs-focus-ring-color: rgba(var(--bs-info-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-6 {
  --bs-focus-ring-color: rgba(var(--bs-info-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-7 {
  --bs-focus-ring-color: rgba(var(--bs-info-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-8 {
  --bs-focus-ring-color: rgba(var(--bs-info-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-9 {
  --bs-focus-ring-color: rgba(var(--bs-info-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info-dark {
  --bs-focus-ring-color: rgba(var(--bs-info-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-light {
  --bs-focus-ring-color: rgba(var(--bs-warning-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-1 {
  --bs-focus-ring-color: rgba(var(--bs-warning-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-2 {
  --bs-focus-ring-color: rgba(var(--bs-warning-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-3 {
  --bs-focus-ring-color: rgba(var(--bs-warning-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-4 {
  --bs-focus-ring-color: rgba(var(--bs-warning-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-5 {
  --bs-focus-ring-color: rgba(var(--bs-warning-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-6 {
  --bs-focus-ring-color: rgba(var(--bs-warning-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-7 {
  --bs-focus-ring-color: rgba(var(--bs-warning-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-8 {
  --bs-focus-ring-color: rgba(var(--bs-warning-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-9 {
  --bs-focus-ring-color: rgba(var(--bs-warning-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning-dark {
  --bs-focus-ring-color: rgba(var(--bs-warning-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-light {
  --bs-focus-ring-color: rgba(var(--bs-danger-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-1 {
  --bs-focus-ring-color: rgba(var(--bs-danger-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-2 {
  --bs-focus-ring-color: rgba(var(--bs-danger-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-3 {
  --bs-focus-ring-color: rgba(var(--bs-danger-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-4 {
  --bs-focus-ring-color: rgba(var(--bs-danger-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-5 {
  --bs-focus-ring-color: rgba(var(--bs-danger-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-6 {
  --bs-focus-ring-color: rgba(var(--bs-danger-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-7 {
  --bs-focus-ring-color: rgba(var(--bs-danger-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-8 {
  --bs-focus-ring-color: rgba(var(--bs-danger-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-9 {
  --bs-focus-ring-color: rgba(var(--bs-danger-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger-dark {
  --bs-focus-ring-color: rgba(var(--bs-danger-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-light {
  --bs-focus-ring-color: rgba(var(--bs-gray-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-1 {
  --bs-focus-ring-color: rgba(var(--bs-gray-1-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-2 {
  --bs-focus-ring-color: rgba(var(--bs-gray-2-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-3 {
  --bs-focus-ring-color: rgba(var(--bs-gray-3-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-4 {
  --bs-focus-ring-color: rgba(var(--bs-gray-4-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-5 {
  --bs-focus-ring-color: rgba(var(--bs-gray-5-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-6 {
  --bs-focus-ring-color: rgba(var(--bs-gray-6-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-7 {
  --bs-focus-ring-color: rgba(var(--bs-gray-7-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-8 {
  --bs-focus-ring-color: rgba(var(--bs-gray-8-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-9 {
  --bs-focus-ring-color: rgba(var(--bs-gray-9-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-dark {
  --bs-focus-ring-color: rgba(var(--bs-gray-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-light-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-1-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-2-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-3-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-4-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-5-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-6-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-7-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-8-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-9-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-light-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-1-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-2-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-3-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-4-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-5-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-6-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-7-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-8-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-9-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-light-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-1-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-2-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-3-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-4-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-5-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-6-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-7-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-8-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-9-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-success-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-light-rgb), var(--bs-border-opacity)) !important;
}

.border-success-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-1-rgb), var(--bs-border-opacity)) !important;
}

.border-success-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-2-rgb), var(--bs-border-opacity)) !important;
}

.border-success-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-3-rgb), var(--bs-border-opacity)) !important;
}

.border-success-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-4-rgb), var(--bs-border-opacity)) !important;
}

.border-success-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-5-rgb), var(--bs-border-opacity)) !important;
}

.border-success-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-6-rgb), var(--bs-border-opacity)) !important;
}

.border-success-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-7-rgb), var(--bs-border-opacity)) !important;
}

.border-success-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-8-rgb), var(--bs-border-opacity)) !important;
}

.border-success-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-9-rgb), var(--bs-border-opacity)) !important;
}

.border-success-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-info-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-light-rgb), var(--bs-border-opacity)) !important;
}

.border-info-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-1-rgb), var(--bs-border-opacity)) !important;
}

.border-info-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-2-rgb), var(--bs-border-opacity)) !important;
}

.border-info-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-3-rgb), var(--bs-border-opacity)) !important;
}

.border-info-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-4-rgb), var(--bs-border-opacity)) !important;
}

.border-info-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-5-rgb), var(--bs-border-opacity)) !important;
}

.border-info-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-6-rgb), var(--bs-border-opacity)) !important;
}

.border-info-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-7-rgb), var(--bs-border-opacity)) !important;
}

.border-info-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-8-rgb), var(--bs-border-opacity)) !important;
}

.border-info-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-9-rgb), var(--bs-border-opacity)) !important;
}

.border-info-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-light-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-1-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-2-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-3-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-4-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-5-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-6-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-7-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-8-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-9-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-light-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-1-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-2-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-3-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-4-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-5-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-6-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-7-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-8-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-9-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-light-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-1-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-2-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-3-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-4-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-5-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-6-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-7 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-7-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-8 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-8-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-9 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-9-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-light-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-1-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-2-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-3-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-4-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-5-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-6-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-7-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-8-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-9-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-light-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-1-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-2-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-3-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-4-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-5-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-6-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-7-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-8-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-9-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-light-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-1-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-2-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-3-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-4-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-5-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-6-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-7-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-8-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-9-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-success-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-light-rgb), var(--bs-text-opacity)) !important;
}

.text-success-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-1-rgb), var(--bs-text-opacity)) !important;
}

.text-success-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-2-rgb), var(--bs-text-opacity)) !important;
}

.text-success-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-3-rgb), var(--bs-text-opacity)) !important;
}

.text-success-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-4-rgb), var(--bs-text-opacity)) !important;
}

.text-success-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-5-rgb), var(--bs-text-opacity)) !important;
}

.text-success-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-6-rgb), var(--bs-text-opacity)) !important;
}

.text-success-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-7-rgb), var(--bs-text-opacity)) !important;
}

.text-success-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-8-rgb), var(--bs-text-opacity)) !important;
}

.text-success-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-9-rgb), var(--bs-text-opacity)) !important;
}

.text-success-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-info-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-light-rgb), var(--bs-text-opacity)) !important;
}

.text-info-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-1-rgb), var(--bs-text-opacity)) !important;
}

.text-info-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-2-rgb), var(--bs-text-opacity)) !important;
}

.text-info-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-3-rgb), var(--bs-text-opacity)) !important;
}

.text-info-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-4-rgb), var(--bs-text-opacity)) !important;
}

.text-info-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-5-rgb), var(--bs-text-opacity)) !important;
}

.text-info-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-6-rgb), var(--bs-text-opacity)) !important;
}

.text-info-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-7-rgb), var(--bs-text-opacity)) !important;
}

.text-info-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-8-rgb), var(--bs-text-opacity)) !important;
}

.text-info-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-9-rgb), var(--bs-text-opacity)) !important;
}

.text-info-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-light-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-1-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-2-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-3-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-4-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-5-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-6-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-7-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-8-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-9-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-light-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-1-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-2-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-3-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-4-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-5-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-6-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-7-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-8-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-9-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-light-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-1-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-2-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-3-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-4-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-5-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-6-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-7 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-7-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-8 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-8-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-9 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-9-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-1-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-2-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-3-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-4-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-5-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-6-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-7 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-7-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-8 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-8-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-9 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-9-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-4-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-5-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-6-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-7 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-7-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-8 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-8-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-9 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-9-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 320px) {
  .float-xxs-start {
    float: left !important;
  }
  .float-xxs-end {
    float: right !important;
  }
  .float-xxs-none {
    float: none !important;
  }
  .object-fit-xxs-contain {
    object-fit: contain !important;
  }
  .object-fit-xxs-cover {
    object-fit: cover !important;
  }
  .object-fit-xxs-fill {
    object-fit: fill !important;
  }
  .object-fit-xxs-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxs-none {
    object-fit: none !important;
  }
  .d-xxs-inline {
    display: inline !important;
  }
  .d-xxs-inline-block {
    display: inline-block !important;
  }
  .d-xxs-block {
    display: block !important;
  }
  .d-xxs-grid {
    display: grid !important;
  }
  .d-xxs-inline-grid {
    display: inline-grid !important;
  }
  .d-xxs-table {
    display: table !important;
  }
  .d-xxs-table-row {
    display: table-row !important;
  }
  .d-xxs-table-cell {
    display: table-cell !important;
  }
  .d-xxs-flex {
    display: flex !important;
  }
  .d-xxs-inline-flex {
    display: inline-flex !important;
  }
  .d-xxs-none {
    display: none !important;
  }
  .flex-xxs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxs-row {
    flex-direction: row !important;
  }
  .flex-xxs-column {
    flex-direction: column !important;
  }
  .flex-xxs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxs-center {
    justify-content: center !important;
  }
  .justify-content-xxs-between {
    justify-content: space-between !important;
  }
  .justify-content-xxs-around {
    justify-content: space-around !important;
  }
  .justify-content-xxs-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxs-start {
    align-items: flex-start !important;
  }
  .align-items-xxs-end {
    align-items: flex-end !important;
  }
  .align-items-xxs-center {
    align-items: center !important;
  }
  .align-items-xxs-baseline {
    align-items: baseline !important;
  }
  .align-items-xxs-stretch {
    align-items: stretch !important;
  }
  .align-content-xxs-start {
    align-content: flex-start !important;
  }
  .align-content-xxs-end {
    align-content: flex-end !important;
  }
  .align-content-xxs-center {
    align-content: center !important;
  }
  .align-content-xxs-between {
    align-content: space-between !important;
  }
  .align-content-xxs-around {
    align-content: space-around !important;
  }
  .align-content-xxs-stretch {
    align-content: stretch !important;
  }
  .align-self-xxs-auto {
    align-self: auto !important;
  }
  .align-self-xxs-start {
    align-self: flex-start !important;
  }
  .align-self-xxs-end {
    align-self: flex-end !important;
  }
  .align-self-xxs-center {
    align-self: center !important;
  }
  .align-self-xxs-baseline {
    align-self: baseline !important;
  }
  .align-self-xxs-stretch {
    align-self: stretch !important;
  }
  .order-xxs-first {
    order: -1 !important;
  }
  .order-xxs-0 {
    order: 0 !important;
  }
  .order-xxs-1 {
    order: 1 !important;
  }
  .order-xxs-2 {
    order: 2 !important;
  }
  .order-xxs-3 {
    order: 3 !important;
  }
  .order-xxs-4 {
    order: 4 !important;
  }
  .order-xxs-5 {
    order: 5 !important;
  }
  .order-xxs-last {
    order: 6 !important;
  }
  .m-xxs-0 {
    margin: 0 !important;
  }
  .m-xxs-1 {
    margin: 0.25rem !important;
  }
  .m-xxs-2 {
    margin: 0.5rem !important;
  }
  .m-xxs-3 {
    margin: 1rem !important;
  }
  .m-xxs-4 {
    margin: 1.5rem !important;
  }
  .m-xxs-5 {
    margin: 3rem !important;
  }
  .m-xxs-auto {
    margin: auto !important;
  }
  .mx-xxs-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxs-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxs-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxs-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxs-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxs-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxs-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxs-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxs-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxs-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxs-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxs-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxs-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxs-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxs-0 {
    margin-top: 0 !important;
  }
  .mt-xxs-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxs-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxs-3 {
    margin-top: 1rem !important;
  }
  .mt-xxs-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxs-5 {
    margin-top: 3rem !important;
  }
  .mt-xxs-auto {
    margin-top: auto !important;
  }
  .me-xxs-0 {
    margin-right: 0 !important;
  }
  .me-xxs-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxs-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxs-3 {
    margin-right: 1rem !important;
  }
  .me-xxs-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxs-5 {
    margin-right: 3rem !important;
  }
  .me-xxs-auto {
    margin-right: auto !important;
  }
  .mb-xxs-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxs-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxs-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxs-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxs-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxs-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxs-auto {
    margin-bottom: auto !important;
  }
  .ms-xxs-0 {
    margin-left: 0 !important;
  }
  .ms-xxs-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxs-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxs-3 {
    margin-left: 1rem !important;
  }
  .ms-xxs-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxs-5 {
    margin-left: 3rem !important;
  }
  .ms-xxs-auto {
    margin-left: auto !important;
  }
  .p-xxs-0 {
    padding: 0 !important;
  }
  .p-xxs-1 {
    padding: 0.25rem !important;
  }
  .p-xxs-2 {
    padding: 0.5rem !important;
  }
  .p-xxs-3 {
    padding: 1rem !important;
  }
  .p-xxs-4 {
    padding: 1.5rem !important;
  }
  .p-xxs-5 {
    padding: 3rem !important;
  }
  .px-xxs-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxs-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxs-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxs-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxs-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxs-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxs-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxs-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxs-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxs-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxs-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxs-0 {
    padding-top: 0 !important;
  }
  .pt-xxs-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxs-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxs-3 {
    padding-top: 1rem !important;
  }
  .pt-xxs-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxs-5 {
    padding-top: 3rem !important;
  }
  .pe-xxs-0 {
    padding-right: 0 !important;
  }
  .pe-xxs-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxs-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxs-3 {
    padding-right: 1rem !important;
  }
  .pe-xxs-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxs-5 {
    padding-right: 3rem !important;
  }
  .pb-xxs-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxs-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxs-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxs-0 {
    padding-left: 0 !important;
  }
  .ps-xxs-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxs-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxs-3 {
    padding-left: 1rem !important;
  }
  .ps-xxs-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxs-5 {
    padding-left: 3rem !important;
  }
  .gap-xxs-0 {
    gap: 0 !important;
  }
  .gap-xxs-1 {
    gap: 0.25rem !important;
  }
  .gap-xxs-2 {
    gap: 0.5rem !important;
  }
  .gap-xxs-3 {
    gap: 1rem !important;
  }
  .gap-xxs-4 {
    gap: 1.5rem !important;
  }
  .gap-xxs-5 {
    gap: 3rem !important;
  }
  .row-gap-xxs-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxs-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxs-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxs-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxs-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxs-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxs-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxs-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxs-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxs-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxs-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxs-5 {
    column-gap: 3rem !important;
  }
  .text-xxs-start {
    text-align: left !important;
  }
  .text-xxs-end {
    text-align: right !important;
  }
  .text-xxs-center {
    text-align: center !important;
  }
}
@media (min-width: 568px) {
  .float-xs-start {
    float: left !important;
  }
  .float-xs-end {
    float: right !important;
  }
  .float-xs-none {
    float: none !important;
  }
  .object-fit-xs-contain {
    object-fit: contain !important;
  }
  .object-fit-xs-cover {
    object-fit: cover !important;
  }
  .object-fit-xs-fill {
    object-fit: fill !important;
  }
  .object-fit-xs-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xs-none {
    object-fit: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-grid {
    display: grid !important;
  }
  .d-xs-inline-grid {
    display: inline-grid !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
  .d-xs-none {
    display: none !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .justify-content-xs-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
  .order-xs-first {
    order: -1 !important;
  }
  .order-xs-0 {
    order: 0 !important;
  }
  .order-xs-1 {
    order: 1 !important;
  }
  .order-xs-2 {
    order: 2 !important;
  }
  .order-xs-3 {
    order: 3 !important;
  }
  .order-xs-4 {
    order: 4 !important;
  }
  .order-xs-5 {
    order: 5 !important;
  }
  .order-xs-last {
    order: 6 !important;
  }
  .m-xs-0 {
    margin: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mx-xs-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xs-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xs-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xs-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xs-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xs-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xs-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xs-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xs-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xs-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xs-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xs-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xs-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xs-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xs-0 {
    margin-top: 0 !important;
  }
  .mt-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xs-3 {
    margin-top: 1rem !important;
  }
  .mt-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xs-5 {
    margin-top: 3rem !important;
  }
  .mt-xs-auto {
    margin-top: auto !important;
  }
  .me-xs-0 {
    margin-right: 0 !important;
  }
  .me-xs-1 {
    margin-right: 0.25rem !important;
  }
  .me-xs-2 {
    margin-right: 0.5rem !important;
  }
  .me-xs-3 {
    margin-right: 1rem !important;
  }
  .me-xs-4 {
    margin-right: 1.5rem !important;
  }
  .me-xs-5 {
    margin-right: 3rem !important;
  }
  .me-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-0 {
    margin-bottom: 0 !important;
  }
  .mb-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xs-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xs-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xs-auto {
    margin-bottom: auto !important;
  }
  .ms-xs-0 {
    margin-left: 0 !important;
  }
  .ms-xs-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xs-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xs-3 {
    margin-left: 1rem !important;
  }
  .ms-xs-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xs-5 {
    margin-left: 3rem !important;
  }
  .ms-xs-auto {
    margin-left: auto !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .px-xs-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xs-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xs-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xs-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xs-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xs-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xs-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xs-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xs-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xs-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xs-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xs-0 {
    padding-top: 0 !important;
  }
  .pt-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xs-3 {
    padding-top: 1rem !important;
  }
  .pt-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xs-5 {
    padding-top: 3rem !important;
  }
  .pe-xs-0 {
    padding-right: 0 !important;
  }
  .pe-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xs-3 {
    padding-right: 1rem !important;
  }
  .pe-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-0 {
    padding-bottom: 0 !important;
  }
  .pb-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xs-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xs-0 {
    padding-left: 0 !important;
  }
  .ps-xs-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xs-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xs-3 {
    padding-left: 1rem !important;
  }
  .ps-xs-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xs-5 {
    padding-left: 3rem !important;
  }
  .gap-xs-0 {
    gap: 0 !important;
  }
  .gap-xs-1 {
    gap: 0.25rem !important;
  }
  .gap-xs-2 {
    gap: 0.5rem !important;
  }
  .gap-xs-3 {
    gap: 1rem !important;
  }
  .gap-xs-4 {
    gap: 1.5rem !important;
  }
  .gap-xs-5 {
    gap: 3rem !important;
  }
  .row-gap-xs-0 {
    row-gap: 0 !important;
  }
  .row-gap-xs-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xs-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xs-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xs-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xs-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xs-0 {
    column-gap: 0 !important;
  }
  .column-gap-xs-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xs-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xs-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xs-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xs-5 {
    column-gap: 3rem !important;
  }
  .text-xs-start {
    text-align: left !important;
  }
  .text-xs-end {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 667px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxxl-start {
    float: left !important;
  }
  .float-xxxl-end {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
  .object-fit-xxxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxxl-none {
    object-fit: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-grid {
    display: grid !important;
  }
  .d-xxxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxxl-none {
    display: none !important;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
  .order-xxxl-first {
    order: -1 !important;
  }
  .order-xxxl-0 {
    order: 0 !important;
  }
  .order-xxxl-1 {
    order: 1 !important;
  }
  .order-xxxl-2 {
    order: 2 !important;
  }
  .order-xxxl-3 {
    order: 3 !important;
  }
  .order-xxxl-4 {
    order: 4 !important;
  }
  .order-xxxl-5 {
    order: 5 !important;
  }
  .order-xxxl-last {
    order: 6 !important;
  }
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxxl-auto {
    margin-top: auto !important;
  }
  .me-xxxl-0 {
    margin-right: 0 !important;
  }
  .me-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxxl-auto {
    margin-left: auto !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxxl-0 {
    gap: 0 !important;
  }
  .gap-xxxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxxl-3 {
    gap: 1rem !important;
  }
  .gap-xxxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxxl-start {
    text-align: left !important;
  }
  .text-xxxl-end {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.container,
.container-fluid,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs,
.container-xxs,
.container-xxxs {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 0;
}

@media (min-width: 320px) {
  .container-xxs, .container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .container-xs, .container-xxs, .container {
    max-width: 568px;
  }
}
@media (min-width: 667px) {
  .container-sm, .container-xs, .container-xxs, .container {
    max-width: 667px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xs, .container-xxs, .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xs, .container-xxs, .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container-xxs, .container {
    max-width: 1200px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container-xxs, .container {
    max-width: 1440px;
  }
}
@media (min-width: 1600px) {
  .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container-xxs, .container {
    max-width: 1600px;
  }
}
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #FFFFFF;
  --bs-gray: #666666;
  --bs-gray-dark: #3f3f3f;
  --bs-gray-100: #d1d1d1;
  --bs-gray-200: #bfbfbf;
  --bs-gray-300: #aaaaaa;
  --bs-gray-400: #999999;
  --bs-gray-500: #7F7F7F;
  --bs-gray-600: #666666;
  --bs-gray-700: #545454;
  --bs-gray-800: #3f3f3f;
  --bs-gray-900: #2d2d2d;
  --bs-primary: #0d6efd;
  --bs-primary-light: #d7e7ff;
  --bs-primary-1: #afcffe;
  --bs-primary-2: #8bbafe;
  --bs-primary-3: #63a2fe;
  --bs-primary-4: #408cfd;
  --bs-primary-5: #0d6efd;
  --bs-primary-6: #0257d5;
  --bs-primary-7: #0149b2;
  --bs-primary-8: #013889;
  --bs-primary-9: #012a66;
  --bs-primary-dark: #01193d;
  --bs-secondary: #6c757d;
  --bs-secondary-light: #d8dbdd;
  --bs-secondary-1: #c2c6ca;
  --bs-secondary-2: #afb5ba;
  --bs-secondary-3: #99a0a7;
  --bs-secondary-4: #868e96;
  --bs-secondary-5: #6c757d;
  --bs-secondary-6: #545b62;
  --bs-secondary-7: #44494e;
  --bs-secondary-8: #313539;
  --bs-secondary-9: #202325;
  --bs-secondary-dark: #0d0f10;
  --bs-tertiary: #F18F01;
  --bs-tertiary-light: #ffe5bf;
  --bs-tertiary-1: #ffd497;
  --bs-tertiary-2: #fec673;
  --bs-tertiary-3: #feb54a;
  --bs-tertiary-4: #fea627;
  --bs-tertiary-5: #F18F01;
  --bs-tertiary-6: #be7101;
  --bs-tertiary-7: #9b5c01;
  --bs-tertiary-8: #724400;
  --bs-tertiary-9: #4e2f00;
  --bs-tertiary-dark: #261600;
  --bs-success: #198754;
  --bs-success-light: #84e8ba;
  --bs-success-1: #61e2a6;
  --bs-success-2: #43dc95;
  --bs-success-3: #27d082;
  --bs-success-4: #21b26f;
  --bs-success-5: #198754;
  --bs-success-6: #115c39;
  --bs-success-7: #0b3e26;
  --bs-success-8: #051b11;
  --bs-success-9: black;
  --bs-success-dark: black;
  --bs-info: #0DCAf0;
  --bs-info-light: #cdf4fc;
  --bs-info-1: #a6ecfa;
  --bs-info-2: #84e5f8;
  --bs-info-3: #5dddf6;
  --bs-info-4: #3cd5f4;
  --bs-info-5: #0DCAf0;
  --bs-info-6: #0aa1c0;
  --bs-info-7: #09859e;
  --bs-info-8: #066477;
  --bs-info-9: #054855;
  --bs-info-dark: #03272e;
  --bs-warning: #FFCA2C;
  --bs-warning-light: #fffdf8;
  --bs-warning-1: #fff3cf;
  --bs-warning-2: #ffeaac;
  --bs-warning-3: #ffe083;
  --bs-warning-4: #ffd75f;
  --bs-warning-5: #FFCA2C;
  --bs-warning-6: #f8ba00;
  --bs-warning-7: #d49f00;
  --bs-warning-8: #ac8000;
  --bs-warning-9: #886600;
  --bs-warning-dark: #5f4700;
  --bs-danger: #DC3545;
  --bs-danger-light: #fae3e5;
  --bs-danger-1: #f4c0c5;
  --bs-danger-2: #efa2a9;
  --bs-danger-3: #e97f89;
  --bs-danger-4: #e4606d;
  --bs-danger-5: #DC3545;
  --bs-danger-6: #bd2130;
  --bs-danger-7: #9f1c28;
  --bs-danger-8: #7c151f;
  --bs-danger-9: #5e1018;
  --bs-danger-dark: #3b0a0f;
  --bs-gray: #7F7F7F;
  --bs-gray-light: #e5e5e5;
  --bs-gray-1: #d1d1d1;
  --bs-gray-2: #bfbfbf;
  --bs-gray-3: #aaaaaa;
  --bs-gray-4: #999999;
  --bs-gray-5: #7F7F7F;
  --bs-gray-6: #666666;
  --bs-gray-7: #545454;
  --bs-gray-8: #3f3f3f;
  --bs-gray-9: #2d2d2d;
  --bs-gray-dark: #191919;
  --bs-light: #F8F9FA;
  --bs-dark: #212529;
  --bs-white: #FFFFFF;
  --bs-black: #000000;
  --bs-primary-rgb: 13, 110, 253;
  --bs-primary-light-rgb: 215, 231, 255;
  --bs-primary-1-rgb: 175, 207, 254;
  --bs-primary-2-rgb: 139, 186, 254;
  --bs-primary-3-rgb: 99, 162, 254;
  --bs-primary-4-rgb: 64, 140, 253;
  --bs-primary-5-rgb: 13, 110, 253;
  --bs-primary-6-rgb: 2, 87, 213;
  --bs-primary-7-rgb: 1, 73, 178;
  --bs-primary-8-rgb: 1, 56, 137;
  --bs-primary-9-rgb: 1, 42, 102;
  --bs-primary-dark-rgb: 1, 25, 61;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-secondary-light-rgb: 216, 219, 221;
  --bs-secondary-1-rgb: 194, 198, 202;
  --bs-secondary-2-rgb: 175, 181, 186;
  --bs-secondary-3-rgb: 153, 160, 167;
  --bs-secondary-4-rgb: 134, 142, 150;
  --bs-secondary-5-rgb: 108, 117, 125;
  --bs-secondary-6-rgb: 84, 91, 98;
  --bs-secondary-7-rgb: 68, 73, 78;
  --bs-secondary-8-rgb: 49, 53, 57;
  --bs-secondary-9-rgb: 32, 35, 37;
  --bs-secondary-dark-rgb: 13, 15, 16;
  --bs-tertiary-rgb: 241, 143, 1;
  --bs-tertiary-light-rgb: 255, 229, 191;
  --bs-tertiary-1-rgb: 255, 212, 151;
  --bs-tertiary-2-rgb: 254, 198, 115;
  --bs-tertiary-3-rgb: 254, 181, 74;
  --bs-tertiary-4-rgb: 254, 166, 39;
  --bs-tertiary-5-rgb: 241, 143, 1;
  --bs-tertiary-6-rgb: 190, 113, 1;
  --bs-tertiary-7-rgb: 155, 92, 1;
  --bs-tertiary-8-rgb: 114, 68, 0;
  --bs-tertiary-9-rgb: 78, 47, 0;
  --bs-tertiary-dark-rgb: 38, 22, 0;
  --bs-success-rgb: 25, 135, 84;
  --bs-success-light-rgb: 132, 232, 186;
  --bs-success-1-rgb: 97, 226, 166;
  --bs-success-2-rgb: 67, 220, 149;
  --bs-success-3-rgb: 39, 208, 130;
  --bs-success-4-rgb: 33, 178, 111;
  --bs-success-5-rgb: 25, 135, 84;
  --bs-success-6-rgb: 17, 92, 57;
  --bs-success-7-rgb: 11, 62, 38;
  --bs-success-8-rgb: 5, 27, 17;
  --bs-success-9-rgb: 0, 0, 0;
  --bs-success-dark-rgb: 0, 0, 0;
  --bs-info-rgb: 13, 202, 240;
  --bs-info-light-rgb: 205, 244, 252;
  --bs-info-1-rgb: 166, 236, 250;
  --bs-info-2-rgb: 132, 229, 248;
  --bs-info-3-rgb: 93, 221, 246;
  --bs-info-4-rgb: 60, 213, 244;
  --bs-info-5-rgb: 13, 202, 240;
  --bs-info-6-rgb: 10, 161, 192;
  --bs-info-7-rgb: 9, 133, 158;
  --bs-info-8-rgb: 6, 100, 119;
  --bs-info-9-rgb: 5, 72, 85;
  --bs-info-dark-rgb: 3, 39, 46;
  --bs-warning-rgb: 255, 202, 44;
  --bs-warning-light-rgb: 255, 253, 248;
  --bs-warning-1-rgb: 255, 243, 207;
  --bs-warning-2-rgb: 255, 234, 172;
  --bs-warning-3-rgb: 255, 224, 131;
  --bs-warning-4-rgb: 255, 215, 95;
  --bs-warning-5-rgb: 255, 202, 44;
  --bs-warning-6-rgb: 248, 186, 0;
  --bs-warning-7-rgb: 212, 159, 0;
  --bs-warning-8-rgb: 172, 128, 0;
  --bs-warning-9-rgb: 136, 102, 0;
  --bs-warning-dark-rgb: 95, 71, 0;
  --bs-danger-rgb: 220, 53, 69;
  --bs-danger-light-rgb: 250, 227, 229;
  --bs-danger-1-rgb: 244, 192, 197;
  --bs-danger-2-rgb: 239, 162, 169;
  --bs-danger-3-rgb: 233, 127, 137;
  --bs-danger-4-rgb: 228, 96, 109;
  --bs-danger-5-rgb: 220, 53, 69;
  --bs-danger-6-rgb: 189, 33, 48;
  --bs-danger-7-rgb: 159, 28, 40;
  --bs-danger-8-rgb: 124, 21, 31;
  --bs-danger-9-rgb: 94, 16, 24;
  --bs-danger-dark-rgb: 59, 10, 15;
  --bs-gray-rgb: 127, 127, 127;
  --bs-gray-light-rgb: 229, 229, 229;
  --bs-gray-1-rgb: 209, 209, 209;
  --bs-gray-2-rgb: 191, 191, 191;
  --bs-gray-3-rgb: 170, 170, 170;
  --bs-gray-4-rgb: 153, 153, 153;
  --bs-gray-5-rgb: 127, 127, 127;
  --bs-gray-6-rgb: 102, 102, 102;
  --bs-gray-7-rgb: 84, 84, 84;
  --bs-gray-8-rgb: 63, 63, 63;
  --bs-gray-9-rgb: 45, 45, 45;
  --bs-gray-dark-rgb: 25, 25, 25;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #665112;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #545454;
  --bs-dark-text-emphasis: #545454;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff4d5;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #e8e8e8;
  --bs-dark-bg-subtle: #999999;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffeaab;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #bfbfbf;
  --bs-dark-border-subtle: #7F7F7F;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #2d2d2d;
  --bs-body-color-rgb: 45, 45, 45;
  --bs-body-bg: #FFFFFF;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(45, 45, 45, 0.75);
  --bs-secondary-color-rgb: 45, 45, 45;
  --bs-secondary-bg: #bfbfbf;
  --bs-secondary-bg-rgb: 191, 191, 191;
  --bs-tertiary-color: rgba(45, 45, 45, 0.5);
  --bs-tertiary-color-rgb: 45, 45, 45;
  --bs-tertiary-bg: #d1d1d1;
  --bs-tertiary-bg-rgb: 209, 209, 209;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #2d2d2d;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #aaaaaa;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #DC3545;
  --bs-form-invalid-border-color: #DC3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #aaaaaa;
  --bs-body-color-rgb: 170, 170, 170;
  --bs-body-bg: #2d2d2d;
  --bs-body-bg-rgb: 45, 45, 45;
  --bs-emphasis-color: #FFFFFF;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(170, 170, 170, 0.75);
  --bs-secondary-color-rgb: 170, 170, 170;
  --bs-secondary-bg: #3f3f3f;
  --bs-secondary-bg-rgb: 63, 63, 63;
  --bs-tertiary-color: rgba(170, 170, 170, 0.5);
  --bs-tertiary-color-rgb: 170, 170, 170;
  --bs-tertiary-bg: #363636;
  --bs-tertiary-bg-rgb: 54, 54, 54;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffdf80;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #d1d1d1;
  --bs-dark-text-emphasis: #aaaaaa;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332809;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #3f3f3f;
  --bs-dark-bg-subtle: #202020;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #99791a;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #545454;
  --bs-dark-border-subtle: #3f3f3f;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #aaaaaa;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #545454;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #666666;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

:root {
  --bs-breakpoint-xxxs: 0;
  --bs-breakpoint-xxs: 320px;
  --bs-breakpoint-xs: 568px;
  --bs-breakpoint-sm: 667px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440px;
  --bs-breakpoint-xxxl: 1600px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 320px) {
  .col-xxs {
    flex: 1 0 0%;
  }
  .row-cols-xxs-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxs-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxs-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxs-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxs-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxs-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxs-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxs-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxs-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxs-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxs-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxs-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxs-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxs-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxs-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxs-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxs-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxs-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxs-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxs-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxs-0 {
    margin-left: 0;
  }
  .offset-xxs-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxs-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxs-3 {
    margin-left: 25%;
  }
  .offset-xxs-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxs-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxs-6 {
    margin-left: 50%;
  }
  .offset-xxs-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxs-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxs-9 {
    margin-left: 75%;
  }
  .offset-xxs-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxs-11 {
    margin-left: 91.66666667%;
  }
  .g-xxs-0,
.gx-xxs-0 {
    --bs-gutter-x: 0;
  }
  .g-xxs-0,
.gy-xxs-0 {
    --bs-gutter-y: 0;
  }
  .g-xxs-1,
.gx-xxs-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxs-1,
.gy-xxs-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxs-2,
.gx-xxs-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxs-2,
.gy-xxs-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxs-3,
.gx-xxs-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxs-3,
.gy-xxs-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxs-4,
.gx-xxs-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxs-4,
.gy-xxs-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxs-5,
.gx-xxs-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxs-5,
.gy-xxs-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 568px) {
  .col-xs {
    flex: 1 0 0%;
  }
  .row-cols-xs-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xs-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xs-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xs-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xs-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xs-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xs-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xs-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xs-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xs-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xs-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xs-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xs-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xs-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xs-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xs-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333333%;
  }
  .offset-xs-2 {
    margin-left: 16.66666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333333%;
  }
  .offset-xs-5 {
    margin-left: 41.66666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333333%;
  }
  .offset-xs-8 {
    margin-left: 66.66666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333333%;
  }
  .offset-xs-11 {
    margin-left: 91.66666667%;
  }
  .g-xs-0,
.gx-xs-0 {
    --bs-gutter-x: 0;
  }
  .g-xs-0,
.gy-xs-0 {
    --bs-gutter-y: 0;
  }
  .g-xs-1,
.gx-xs-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xs-1,
.gy-xs-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xs-2,
.gx-xs-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xs-2,
.gy-xs-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xs-3,
.gx-xs-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xs-3,
.gy-xs-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xs-4,
.gx-xs-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xs-4,
.gy-xs-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xs-5,
.gx-xs-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xs-5,
.gy-xs-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 667px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1600px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
.gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
.gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-1,
.gx-xxxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxxl-1,
.gy-xxxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxxl-2,
.gx-xxxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxxl-2,
.gy-xxxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxxl-3,
.gx-xxxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxxl-3,
.gy-xxxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxxl-4,
.gx-xxxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxxl-4,
.gy-xxxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxxl-5,
.gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxxl-5,
.gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #fff4d5;
  --bs-table-border-color: #ccc3aa;
  --bs-table-striped-bg: #f2e8ca;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6dcc0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ece2c5;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #F8F9FA;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #FFFFFF;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #FFFFFF;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #FFFFFF;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #FFFFFF;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 319.98px) {
  .table-responsive-xxs {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 567.98px) {
  .table-responsive-xs {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 666.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233f3f3f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23aaaaaa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #666666;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DC3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23DC3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-primary-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #d7e7ff;
  --bs-btn-border-color: #d7e7ff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ddebff;
  --bs-btn-hover-border-color: #dbe9ff;
  --bs-btn-focus-shadow-rgb: 183, 196, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #dfecff;
  --bs-btn-active-border-color: #dbe9ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #d7e7ff;
  --bs-btn-disabled-border-color: #d7e7ff;
}

.btn-primary-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #afcffe;
  --bs-btn-border-color: #afcffe;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #bbd6fe;
  --bs-btn-hover-border-color: #b7d4fe;
  --bs-btn-focus-shadow-rgb: 149, 176, 216;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #bfd9fe;
  --bs-btn-active-border-color: #b7d4fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #afcffe;
  --bs-btn-disabled-border-color: #afcffe;
}

.btn-primary-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #8bbafe;
  --bs-btn-border-color: #8bbafe;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #9cc4fe;
  --bs-btn-hover-border-color: #97c1fe;
  --bs-btn-focus-shadow-rgb: 118, 158, 216;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a2c8fe;
  --bs-btn-active-border-color: #97c1fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #8bbafe;
  --bs-btn-disabled-border-color: #8bbafe;
}

.btn-primary-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #63a2fe;
  --bs-btn-border-color: #63a2fe;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #7ab0fe;
  --bs-btn-hover-border-color: #73abfe;
  --bs-btn-focus-shadow-rgb: 84, 138, 216;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #82b5fe;
  --bs-btn-active-border-color: #73abfe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #63a2fe;
  --bs-btn-disabled-border-color: #63a2fe;
}

.btn-primary-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #408cfd;
  --bs-btn-border-color: #408cfd;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #5d9dfd;
  --bs-btn-hover-border-color: #5398fd;
  --bs-btn-focus-shadow-rgb: 54, 119, 215;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #66a3fd;
  --bs-btn-active-border-color: #5398fd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #408cfd;
  --bs-btn-disabled-border-color: #408cfd;
}

.btn-primary-5 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-primary-6 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0257d5;
  --bs-btn-border-color: #0257d5;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #024ab5;
  --bs-btn-hover-border-color: #0246aa;
  --bs-btn-focus-shadow-rgb: 40, 112, 219;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0246aa;
  --bs-btn-active-border-color: #0241a0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #0257d5;
  --bs-btn-disabled-border-color: #0257d5;
}

.btn-primary-7 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0149b2;
  --bs-btn-border-color: #0149b2;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #013e97;
  --bs-btn-hover-border-color: #013a8e;
  --bs-btn-focus-shadow-rgb: 39, 100, 190;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #013a8e;
  --bs-btn-active-border-color: #013786;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #0149b2;
  --bs-btn-disabled-border-color: #0149b2;
}

.btn-primary-8 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #013889;
  --bs-btn-border-color: #013889;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #013074;
  --bs-btn-hover-border-color: #012d6e;
  --bs-btn-focus-shadow-rgb: 39, 86, 155;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #012d6e;
  --bs-btn-active-border-color: #012a67;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #013889;
  --bs-btn-disabled-border-color: #013889;
}

.btn-primary-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #012a66;
  --bs-btn-border-color: #012a66;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #012457;
  --bs-btn-hover-border-color: #012252;
  --bs-btn-focus-shadow-rgb: 39, 74, 125;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #012252;
  --bs-btn-active-border-color: #01204d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #012a66;
  --bs-btn-disabled-border-color: #012a66;
}

.btn-primary-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #01193d;
  --bs-btn-border-color: #01193d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #011534;
  --bs-btn-hover-border-color: #011431;
  --bs-btn-focus-shadow-rgb: 39, 60, 90;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #011431;
  --bs-btn-active-border-color: #01132e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #01193d;
  --bs-btn-disabled-border-color: #01193d;
}

.btn-secondary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-secondary-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #d8dbdd;
  --bs-btn-border-color: #d8dbdd;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #dee0e2;
  --bs-btn-hover-border-color: #dcdfe0;
  --bs-btn-focus-shadow-rgb: 184, 186, 188;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e0e2e4;
  --bs-btn-active-border-color: #dcdfe0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #d8dbdd;
  --bs-btn-disabled-border-color: #d8dbdd;
}

.btn-secondary-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #c2c6ca;
  --bs-btn-border-color: #c2c6ca;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cbcfd2;
  --bs-btn-hover-border-color: #c8cccf;
  --bs-btn-focus-shadow-rgb: 165, 168, 172;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ced1d5;
  --bs-btn-active-border-color: #c8cccf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #c2c6ca;
  --bs-btn-disabled-border-color: #c2c6ca;
}

.btn-secondary-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #afb5ba;
  --bs-btn-border-color: #afb5ba;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #bbc0c4;
  --bs-btn-hover-border-color: #b7bcc1;
  --bs-btn-focus-shadow-rgb: 149, 154, 158;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #bfc4c8;
  --bs-btn-active-border-color: #b7bcc1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #afb5ba;
  --bs-btn-disabled-border-color: #afb5ba;
}

.btn-secondary-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #99a0a7;
  --bs-btn-border-color: #99a0a7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a8aeb4;
  --bs-btn-hover-border-color: #a3aab0;
  --bs-btn-focus-shadow-rgb: 130, 136, 142;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #adb3b9;
  --bs-btn-active-border-color: #a3aab0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #99a0a7;
  --bs-btn-disabled-border-color: #99a0a7;
}

.btn-secondary-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #868e96;
  --bs-btn-border-color: #868e96;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #989fa6;
  --bs-btn-hover-border-color: #9299a1;
  --bs-btn-focus-shadow-rgb: 114, 121, 128;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #9ea5ab;
  --bs-btn-active-border-color: #9299a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #868e96;
  --bs-btn-disabled-border-color: #868e96;
}

.btn-secondary-5 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-secondary-6 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #545b62;
  --bs-btn-border-color: #545b62;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #474d53;
  --bs-btn-hover-border-color: #43494e;
  --bs-btn-focus-shadow-rgb: 110, 116, 122;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #43494e;
  --bs-btn-active-border-color: #3f444a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #545b62;
  --bs-btn-disabled-border-color: #545b62;
}

.btn-secondary-7 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #44494e;
  --bs-btn-border-color: #44494e;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #3a3e42;
  --bs-btn-hover-border-color: #363a3e;
  --bs-btn-focus-shadow-rgb: 96, 100, 105;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #363a3e;
  --bs-btn-active-border-color: #33373b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #44494e;
  --bs-btn-disabled-border-color: #44494e;
}

.btn-secondary-8 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #313539;
  --bs-btn-border-color: #313539;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #2a2d30;
  --bs-btn-hover-border-color: #272a2e;
  --bs-btn-focus-shadow-rgb: 80, 83, 87;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #272a2e;
  --bs-btn-active-border-color: #25282b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #313539;
  --bs-btn-disabled-border-color: #313539;
}

.btn-secondary-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #202325;
  --bs-btn-border-color: #202325;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #1b1e1f;
  --bs-btn-hover-border-color: #1a1c1e;
  --bs-btn-focus-shadow-rgb: 65, 68, 70;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #1a1c1e;
  --bs-btn-active-border-color: #181a1c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #202325;
  --bs-btn-disabled-border-color: #202325;
}

.btn-secondary-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0d0f10;
  --bs-btn-border-color: #0d0f10;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0b0d0e;
  --bs-btn-hover-border-color: #0a0c0d;
  --bs-btn-focus-shadow-rgb: 49, 51, 52;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0a0c0d;
  --bs-btn-active-border-color: #0a0b0c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #0d0f10;
  --bs-btn-disabled-border-color: #0d0f10;
}

.btn-tertiary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F18F01;
  --bs-btn-border-color: #F18F01;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f3a027;
  --bs-btn-hover-border-color: #f29a1a;
  --bs-btn-focus-shadow-rgb: 205, 122, 1;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f4a534;
  --bs-btn-active-border-color: #f29a1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F18F01;
  --bs-btn-disabled-border-color: #F18F01;
}

.btn-tertiary-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffe5bf;
  --bs-btn-border-color: #ffe5bf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffe9c9;
  --bs-btn-hover-border-color: #ffe8c5;
  --bs-btn-focus-shadow-rgb: 217, 195, 162;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffeacc;
  --bs-btn-active-border-color: #ffe8c5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffe5bf;
  --bs-btn-disabled-border-color: #ffe5bf;
}

.btn-tertiary-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffd497;
  --bs-btn-border-color: #ffd497;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffdaa7;
  --bs-btn-hover-border-color: #ffd8a1;
  --bs-btn-focus-shadow-rgb: 217, 180, 128;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffddac;
  --bs-btn-active-border-color: #ffd8a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffd497;
  --bs-btn-disabled-border-color: #ffd497;
}

.btn-tertiary-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fec673;
  --bs-btn-border-color: #fec673;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fecf88;
  --bs-btn-hover-border-color: #fecc81;
  --bs-btn-focus-shadow-rgb: 216, 168, 98;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fed18f;
  --bs-btn-active-border-color: #fecc81;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fec673;
  --bs-btn-disabled-border-color: #fec673;
}

.btn-tertiary-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #feb54a;
  --bs-btn-border-color: #feb54a;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fec065;
  --bs-btn-hover-border-color: #febc5c;
  --bs-btn-focus-shadow-rgb: 216, 154, 63;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fec46e;
  --bs-btn-active-border-color: #febc5c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #feb54a;
  --bs-btn-disabled-border-color: #feb54a;
}

.btn-tertiary-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fea627;
  --bs-btn-border-color: #fea627;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #feb347;
  --bs-btn-hover-border-color: #feaf3d;
  --bs-btn-focus-shadow-rgb: 216, 141, 33;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #feb852;
  --bs-btn-active-border-color: #feaf3d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fea627;
  --bs-btn-disabled-border-color: #fea627;
}

.btn-tertiary-5 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F18F01;
  --bs-btn-border-color: #F18F01;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f3a027;
  --bs-btn-hover-border-color: #f29a1a;
  --bs-btn-focus-shadow-rgb: 205, 122, 1;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f4a534;
  --bs-btn-active-border-color: #f29a1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F18F01;
  --bs-btn-disabled-border-color: #F18F01;
}

.btn-tertiary-6 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #be7101;
  --bs-btn-border-color: #be7101;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c88627;
  --bs-btn-hover-border-color: #c57f1a;
  --bs-btn-focus-shadow-rgb: 162, 96, 1;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #cb8d34;
  --bs-btn-active-border-color: #c57f1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #be7101;
  --bs-btn-disabled-border-color: #be7101;
}

.btn-tertiary-7 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #9b5c01;
  --bs-btn-border-color: #9b5c01;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #844e01;
  --bs-btn-hover-border-color: #7c4a01;
  --bs-btn-focus-shadow-rgb: 170, 116, 39;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #7c4a01;
  --bs-btn-active-border-color: #744501;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #9b5c01;
  --bs-btn-disabled-border-color: #9b5c01;
}

.btn-tertiary-8 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #724400;
  --bs-btn-border-color: #724400;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #613a00;
  --bs-btn-hover-border-color: #5b3600;
  --bs-btn-focus-shadow-rgb: 135, 96, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #5b3600;
  --bs-btn-active-border-color: #563300;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #724400;
  --bs-btn-disabled-border-color: #724400;
}

.btn-tertiary-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #4e2f00;
  --bs-btn-border-color: #4e2f00;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #422800;
  --bs-btn-hover-border-color: #3e2600;
  --bs-btn-focus-shadow-rgb: 105, 78, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #3e2600;
  --bs-btn-active-border-color: #3b2300;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #4e2f00;
  --bs-btn-disabled-border-color: #4e2f00;
}

.btn-tertiary-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #261600;
  --bs-btn-border-color: #261600;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #201300;
  --bs-btn-hover-border-color: #1e1200;
  --bs-btn-focus-shadow-rgb: 71, 57, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #1e1200;
  --bs-btn-active-border-color: #1d1100;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #261600;
  --bs-btn-disabled-border-color: #261600;
}

.btn-success {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-success-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #84e8ba;
  --bs-btn-border-color: #84e8ba;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #96ebc4;
  --bs-btn-hover-border-color: #90eac1;
  --bs-btn-focus-shadow-rgb: 112, 197, 158;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #9dedc8;
  --bs-btn-active-border-color: #90eac1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #84e8ba;
  --bs-btn-disabled-border-color: #84e8ba;
}

.btn-success-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #61e2a6;
  --bs-btn-border-color: #61e2a6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #79e6b3;
  --bs-btn-hover-border-color: #71e5af;
  --bs-btn-focus-shadow-rgb: 82, 192, 141;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #81e8b8;
  --bs-btn-active-border-color: #71e5af;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #61e2a6;
  --bs-btn-disabled-border-color: #61e2a6;
}

.btn-success-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #43dc95;
  --bs-btn-border-color: #43dc95;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #5fe1a5;
  --bs-btn-hover-border-color: #56e0a0;
  --bs-btn-focus-shadow-rgb: 57, 187, 127;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #69e3aa;
  --bs-btn-active-border-color: #56e0a0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #43dc95;
  --bs-btn-disabled-border-color: #43dc95;
}

.btn-success-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #27d082;
  --bs-btn-border-color: #27d082;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #47d795;
  --bs-btn-hover-border-color: #3dd58f;
  --bs-btn-focus-shadow-rgb: 33, 177, 111;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #52d99b;
  --bs-btn-active-border-color: #3dd58f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #27d082;
  --bs-btn-disabled-border-color: #27d082;
}

.btn-success-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #21b26f;
  --bs-btn-border-color: #21b26f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #42be85;
  --bs-btn-hover-border-color: #37ba7d;
  --bs-btn-focus-shadow-rgb: 28, 151, 94;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #4dc18c;
  --bs-btn-active-border-color: #37ba7d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #21b26f;
  --bs-btn-disabled-border-color: #21b26f;
}

.btn-success-5 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-success-6 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #115c39;
  --bs-btn-border-color: #115c39;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0e4e30;
  --bs-btn-hover-border-color: #0e4a2e;
  --bs-btn-focus-shadow-rgb: 53, 116, 87;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0e4a2e;
  --bs-btn-active-border-color: #0d452b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #115c39;
  --bs-btn-disabled-border-color: #115c39;
}

.btn-success-7 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0b3e26;
  --bs-btn-border-color: #0b3e26;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #093520;
  --bs-btn-hover-border-color: #09321e;
  --bs-btn-focus-shadow-rgb: 48, 91, 71;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #09321e;
  --bs-btn-active-border-color: #082f1d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #0b3e26;
  --bs-btn-disabled-border-color: #0b3e26;
}

.btn-success-8 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #051b11;
  --bs-btn-border-color: #051b11;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #04170e;
  --bs-btn-hover-border-color: #04160e;
  --bs-btn-focus-shadow-rgb: 43, 61, 53;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #04160e;
  --bs-btn-active-border-color: #04140d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #051b11;
  --bs-btn-disabled-border-color: #051b11;
}

.btn-success-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: black;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: black;
  --bs-btn-disabled-border-color: black;
}

.btn-success-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: black;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: black;
  --bs-btn-disabled-border-color: black;
}

.btn-info {
  --bs-btn-color: #000000;
  --bs-btn-bg: #0DCAf0;
  --bs-btn-border-color: #0DCAf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #0DCAf0;
  --bs-btn-disabled-border-color: #0DCAf0;
}

.btn-info-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #cdf4fc;
  --bs-btn-border-color: #cdf4fc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d5f6fc;
  --bs-btn-hover-border-color: #d2f5fc;
  --bs-btn-focus-shadow-rgb: 174, 207, 214;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d7f6fd;
  --bs-btn-active-border-color: #d2f5fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #cdf4fc;
  --bs-btn-disabled-border-color: #cdf4fc;
}

.btn-info-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #a6ecfa;
  --bs-btn-border-color: #a6ecfa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #b3effb;
  --bs-btn-hover-border-color: #afeefb;
  --bs-btn-focus-shadow-rgb: 141, 201, 213;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #b8f0fb;
  --bs-btn-active-border-color: #afeefb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #a6ecfa;
  --bs-btn-disabled-border-color: #a6ecfa;
}

.btn-info-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #84e5f8;
  --bs-btn-border-color: #84e5f8;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #96e9f9;
  --bs-btn-hover-border-color: #90e8f9;
  --bs-btn-focus-shadow-rgb: 112, 195, 211;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #9deaf9;
  --bs-btn-active-border-color: #90e8f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #84e5f8;
  --bs-btn-disabled-border-color: #84e5f8;
}

.btn-info-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #5dddf6;
  --bs-btn-border-color: #5dddf6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #75e2f7;
  --bs-btn-hover-border-color: #6de0f7;
  --bs-btn-focus-shadow-rgb: 79, 188, 209;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #7de4f8;
  --bs-btn-active-border-color: #6de0f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #5dddf6;
  --bs-btn-disabled-border-color: #5dddf6;
}

.btn-info-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #3cd5f4;
  --bs-btn-border-color: #3cd5f4;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #59dbf6;
  --bs-btn-hover-border-color: #50d9f5;
  --bs-btn-focus-shadow-rgb: 51, 181, 207;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #63ddf6;
  --bs-btn-active-border-color: #50d9f5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #3cd5f4;
  --bs-btn-disabled-border-color: #3cd5f4;
}

.btn-info-5 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #0DCAf0;
  --bs-btn-border-color: #0DCAf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #0DCAf0;
  --bs-btn-disabled-border-color: #0DCAf0;
}

.btn-info-6 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #0aa1c0;
  --bs-btn-border-color: #0aa1c0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #2fafc9;
  --bs-btn-hover-border-color: #23aac6;
  --bs-btn-focus-shadow-rgb: 9, 137, 163;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3bb4cd;
  --bs-btn-active-border-color: #23aac6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #0aa1c0;
  --bs-btn-disabled-border-color: #0aa1c0;
}

.btn-info-7 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #09859e;
  --bs-btn-border-color: #09859e;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #2e97ad;
  --bs-btn-hover-border-color: #2291a8;
  --bs-btn-focus-shadow-rgb: 8, 113, 134;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3a9db1;
  --bs-btn-active-border-color: #2291a8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #09859e;
  --bs-btn-disabled-border-color: #09859e;
}

.btn-info-8 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #066477;
  --bs-btn-border-color: #066477;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #055565;
  --bs-btn-hover-border-color: #05505f;
  --bs-btn-focus-shadow-rgb: 43, 123, 139;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #05505f;
  --bs-btn-active-border-color: #054b59;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #066477;
  --bs-btn-disabled-border-color: #066477;
}

.btn-info-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #054855;
  --bs-btn-border-color: #054855;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #043d48;
  --bs-btn-hover-border-color: #043a44;
  --bs-btn-focus-shadow-rgb: 43, 99, 111;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #043a44;
  --bs-btn-active-border-color: #043640;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #054855;
  --bs-btn-disabled-border-color: #054855;
}

.btn-info-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #03272e;
  --bs-btn-border-color: #03272e;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #032127;
  --bs-btn-hover-border-color: #021f25;
  --bs-btn-focus-shadow-rgb: 41, 71, 77;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #021f25;
  --bs-btn-active-border-color: #021d23;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #03272e;
  --bs-btn-disabled-border-color: #03272e;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFCA2C;
  --bs-btn-border-color: #FFCA2C;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffd24c;
  --bs-btn-hover-border-color: #ffcf41;
  --bs-btn-focus-shadow-rgb: 217, 172, 37;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd556;
  --bs-btn-active-border-color: #ffcf41;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFCA2C;
  --bs-btn-disabled-border-color: #FFCA2C;
}

.btn-warning-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fffdf8;
  --bs-btn-border-color: #fffdf8;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fffdf9;
  --bs-btn-hover-border-color: #fffdf9;
  --bs-btn-focus-shadow-rgb: 217, 215, 211;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fffdf9;
  --bs-btn-active-border-color: #fffdf9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fffdf8;
  --bs-btn-disabled-border-color: #fffdf8;
}

.btn-warning-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fff3cf;
  --bs-btn-border-color: #fff3cf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fff5d6;
  --bs-btn-hover-border-color: #fff4d4;
  --bs-btn-focus-shadow-rgb: 217, 207, 176;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fff5d9;
  --bs-btn-active-border-color: #fff4d4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fff3cf;
  --bs-btn-disabled-border-color: #fff3cf;
}

.btn-warning-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffeaac;
  --bs-btn-border-color: #ffeaac;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffedb8;
  --bs-btn-hover-border-color: #ffecb4;
  --bs-btn-focus-shadow-rgb: 217, 199, 146;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffeebd;
  --bs-btn-active-border-color: #ffecb4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffeaac;
  --bs-btn-disabled-border-color: #ffeaac;
}

.btn-warning-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffe083;
  --bs-btn-border-color: #ffe083;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffe596;
  --bs-btn-hover-border-color: #ffe38f;
  --bs-btn-focus-shadow-rgb: 217, 190, 111;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffe69c;
  --bs-btn-active-border-color: #ffe38f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffe083;
  --bs-btn-disabled-border-color: #ffe083;
}

.btn-warning-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffd75f;
  --bs-btn-border-color: #ffd75f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffdd77;
  --bs-btn-hover-border-color: #ffdb6f;
  --bs-btn-focus-shadow-rgb: 217, 183, 81;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffdf7f;
  --bs-btn-active-border-color: #ffdb6f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffd75f;
  --bs-btn-disabled-border-color: #ffd75f;
}

.btn-warning-5 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFCA2C;
  --bs-btn-border-color: #FFCA2C;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffd24c;
  --bs-btn-hover-border-color: #ffcf41;
  --bs-btn-focus-shadow-rgb: 217, 172, 37;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd556;
  --bs-btn-active-border-color: #ffcf41;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFCA2C;
  --bs-btn-disabled-border-color: #FFCA2C;
}

.btn-warning-6 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f8ba00;
  --bs-btn-border-color: #f8ba00;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f9c426;
  --bs-btn-hover-border-color: #f9c11a;
  --bs-btn-focus-shadow-rgb: 211, 158, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f9c833;
  --bs-btn-active-border-color: #f9c11a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f8ba00;
  --bs-btn-disabled-border-color: #f8ba00;
}

.btn-warning-7 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #d49f00;
  --bs-btn-border-color: #d49f00;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #daad26;
  --bs-btn-hover-border-color: #d8a91a;
  --bs-btn-focus-shadow-rgb: 180, 135, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ddb233;
  --bs-btn-active-border-color: #d8a91a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #d49f00;
  --bs-btn-disabled-border-color: #d49f00;
}

.btn-warning-8 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ac8000;
  --bs-btn-border-color: #ac8000;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #b89326;
  --bs-btn-hover-border-color: #b48d1a;
  --bs-btn-focus-shadow-rgb: 146, 109, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #bd9933;
  --bs-btn-active-border-color: #b48d1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ac8000;
  --bs-btn-disabled-border-color: #ac8000;
}

.btn-warning-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #886600;
  --bs-btn-border-color: #886600;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #745700;
  --bs-btn-hover-border-color: #6d5200;
  --bs-btn-focus-shadow-rgb: 154, 125, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #6d5200;
  --bs-btn-active-border-color: #664d00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #886600;
  --bs-btn-disabled-border-color: #886600;
}

.btn-warning-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #5f4700;
  --bs-btn-border-color: #5f4700;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #513c00;
  --bs-btn-hover-border-color: #4c3900;
  --bs-btn-focus-shadow-rgb: 119, 99, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #4c3900;
  --bs-btn-active-border-color: #473500;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #5f4700;
  --bs-btn-disabled-border-color: #5f4700;
}

.btn-danger {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #DC3545;
  --bs-btn-border-color: #DC3545;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #DC3545;
  --bs-btn-disabled-border-color: #DC3545;
}

.btn-danger-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fae3e5;
  --bs-btn-border-color: #fae3e5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fbe7e9;
  --bs-btn-hover-border-color: #fbe6e8;
  --bs-btn-focus-shadow-rgb: 213, 193, 195;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fbe9ea;
  --bs-btn-active-border-color: #fbe6e8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fae3e5;
  --bs-btn-disabled-border-color: #fae3e5;
}

.btn-danger-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f4c0c5;
  --bs-btn-border-color: #f4c0c5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f6c9ce;
  --bs-btn-hover-border-color: #f5c6cb;
  --bs-btn-focus-shadow-rgb: 207, 163, 167;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f6cdd1;
  --bs-btn-active-border-color: #f5c6cb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f4c0c5;
  --bs-btn-disabled-border-color: #f4c0c5;
}

.btn-danger-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #efa2a9;
  --bs-btn-border-color: #efa2a9;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f1b0b6;
  --bs-btn-hover-border-color: #f1abb2;
  --bs-btn-focus-shadow-rgb: 203, 138, 144;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f2b5ba;
  --bs-btn-active-border-color: #f1abb2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #efa2a9;
  --bs-btn-disabled-border-color: #efa2a9;
}

.btn-danger-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e97f89;
  --bs-btn-border-color: #e97f89;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ec929b;
  --bs-btn-hover-border-color: #eb8c95;
  --bs-btn-focus-shadow-rgb: 198, 108, 116;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ed99a1;
  --bs-btn-active-border-color: #eb8c95;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e97f89;
  --bs-btn-disabled-border-color: #e97f89;
}

.btn-danger-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e4606d;
  --bs-btn-border-color: #e4606d;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e87883;
  --bs-btn-hover-border-color: #e7707c;
  --bs-btn-focus-shadow-rgb: 194, 82, 93;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e9808a;
  --bs-btn-active-border-color: #e7707c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e4606d;
  --bs-btn-disabled-border-color: #e4606d;
}

.btn-danger-5 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #DC3545;
  --bs-btn-border-color: #DC3545;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #DC3545;
  --bs-btn-disabled-border-color: #DC3545;
}

.btn-danger-6 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #bd2130;
  --bs-btn-border-color: #bd2130;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #a11c29;
  --bs-btn-hover-border-color: #971a26;
  --bs-btn-focus-shadow-rgb: 199, 66, 79;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #971a26;
  --bs-btn-active-border-color: #8e1924;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #bd2130;
  --bs-btn-disabled-border-color: #bd2130;
}

.btn-danger-7 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #9f1c28;
  --bs-btn-border-color: #9f1c28;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #871822;
  --bs-btn-hover-border-color: #7f1620;
  --bs-btn-focus-shadow-rgb: 173, 62, 72;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #7f1620;
  --bs-btn-active-border-color: #77151e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #9f1c28;
  --bs-btn-disabled-border-color: #9f1c28;
}

.btn-danger-8 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #7c151f;
  --bs-btn-border-color: #7c151f;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #69121a;
  --bs-btn-hover-border-color: #631119;
  --bs-btn-focus-shadow-rgb: 144, 56, 65;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #631119;
  --bs-btn-active-border-color: #5d1017;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #7c151f;
  --bs-btn-disabled-border-color: #7c151f;
}

.btn-danger-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #5e1018;
  --bs-btn-border-color: #5e1018;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #500e14;
  --bs-btn-hover-border-color: #4b0d13;
  --bs-btn-focus-shadow-rgb: 118, 52, 59;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #4b0d13;
  --bs-btn-active-border-color: #470c12;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #5e1018;
  --bs-btn-disabled-border-color: #5e1018;
}

.btn-danger-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #3b0a0f;
  --bs-btn-border-color: #3b0a0f;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #32090d;
  --bs-btn-hover-border-color: #2f080c;
  --bs-btn-focus-shadow-rgb: 88, 47, 51;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #2f080c;
  --bs-btn-active-border-color: #2c080b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #3b0a0f;
  --bs-btn-disabled-border-color: #3b0a0f;
}

.btn-gray {
  --bs-btn-color: #000000;
  --bs-btn-bg: #7F7F7F;
  --bs-btn-border-color: #7F7F7F;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #929292;
  --bs-btn-hover-border-color: #8c8c8c;
  --bs-btn-focus-shadow-rgb: 108, 108, 108;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #999999;
  --bs-btn-active-border-color: #8c8c8c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #7F7F7F;
  --bs-btn-disabled-border-color: #7F7F7F;
}

.btn-gray-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e5e5e5;
  --bs-btn-border-color: #e5e5e5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e9e9e9;
  --bs-btn-hover-border-color: #e8e8e8;
  --bs-btn-focus-shadow-rgb: 195, 195, 195;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eaeaea;
  --bs-btn-active-border-color: #e8e8e8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e5e5e5;
  --bs-btn-disabled-border-color: #e5e5e5;
}

.btn-gray-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #d1d1d1;
  --bs-btn-border-color: #d1d1d1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d8d8d8;
  --bs-btn-hover-border-color: #d6d6d6;
  --bs-btn-focus-shadow-rgb: 178, 178, 178;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #dadada;
  --bs-btn-active-border-color: #d6d6d6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #d1d1d1;
  --bs-btn-disabled-border-color: #d1d1d1;
}

.btn-gray-2 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #bfbfbf;
  --bs-btn-border-color: #bfbfbf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c9c9c9;
  --bs-btn-hover-border-color: #c5c5c5;
  --bs-btn-focus-shadow-rgb: 162, 162, 162;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #cccccc;
  --bs-btn-active-border-color: #c5c5c5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #bfbfbf;
  --bs-btn-disabled-border-color: #bfbfbf;
}

.btn-gray-3 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #aaaaaa;
  --bs-btn-border-color: #aaaaaa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #b7b7b7;
  --bs-btn-hover-border-color: #b3b3b3;
  --bs-btn-focus-shadow-rgb: 145, 145, 145;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #bbbbbb;
  --bs-btn-active-border-color: #b3b3b3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #aaaaaa;
  --bs-btn-disabled-border-color: #aaaaaa;
}

.btn-gray-4 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #999999;
  --bs-btn-border-color: #999999;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a8a8a8;
  --bs-btn-hover-border-color: #a3a3a3;
  --bs-btn-focus-shadow-rgb: 130, 130, 130;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #adadad;
  --bs-btn-active-border-color: #a3a3a3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #999999;
  --bs-btn-disabled-border-color: #999999;
}

.btn-gray-5 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #7F7F7F;
  --bs-btn-border-color: #7F7F7F;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #929292;
  --bs-btn-hover-border-color: #8c8c8c;
  --bs-btn-focus-shadow-rgb: 108, 108, 108;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #999999;
  --bs-btn-active-border-color: #8c8c8c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #7F7F7F;
  --bs-btn-disabled-border-color: #7F7F7F;
}

.btn-gray-6 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #666666;
  --bs-btn-border-color: #666666;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #575757;
  --bs-btn-hover-border-color: #525252;
  --bs-btn-focus-shadow-rgb: 125, 125, 125;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #525252;
  --bs-btn-active-border-color: #4d4d4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #666666;
  --bs-btn-disabled-border-color: #666666;
}

.btn-gray-7 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #545454;
  --bs-btn-border-color: #545454;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #474747;
  --bs-btn-hover-border-color: #434343;
  --bs-btn-focus-shadow-rgb: 110, 110, 110;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #434343;
  --bs-btn-active-border-color: #3f3f3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #545454;
  --bs-btn-disabled-border-color: #545454;
}

.btn-gray-8 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #3f3f3f;
  --bs-btn-border-color: #3f3f3f;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #363636;
  --bs-btn-hover-border-color: #323232;
  --bs-btn-focus-shadow-rgb: 92, 92, 92;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #323232;
  --bs-btn-active-border-color: #2f2f2f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #3f3f3f;
  --bs-btn-disabled-border-color: #3f3f3f;
}

.btn-gray-9 {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #2d2d2d;
  --bs-btn-border-color: #2d2d2d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #262626;
  --bs-btn-hover-border-color: #242424;
  --bs-btn-focus-shadow-rgb: 77, 77, 77;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #242424;
  --bs-btn-active-border-color: #222222;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #2d2d2d;
  --bs-btn-disabled-border-color: #2d2d2d;
}

.btn-gray-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #191919;
  --bs-btn-border-color: #191919;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #151515;
  --bs-btn-hover-border-color: #141414;
  --bs-btn-focus-shadow-rgb: 60, 60, 60;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #141414;
  --bs-btn-active-border-color: #131313;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #191919;
  --bs-btn-disabled-border-color: #191919;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F8F9FA;
  --bs-btn-border-color: #F8F9FA;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F8F9FA;
  --bs-btn-disabled-border-color: #F8F9FA;
}

.btn-dark {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-white {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFFFFF;
  --bs-btn-disabled-border-color: #FFFFFF;
}

.btn-black {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-primary-light {
  --bs-btn-color: #d7e7ff;
  --bs-btn-border-color: #d7e7ff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d7e7ff;
  --bs-btn-hover-border-color: #d7e7ff;
  --bs-btn-focus-shadow-rgb: 215, 231, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d7e7ff;
  --bs-btn-active-border-color: #d7e7ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d7e7ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d7e7ff;
  --bs-gradient: none;
}

.btn-outline-primary-1 {
  --bs-btn-color: #afcffe;
  --bs-btn-border-color: #afcffe;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #afcffe;
  --bs-btn-hover-border-color: #afcffe;
  --bs-btn-focus-shadow-rgb: 175, 207, 254;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #afcffe;
  --bs-btn-active-border-color: #afcffe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #afcffe;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #afcffe;
  --bs-gradient: none;
}

.btn-outline-primary-2 {
  --bs-btn-color: #8bbafe;
  --bs-btn-border-color: #8bbafe;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #8bbafe;
  --bs-btn-hover-border-color: #8bbafe;
  --bs-btn-focus-shadow-rgb: 139, 186, 254;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #8bbafe;
  --bs-btn-active-border-color: #8bbafe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #8bbafe;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8bbafe;
  --bs-gradient: none;
}

.btn-outline-primary-3 {
  --bs-btn-color: #63a2fe;
  --bs-btn-border-color: #63a2fe;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #63a2fe;
  --bs-btn-hover-border-color: #63a2fe;
  --bs-btn-focus-shadow-rgb: 99, 162, 254;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #63a2fe;
  --bs-btn-active-border-color: #63a2fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #63a2fe;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #63a2fe;
  --bs-gradient: none;
}

.btn-outline-primary-4 {
  --bs-btn-color: #408cfd;
  --bs-btn-border-color: #408cfd;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #408cfd;
  --bs-btn-hover-border-color: #408cfd;
  --bs-btn-focus-shadow-rgb: 64, 140, 253;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #408cfd;
  --bs-btn-active-border-color: #408cfd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #408cfd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #408cfd;
  --bs-gradient: none;
}

.btn-outline-primary-5 {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-primary-6 {
  --bs-btn-color: #0257d5;
  --bs-btn-border-color: #0257d5;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0257d5;
  --bs-btn-hover-border-color: #0257d5;
  --bs-btn-focus-shadow-rgb: 2, 87, 213;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0257d5;
  --bs-btn-active-border-color: #0257d5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0257d5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0257d5;
  --bs-gradient: none;
}

.btn-outline-primary-7 {
  --bs-btn-color: #0149b2;
  --bs-btn-border-color: #0149b2;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0149b2;
  --bs-btn-hover-border-color: #0149b2;
  --bs-btn-focus-shadow-rgb: 1, 73, 178;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0149b2;
  --bs-btn-active-border-color: #0149b2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0149b2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0149b2;
  --bs-gradient: none;
}

.btn-outline-primary-8 {
  --bs-btn-color: #013889;
  --bs-btn-border-color: #013889;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #013889;
  --bs-btn-hover-border-color: #013889;
  --bs-btn-focus-shadow-rgb: 1, 56, 137;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #013889;
  --bs-btn-active-border-color: #013889;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #013889;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #013889;
  --bs-gradient: none;
}

.btn-outline-primary-9 {
  --bs-btn-color: #012a66;
  --bs-btn-border-color: #012a66;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #012a66;
  --bs-btn-hover-border-color: #012a66;
  --bs-btn-focus-shadow-rgb: 1, 42, 102;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #012a66;
  --bs-btn-active-border-color: #012a66;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #012a66;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #012a66;
  --bs-gradient: none;
}

.btn-outline-primary-dark {
  --bs-btn-color: #01193d;
  --bs-btn-border-color: #01193d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #01193d;
  --bs-btn-hover-border-color: #01193d;
  --bs-btn-focus-shadow-rgb: 1, 25, 61;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #01193d;
  --bs-btn-active-border-color: #01193d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #01193d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #01193d;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-secondary-light {
  --bs-btn-color: #d8dbdd;
  --bs-btn-border-color: #d8dbdd;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d8dbdd;
  --bs-btn-hover-border-color: #d8dbdd;
  --bs-btn-focus-shadow-rgb: 216, 219, 221;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d8dbdd;
  --bs-btn-active-border-color: #d8dbdd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d8dbdd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d8dbdd;
  --bs-gradient: none;
}

.btn-outline-secondary-1 {
  --bs-btn-color: #c2c6ca;
  --bs-btn-border-color: #c2c6ca;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c2c6ca;
  --bs-btn-hover-border-color: #c2c6ca;
  --bs-btn-focus-shadow-rgb: 194, 198, 202;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c2c6ca;
  --bs-btn-active-border-color: #c2c6ca;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c2c6ca;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c2c6ca;
  --bs-gradient: none;
}

.btn-outline-secondary-2 {
  --bs-btn-color: #afb5ba;
  --bs-btn-border-color: #afb5ba;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #afb5ba;
  --bs-btn-hover-border-color: #afb5ba;
  --bs-btn-focus-shadow-rgb: 175, 181, 186;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #afb5ba;
  --bs-btn-active-border-color: #afb5ba;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #afb5ba;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #afb5ba;
  --bs-gradient: none;
}

.btn-outline-secondary-3 {
  --bs-btn-color: #99a0a7;
  --bs-btn-border-color: #99a0a7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #99a0a7;
  --bs-btn-hover-border-color: #99a0a7;
  --bs-btn-focus-shadow-rgb: 153, 160, 167;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #99a0a7;
  --bs-btn-active-border-color: #99a0a7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #99a0a7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #99a0a7;
  --bs-gradient: none;
}

.btn-outline-secondary-4 {
  --bs-btn-color: #868e96;
  --bs-btn-border-color: #868e96;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #868e96;
  --bs-btn-hover-border-color: #868e96;
  --bs-btn-focus-shadow-rgb: 134, 142, 150;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #868e96;
  --bs-btn-active-border-color: #868e96;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #868e96;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #868e96;
  --bs-gradient: none;
}

.btn-outline-secondary-5 {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-secondary-6 {
  --bs-btn-color: #545b62;
  --bs-btn-border-color: #545b62;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #545b62;
  --bs-btn-hover-border-color: #545b62;
  --bs-btn-focus-shadow-rgb: 84, 91, 98;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #545b62;
  --bs-btn-active-border-color: #545b62;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #545b62;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #545b62;
  --bs-gradient: none;
}

.btn-outline-secondary-7 {
  --bs-btn-color: #44494e;
  --bs-btn-border-color: #44494e;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #44494e;
  --bs-btn-hover-border-color: #44494e;
  --bs-btn-focus-shadow-rgb: 68, 73, 78;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #44494e;
  --bs-btn-active-border-color: #44494e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #44494e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #44494e;
  --bs-gradient: none;
}

.btn-outline-secondary-8 {
  --bs-btn-color: #313539;
  --bs-btn-border-color: #313539;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #313539;
  --bs-btn-hover-border-color: #313539;
  --bs-btn-focus-shadow-rgb: 49, 53, 57;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #313539;
  --bs-btn-active-border-color: #313539;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #313539;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #313539;
  --bs-gradient: none;
}

.btn-outline-secondary-9 {
  --bs-btn-color: #202325;
  --bs-btn-border-color: #202325;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #202325;
  --bs-btn-hover-border-color: #202325;
  --bs-btn-focus-shadow-rgb: 32, 35, 37;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #202325;
  --bs-btn-active-border-color: #202325;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #202325;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #202325;
  --bs-gradient: none;
}

.btn-outline-secondary-dark {
  --bs-btn-color: #0d0f10;
  --bs-btn-border-color: #0d0f10;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0d0f10;
  --bs-btn-hover-border-color: #0d0f10;
  --bs-btn-focus-shadow-rgb: 13, 15, 16;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0d0f10;
  --bs-btn-active-border-color: #0d0f10;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d0f10;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d0f10;
  --bs-gradient: none;
}

.btn-outline-tertiary {
  --bs-btn-color: #F18F01;
  --bs-btn-border-color: #F18F01;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F18F01;
  --bs-btn-hover-border-color: #F18F01;
  --bs-btn-focus-shadow-rgb: 241, 143, 1;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F18F01;
  --bs-btn-active-border-color: #F18F01;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F18F01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F18F01;
  --bs-gradient: none;
}

.btn-outline-tertiary-light {
  --bs-btn-color: #ffe5bf;
  --bs-btn-border-color: #ffe5bf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffe5bf;
  --bs-btn-hover-border-color: #ffe5bf;
  --bs-btn-focus-shadow-rgb: 255, 229, 191;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffe5bf;
  --bs-btn-active-border-color: #ffe5bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffe5bf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffe5bf;
  --bs-gradient: none;
}

.btn-outline-tertiary-1 {
  --bs-btn-color: #ffd497;
  --bs-btn-border-color: #ffd497;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffd497;
  --bs-btn-hover-border-color: #ffd497;
  --bs-btn-focus-shadow-rgb: 255, 212, 151;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd497;
  --bs-btn-active-border-color: #ffd497;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffd497;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffd497;
  --bs-gradient: none;
}

.btn-outline-tertiary-2 {
  --bs-btn-color: #fec673;
  --bs-btn-border-color: #fec673;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fec673;
  --bs-btn-hover-border-color: #fec673;
  --bs-btn-focus-shadow-rgb: 254, 198, 115;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fec673;
  --bs-btn-active-border-color: #fec673;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fec673;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fec673;
  --bs-gradient: none;
}

.btn-outline-tertiary-3 {
  --bs-btn-color: #feb54a;
  --bs-btn-border-color: #feb54a;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #feb54a;
  --bs-btn-hover-border-color: #feb54a;
  --bs-btn-focus-shadow-rgb: 254, 181, 74;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #feb54a;
  --bs-btn-active-border-color: #feb54a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #feb54a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #feb54a;
  --bs-gradient: none;
}

.btn-outline-tertiary-4 {
  --bs-btn-color: #fea627;
  --bs-btn-border-color: #fea627;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fea627;
  --bs-btn-hover-border-color: #fea627;
  --bs-btn-focus-shadow-rgb: 254, 166, 39;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fea627;
  --bs-btn-active-border-color: #fea627;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fea627;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fea627;
  --bs-gradient: none;
}

.btn-outline-tertiary-5 {
  --bs-btn-color: #F18F01;
  --bs-btn-border-color: #F18F01;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F18F01;
  --bs-btn-hover-border-color: #F18F01;
  --bs-btn-focus-shadow-rgb: 241, 143, 1;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F18F01;
  --bs-btn-active-border-color: #F18F01;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F18F01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F18F01;
  --bs-gradient: none;
}

.btn-outline-tertiary-6 {
  --bs-btn-color: #be7101;
  --bs-btn-border-color: #be7101;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #be7101;
  --bs-btn-hover-border-color: #be7101;
  --bs-btn-focus-shadow-rgb: 190, 113, 1;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #be7101;
  --bs-btn-active-border-color: #be7101;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #be7101;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #be7101;
  --bs-gradient: none;
}

.btn-outline-tertiary-7 {
  --bs-btn-color: #9b5c01;
  --bs-btn-border-color: #9b5c01;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #9b5c01;
  --bs-btn-hover-border-color: #9b5c01;
  --bs-btn-focus-shadow-rgb: 155, 92, 1;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #9b5c01;
  --bs-btn-active-border-color: #9b5c01;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #9b5c01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9b5c01;
  --bs-gradient: none;
}

.btn-outline-tertiary-8 {
  --bs-btn-color: #724400;
  --bs-btn-border-color: #724400;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #724400;
  --bs-btn-hover-border-color: #724400;
  --bs-btn-focus-shadow-rgb: 114, 68, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #724400;
  --bs-btn-active-border-color: #724400;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #724400;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #724400;
  --bs-gradient: none;
}

.btn-outline-tertiary-9 {
  --bs-btn-color: #4e2f00;
  --bs-btn-border-color: #4e2f00;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #4e2f00;
  --bs-btn-hover-border-color: #4e2f00;
  --bs-btn-focus-shadow-rgb: 78, 47, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #4e2f00;
  --bs-btn-active-border-color: #4e2f00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4e2f00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4e2f00;
  --bs-gradient: none;
}

.btn-outline-tertiary-dark {
  --bs-btn-color: #261600;
  --bs-btn-border-color: #261600;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #261600;
  --bs-btn-hover-border-color: #261600;
  --bs-btn-focus-shadow-rgb: 38, 22, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #261600;
  --bs-btn-active-border-color: #261600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #261600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #261600;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-success-light {
  --bs-btn-color: #84e8ba;
  --bs-btn-border-color: #84e8ba;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #84e8ba;
  --bs-btn-hover-border-color: #84e8ba;
  --bs-btn-focus-shadow-rgb: 132, 232, 186;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #84e8ba;
  --bs-btn-active-border-color: #84e8ba;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #84e8ba;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #84e8ba;
  --bs-gradient: none;
}

.btn-outline-success-1 {
  --bs-btn-color: #61e2a6;
  --bs-btn-border-color: #61e2a6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #61e2a6;
  --bs-btn-hover-border-color: #61e2a6;
  --bs-btn-focus-shadow-rgb: 97, 226, 166;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #61e2a6;
  --bs-btn-active-border-color: #61e2a6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #61e2a6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #61e2a6;
  --bs-gradient: none;
}

.btn-outline-success-2 {
  --bs-btn-color: #43dc95;
  --bs-btn-border-color: #43dc95;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #43dc95;
  --bs-btn-hover-border-color: #43dc95;
  --bs-btn-focus-shadow-rgb: 67, 220, 149;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #43dc95;
  --bs-btn-active-border-color: #43dc95;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #43dc95;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #43dc95;
  --bs-gradient: none;
}

.btn-outline-success-3 {
  --bs-btn-color: #27d082;
  --bs-btn-border-color: #27d082;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #27d082;
  --bs-btn-hover-border-color: #27d082;
  --bs-btn-focus-shadow-rgb: 39, 208, 130;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #27d082;
  --bs-btn-active-border-color: #27d082;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #27d082;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #27d082;
  --bs-gradient: none;
}

.btn-outline-success-4 {
  --bs-btn-color: #21b26f;
  --bs-btn-border-color: #21b26f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #21b26f;
  --bs-btn-hover-border-color: #21b26f;
  --bs-btn-focus-shadow-rgb: 33, 178, 111;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #21b26f;
  --bs-btn-active-border-color: #21b26f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #21b26f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #21b26f;
  --bs-gradient: none;
}

.btn-outline-success-5 {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-success-6 {
  --bs-btn-color: #115c39;
  --bs-btn-border-color: #115c39;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #115c39;
  --bs-btn-hover-border-color: #115c39;
  --bs-btn-focus-shadow-rgb: 17, 92, 57;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #115c39;
  --bs-btn-active-border-color: #115c39;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #115c39;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #115c39;
  --bs-gradient: none;
}

.btn-outline-success-7 {
  --bs-btn-color: #0b3e26;
  --bs-btn-border-color: #0b3e26;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0b3e26;
  --bs-btn-hover-border-color: #0b3e26;
  --bs-btn-focus-shadow-rgb: 11, 62, 38;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0b3e26;
  --bs-btn-active-border-color: #0b3e26;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0b3e26;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0b3e26;
  --bs-gradient: none;
}

.btn-outline-success-8 {
  --bs-btn-color: #051b11;
  --bs-btn-border-color: #051b11;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #051b11;
  --bs-btn-hover-border-color: #051b11;
  --bs-btn-focus-shadow-rgb: 5, 27, 17;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #051b11;
  --bs-btn-active-border-color: #051b11;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #051b11;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #051b11;
  --bs-gradient: none;
}

.btn-outline-success-9 {
  --bs-btn-color: black;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: black;
  --bs-gradient: none;
}

.btn-outline-success-dark {
  --bs-btn-color: black;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: black;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0DCAf0;
  --bs-btn-border-color: #0DCAf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #0DCAf0;
  --bs-btn-hover-border-color: #0DCAf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #0DCAf0;
  --bs-btn-active-border-color: #0DCAf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0DCAf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0DCAf0;
  --bs-gradient: none;
}

.btn-outline-info-light {
  --bs-btn-color: #cdf4fc;
  --bs-btn-border-color: #cdf4fc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cdf4fc;
  --bs-btn-hover-border-color: #cdf4fc;
  --bs-btn-focus-shadow-rgb: 205, 244, 252;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #cdf4fc;
  --bs-btn-active-border-color: #cdf4fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #cdf4fc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cdf4fc;
  --bs-gradient: none;
}

.btn-outline-info-1 {
  --bs-btn-color: #a6ecfa;
  --bs-btn-border-color: #a6ecfa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a6ecfa;
  --bs-btn-hover-border-color: #a6ecfa;
  --bs-btn-focus-shadow-rgb: 166, 236, 250;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a6ecfa;
  --bs-btn-active-border-color: #a6ecfa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #a6ecfa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a6ecfa;
  --bs-gradient: none;
}

.btn-outline-info-2 {
  --bs-btn-color: #84e5f8;
  --bs-btn-border-color: #84e5f8;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #84e5f8;
  --bs-btn-hover-border-color: #84e5f8;
  --bs-btn-focus-shadow-rgb: 132, 229, 248;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #84e5f8;
  --bs-btn-active-border-color: #84e5f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #84e5f8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #84e5f8;
  --bs-gradient: none;
}

.btn-outline-info-3 {
  --bs-btn-color: #5dddf6;
  --bs-btn-border-color: #5dddf6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #5dddf6;
  --bs-btn-hover-border-color: #5dddf6;
  --bs-btn-focus-shadow-rgb: 93, 221, 246;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #5dddf6;
  --bs-btn-active-border-color: #5dddf6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5dddf6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5dddf6;
  --bs-gradient: none;
}

.btn-outline-info-4 {
  --bs-btn-color: #3cd5f4;
  --bs-btn-border-color: #3cd5f4;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3cd5f4;
  --bs-btn-hover-border-color: #3cd5f4;
  --bs-btn-focus-shadow-rgb: 60, 213, 244;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3cd5f4;
  --bs-btn-active-border-color: #3cd5f4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3cd5f4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3cd5f4;
  --bs-gradient: none;
}

.btn-outline-info-5 {
  --bs-btn-color: #0DCAf0;
  --bs-btn-border-color: #0DCAf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #0DCAf0;
  --bs-btn-hover-border-color: #0DCAf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #0DCAf0;
  --bs-btn-active-border-color: #0DCAf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0DCAf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0DCAf0;
  --bs-gradient: none;
}

.btn-outline-info-6 {
  --bs-btn-color: #0aa1c0;
  --bs-btn-border-color: #0aa1c0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #0aa1c0;
  --bs-btn-hover-border-color: #0aa1c0;
  --bs-btn-focus-shadow-rgb: 10, 161, 192;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #0aa1c0;
  --bs-btn-active-border-color: #0aa1c0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0aa1c0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0aa1c0;
  --bs-gradient: none;
}

.btn-outline-info-7 {
  --bs-btn-color: #09859e;
  --bs-btn-border-color: #09859e;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #09859e;
  --bs-btn-hover-border-color: #09859e;
  --bs-btn-focus-shadow-rgb: 9, 133, 158;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #09859e;
  --bs-btn-active-border-color: #09859e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #09859e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #09859e;
  --bs-gradient: none;
}

.btn-outline-info-8 {
  --bs-btn-color: #066477;
  --bs-btn-border-color: #066477;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #066477;
  --bs-btn-hover-border-color: #066477;
  --bs-btn-focus-shadow-rgb: 6, 100, 119;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #066477;
  --bs-btn-active-border-color: #066477;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #066477;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #066477;
  --bs-gradient: none;
}

.btn-outline-info-9 {
  --bs-btn-color: #054855;
  --bs-btn-border-color: #054855;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #054855;
  --bs-btn-hover-border-color: #054855;
  --bs-btn-focus-shadow-rgb: 5, 72, 85;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #054855;
  --bs-btn-active-border-color: #054855;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #054855;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #054855;
  --bs-gradient: none;
}

.btn-outline-info-dark {
  --bs-btn-color: #03272e;
  --bs-btn-border-color: #03272e;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #03272e;
  --bs-btn-hover-border-color: #03272e;
  --bs-btn-focus-shadow-rgb: 3, 39, 46;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #03272e;
  --bs-btn-active-border-color: #03272e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #03272e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #03272e;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFCA2C;
  --bs-btn-border-color: #FFCA2C;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFCA2C;
  --bs-btn-hover-border-color: #FFCA2C;
  --bs-btn-focus-shadow-rgb: 255, 202, 44;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFCA2C;
  --bs-btn-active-border-color: #FFCA2C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFCA2C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFCA2C;
  --bs-gradient: none;
}

.btn-outline-warning-light {
  --bs-btn-color: #fffdf8;
  --bs-btn-border-color: #fffdf8;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fffdf8;
  --bs-btn-hover-border-color: #fffdf8;
  --bs-btn-focus-shadow-rgb: 255, 253, 248;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fffdf8;
  --bs-btn-active-border-color: #fffdf8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fffdf8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fffdf8;
  --bs-gradient: none;
}

.btn-outline-warning-1 {
  --bs-btn-color: #fff3cf;
  --bs-btn-border-color: #fff3cf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fff3cf;
  --bs-btn-hover-border-color: #fff3cf;
  --bs-btn-focus-shadow-rgb: 255, 243, 207;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fff3cf;
  --bs-btn-active-border-color: #fff3cf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff3cf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff3cf;
  --bs-gradient: none;
}

.btn-outline-warning-2 {
  --bs-btn-color: #ffeaac;
  --bs-btn-border-color: #ffeaac;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffeaac;
  --bs-btn-hover-border-color: #ffeaac;
  --bs-btn-focus-shadow-rgb: 255, 234, 172;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffeaac;
  --bs-btn-active-border-color: #ffeaac;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffeaac;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffeaac;
  --bs-gradient: none;
}

.btn-outline-warning-3 {
  --bs-btn-color: #ffe083;
  --bs-btn-border-color: #ffe083;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffe083;
  --bs-btn-hover-border-color: #ffe083;
  --bs-btn-focus-shadow-rgb: 255, 224, 131;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffe083;
  --bs-btn-active-border-color: #ffe083;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffe083;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffe083;
  --bs-gradient: none;
}

.btn-outline-warning-4 {
  --bs-btn-color: #ffd75f;
  --bs-btn-border-color: #ffd75f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffd75f;
  --bs-btn-hover-border-color: #ffd75f;
  --bs-btn-focus-shadow-rgb: 255, 215, 95;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd75f;
  --bs-btn-active-border-color: #ffd75f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffd75f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffd75f;
  --bs-gradient: none;
}

.btn-outline-warning-5 {
  --bs-btn-color: #FFCA2C;
  --bs-btn-border-color: #FFCA2C;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFCA2C;
  --bs-btn-hover-border-color: #FFCA2C;
  --bs-btn-focus-shadow-rgb: 255, 202, 44;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFCA2C;
  --bs-btn-active-border-color: #FFCA2C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFCA2C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFCA2C;
  --bs-gradient: none;
}

.btn-outline-warning-6 {
  --bs-btn-color: #f8ba00;
  --bs-btn-border-color: #f8ba00;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f8ba00;
  --bs-btn-hover-border-color: #f8ba00;
  --bs-btn-focus-shadow-rgb: 248, 186, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f8ba00;
  --bs-btn-active-border-color: #f8ba00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8ba00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8ba00;
  --bs-gradient: none;
}

.btn-outline-warning-7 {
  --bs-btn-color: #d49f00;
  --bs-btn-border-color: #d49f00;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d49f00;
  --bs-btn-hover-border-color: #d49f00;
  --bs-btn-focus-shadow-rgb: 212, 159, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d49f00;
  --bs-btn-active-border-color: #d49f00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d49f00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d49f00;
  --bs-gradient: none;
}

.btn-outline-warning-8 {
  --bs-btn-color: #ac8000;
  --bs-btn-border-color: #ac8000;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ac8000;
  --bs-btn-hover-border-color: #ac8000;
  --bs-btn-focus-shadow-rgb: 172, 128, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ac8000;
  --bs-btn-active-border-color: #ac8000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ac8000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ac8000;
  --bs-gradient: none;
}

.btn-outline-warning-9 {
  --bs-btn-color: #886600;
  --bs-btn-border-color: #886600;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #886600;
  --bs-btn-hover-border-color: #886600;
  --bs-btn-focus-shadow-rgb: 136, 102, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #886600;
  --bs-btn-active-border-color: #886600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #886600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #886600;
  --bs-gradient: none;
}

.btn-outline-warning-dark {
  --bs-btn-color: #5f4700;
  --bs-btn-border-color: #5f4700;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5f4700;
  --bs-btn-hover-border-color: #5f4700;
  --bs-btn-focus-shadow-rgb: 95, 71, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #5f4700;
  --bs-btn-active-border-color: #5f4700;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5f4700;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5f4700;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #DC3545;
  --bs-btn-border-color: #DC3545;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #DC3545;
  --bs-btn-hover-border-color: #DC3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #DC3545;
  --bs-btn-active-border-color: #DC3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #DC3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #DC3545;
  --bs-gradient: none;
}

.btn-outline-danger-light {
  --bs-btn-color: #fae3e5;
  --bs-btn-border-color: #fae3e5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fae3e5;
  --bs-btn-hover-border-color: #fae3e5;
  --bs-btn-focus-shadow-rgb: 250, 227, 229;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fae3e5;
  --bs-btn-active-border-color: #fae3e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fae3e5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fae3e5;
  --bs-gradient: none;
}

.btn-outline-danger-1 {
  --bs-btn-color: #f4c0c5;
  --bs-btn-border-color: #f4c0c5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f4c0c5;
  --bs-btn-hover-border-color: #f4c0c5;
  --bs-btn-focus-shadow-rgb: 244, 192, 197;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f4c0c5;
  --bs-btn-active-border-color: #f4c0c5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f4c0c5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f4c0c5;
  --bs-gradient: none;
}

.btn-outline-danger-2 {
  --bs-btn-color: #efa2a9;
  --bs-btn-border-color: #efa2a9;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #efa2a9;
  --bs-btn-hover-border-color: #efa2a9;
  --bs-btn-focus-shadow-rgb: 239, 162, 169;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #efa2a9;
  --bs-btn-active-border-color: #efa2a9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #efa2a9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #efa2a9;
  --bs-gradient: none;
}

.btn-outline-danger-3 {
  --bs-btn-color: #e97f89;
  --bs-btn-border-color: #e97f89;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e97f89;
  --bs-btn-hover-border-color: #e97f89;
  --bs-btn-focus-shadow-rgb: 233, 127, 137;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e97f89;
  --bs-btn-active-border-color: #e97f89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e97f89;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e97f89;
  --bs-gradient: none;
}

.btn-outline-danger-4 {
  --bs-btn-color: #e4606d;
  --bs-btn-border-color: #e4606d;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e4606d;
  --bs-btn-hover-border-color: #e4606d;
  --bs-btn-focus-shadow-rgb: 228, 96, 109;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e4606d;
  --bs-btn-active-border-color: #e4606d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e4606d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e4606d;
  --bs-gradient: none;
}

.btn-outline-danger-5 {
  --bs-btn-color: #DC3545;
  --bs-btn-border-color: #DC3545;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #DC3545;
  --bs-btn-hover-border-color: #DC3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #DC3545;
  --bs-btn-active-border-color: #DC3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #DC3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #DC3545;
  --bs-gradient: none;
}

.btn-outline-danger-6 {
  --bs-btn-color: #bd2130;
  --bs-btn-border-color: #bd2130;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #bd2130;
  --bs-btn-hover-border-color: #bd2130;
  --bs-btn-focus-shadow-rgb: 189, 33, 48;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #bd2130;
  --bs-btn-active-border-color: #bd2130;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #bd2130;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #bd2130;
  --bs-gradient: none;
}

.btn-outline-danger-7 {
  --bs-btn-color: #9f1c28;
  --bs-btn-border-color: #9f1c28;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #9f1c28;
  --bs-btn-hover-border-color: #9f1c28;
  --bs-btn-focus-shadow-rgb: 159, 28, 40;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #9f1c28;
  --bs-btn-active-border-color: #9f1c28;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #9f1c28;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9f1c28;
  --bs-gradient: none;
}

.btn-outline-danger-8 {
  --bs-btn-color: #7c151f;
  --bs-btn-border-color: #7c151f;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #7c151f;
  --bs-btn-hover-border-color: #7c151f;
  --bs-btn-focus-shadow-rgb: 124, 21, 31;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #7c151f;
  --bs-btn-active-border-color: #7c151f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7c151f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7c151f;
  --bs-gradient: none;
}

.btn-outline-danger-9 {
  --bs-btn-color: #5e1018;
  --bs-btn-border-color: #5e1018;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #5e1018;
  --bs-btn-hover-border-color: #5e1018;
  --bs-btn-focus-shadow-rgb: 94, 16, 24;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #5e1018;
  --bs-btn-active-border-color: #5e1018;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5e1018;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5e1018;
  --bs-gradient: none;
}

.btn-outline-danger-dark {
  --bs-btn-color: #3b0a0f;
  --bs-btn-border-color: #3b0a0f;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #3b0a0f;
  --bs-btn-hover-border-color: #3b0a0f;
  --bs-btn-focus-shadow-rgb: 59, 10, 15;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #3b0a0f;
  --bs-btn-active-border-color: #3b0a0f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3b0a0f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3b0a0f;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #7F7F7F;
  --bs-btn-border-color: #7F7F7F;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #7F7F7F;
  --bs-btn-hover-border-color: #7F7F7F;
  --bs-btn-focus-shadow-rgb: 127, 127, 127;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #7F7F7F;
  --bs-btn-active-border-color: #7F7F7F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7F7F7F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7F7F7F;
  --bs-gradient: none;
}

.btn-outline-gray-light {
  --bs-btn-color: #e5e5e5;
  --bs-btn-border-color: #e5e5e5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e5e5e5;
  --bs-btn-hover-border-color: #e5e5e5;
  --bs-btn-focus-shadow-rgb: 229, 229, 229;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e5e5e5;
  --bs-btn-active-border-color: #e5e5e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e5e5e5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e5e5e5;
  --bs-gradient: none;
}

.btn-outline-gray-1 {
  --bs-btn-color: #d1d1d1;
  --bs-btn-border-color: #d1d1d1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d1d1d1;
  --bs-btn-hover-border-color: #d1d1d1;
  --bs-btn-focus-shadow-rgb: 209, 209, 209;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d1d1d1;
  --bs-btn-active-border-color: #d1d1d1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d1d1d1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d1d1d1;
  --bs-gradient: none;
}

.btn-outline-gray-2 {
  --bs-btn-color: #bfbfbf;
  --bs-btn-border-color: #bfbfbf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #bfbfbf;
  --bs-btn-hover-border-color: #bfbfbf;
  --bs-btn-focus-shadow-rgb: 191, 191, 191;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #bfbfbf;
  --bs-btn-active-border-color: #bfbfbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #bfbfbf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #bfbfbf;
  --bs-gradient: none;
}

.btn-outline-gray-3 {
  --bs-btn-color: #aaaaaa;
  --bs-btn-border-color: #aaaaaa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #aaaaaa;
  --bs-btn-hover-border-color: #aaaaaa;
  --bs-btn-focus-shadow-rgb: 170, 170, 170;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #aaaaaa;
  --bs-btn-active-border-color: #aaaaaa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #aaaaaa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #aaaaaa;
  --bs-gradient: none;
}

.btn-outline-gray-4 {
  --bs-btn-color: #999999;
  --bs-btn-border-color: #999999;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #999999;
  --bs-btn-hover-border-color: #999999;
  --bs-btn-focus-shadow-rgb: 153, 153, 153;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #999999;
  --bs-btn-active-border-color: #999999;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #999999;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #999999;
  --bs-gradient: none;
}

.btn-outline-gray-5 {
  --bs-btn-color: #7F7F7F;
  --bs-btn-border-color: #7F7F7F;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #7F7F7F;
  --bs-btn-hover-border-color: #7F7F7F;
  --bs-btn-focus-shadow-rgb: 127, 127, 127;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #7F7F7F;
  --bs-btn-active-border-color: #7F7F7F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7F7F7F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7F7F7F;
  --bs-gradient: none;
}

.btn-outline-gray-6 {
  --bs-btn-color: #666666;
  --bs-btn-border-color: #666666;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #666666;
  --bs-btn-hover-border-color: #666666;
  --bs-btn-focus-shadow-rgb: 102, 102, 102;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #666666;
  --bs-btn-active-border-color: #666666;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #666666;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #666666;
  --bs-gradient: none;
}

.btn-outline-gray-7 {
  --bs-btn-color: #545454;
  --bs-btn-border-color: #545454;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #545454;
  --bs-btn-hover-border-color: #545454;
  --bs-btn-focus-shadow-rgb: 84, 84, 84;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #545454;
  --bs-btn-active-border-color: #545454;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #545454;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #545454;
  --bs-gradient: none;
}

.btn-outline-gray-8 {
  --bs-btn-color: #3f3f3f;
  --bs-btn-border-color: #3f3f3f;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #3f3f3f;
  --bs-btn-hover-border-color: #3f3f3f;
  --bs-btn-focus-shadow-rgb: 63, 63, 63;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #3f3f3f;
  --bs-btn-active-border-color: #3f3f3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3f3f3f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3f3f3f;
  --bs-gradient: none;
}

.btn-outline-gray-9 {
  --bs-btn-color: #2d2d2d;
  --bs-btn-border-color: #2d2d2d;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #2d2d2d;
  --bs-btn-hover-border-color: #2d2d2d;
  --bs-btn-focus-shadow-rgb: 45, 45, 45;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #2d2d2d;
  --bs-btn-active-border-color: #2d2d2d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2d2d2d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2d2d2d;
  --bs-gradient: none;
}

.btn-outline-gray-dark {
  --bs-btn-color: #191919;
  --bs-btn-border-color: #191919;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #191919;
  --bs-btn-hover-border-color: #191919;
  --bs-btn-focus-shadow-rgb: 25, 25, 25;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #191919;
  --bs-btn-active-border-color: #191919;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #191919;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #191919;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #F8F9FA;
  --bs-btn-border-color: #F8F9FA;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F8F9FA;
  --bs-btn-hover-border-color: #F8F9FA;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F8F9FA;
  --bs-btn-active-border-color: #F8F9FA;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F8F9FA;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F8F9FA;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #FFFFFF;
  --bs-btn-border-color: #FFFFFF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFFFFF;
  --bs-btn-hover-border-color: #FFFFFF;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFFFFF;
  --bs-btn-active-border-color: #FFFFFF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFFFFF;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #666666;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #FFFFFF;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #666666;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 320px) {
  .dropdown-menu-xxs-start {
    --bs-position: start;
  }
  .dropdown-menu-xxs-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxs-end {
    --bs-position: end;
  }
  .dropdown-menu-xxs-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 568px) {
  .dropdown-menu-xs-start {
    --bs-position: start;
  }
  .dropdown-menu-xs-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xs-end {
    --bs-position: end;
  }
  .dropdown-menu-xs-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 667px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #aaaaaa;
  --bs-dropdown-bg: #3f3f3f;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #aaaaaa;
  --bs-dropdown-link-hover-color: #FFFFFF;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #FFFFFF;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #7F7F7F;
  --bs-dropdown-header-color: #7F7F7F;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #FFFFFF;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2845, 45, 45, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-xxxs,
.navbar > .container-xxs,
.navbar > .container-xs,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl,
.navbar > .container-xxxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 320px) {
  .navbar-expand-xxs {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxs .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxs .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxs .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxs .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxs .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxs .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxs .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxs .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxs .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 568px) {
  .navbar-expand-xs {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xs .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xs .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xs .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xs .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xs .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xs .navbar-toggler {
    display: none;
  }
  .navbar-expand-xs .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xs .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xs .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 667px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #FFFFFF;
  --bs-navbar-brand-color: #FFFFFF;
  --bs-navbar-brand-hover-color: #FFFFFF;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 667px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #FFFFFF;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #FFFFFF;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-primary-light {
  --bs-alert-color: var(--bs-primary-light-text-emphasis);
  --bs-alert-bg: var(--bs-primary-light-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-light-border-subtle);
  --bs-alert-link-color: var(--bs-primary-light-text-emphasis);
}

.alert-primary-1 {
  --bs-alert-color: var(--bs-primary-1-text-emphasis);
  --bs-alert-bg: var(--bs-primary-1-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-1-border-subtle);
  --bs-alert-link-color: var(--bs-primary-1-text-emphasis);
}

.alert-primary-2 {
  --bs-alert-color: var(--bs-primary-2-text-emphasis);
  --bs-alert-bg: var(--bs-primary-2-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-2-border-subtle);
  --bs-alert-link-color: var(--bs-primary-2-text-emphasis);
}

.alert-primary-3 {
  --bs-alert-color: var(--bs-primary-3-text-emphasis);
  --bs-alert-bg: var(--bs-primary-3-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-3-border-subtle);
  --bs-alert-link-color: var(--bs-primary-3-text-emphasis);
}

.alert-primary-4 {
  --bs-alert-color: var(--bs-primary-4-text-emphasis);
  --bs-alert-bg: var(--bs-primary-4-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-4-border-subtle);
  --bs-alert-link-color: var(--bs-primary-4-text-emphasis);
}

.alert-primary-5 {
  --bs-alert-color: var(--bs-primary-5-text-emphasis);
  --bs-alert-bg: var(--bs-primary-5-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-5-border-subtle);
  --bs-alert-link-color: var(--bs-primary-5-text-emphasis);
}

.alert-primary-6 {
  --bs-alert-color: var(--bs-primary-6-text-emphasis);
  --bs-alert-bg: var(--bs-primary-6-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-6-border-subtle);
  --bs-alert-link-color: var(--bs-primary-6-text-emphasis);
}

.alert-primary-7 {
  --bs-alert-color: var(--bs-primary-7-text-emphasis);
  --bs-alert-bg: var(--bs-primary-7-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-7-border-subtle);
  --bs-alert-link-color: var(--bs-primary-7-text-emphasis);
}

.alert-primary-8 {
  --bs-alert-color: var(--bs-primary-8-text-emphasis);
  --bs-alert-bg: var(--bs-primary-8-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-8-border-subtle);
  --bs-alert-link-color: var(--bs-primary-8-text-emphasis);
}

.alert-primary-9 {
  --bs-alert-color: var(--bs-primary-9-text-emphasis);
  --bs-alert-bg: var(--bs-primary-9-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-9-border-subtle);
  --bs-alert-link-color: var(--bs-primary-9-text-emphasis);
}

.alert-primary-dark {
  --bs-alert-color: var(--bs-primary-dark-text-emphasis);
  --bs-alert-bg: var(--bs-primary-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-dark-border-subtle);
  --bs-alert-link-color: var(--bs-primary-dark-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-secondary-light {
  --bs-alert-color: var(--bs-secondary-light-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-light-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-light-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-light-text-emphasis);
}

.alert-secondary-1 {
  --bs-alert-color: var(--bs-secondary-1-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-1-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-1-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-1-text-emphasis);
}

.alert-secondary-2 {
  --bs-alert-color: var(--bs-secondary-2-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-2-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-2-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-2-text-emphasis);
}

.alert-secondary-3 {
  --bs-alert-color: var(--bs-secondary-3-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-3-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-3-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-3-text-emphasis);
}

.alert-secondary-4 {
  --bs-alert-color: var(--bs-secondary-4-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-4-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-4-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-4-text-emphasis);
}

.alert-secondary-5 {
  --bs-alert-color: var(--bs-secondary-5-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-5-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-5-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-5-text-emphasis);
}

.alert-secondary-6 {
  --bs-alert-color: var(--bs-secondary-6-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-6-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-6-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-6-text-emphasis);
}

.alert-secondary-7 {
  --bs-alert-color: var(--bs-secondary-7-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-7-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-7-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-7-text-emphasis);
}

.alert-secondary-8 {
  --bs-alert-color: var(--bs-secondary-8-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-8-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-8-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-8-text-emphasis);
}

.alert-secondary-9 {
  --bs-alert-color: var(--bs-secondary-9-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-9-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-9-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-9-text-emphasis);
}

.alert-secondary-dark {
  --bs-alert-color: var(--bs-secondary-dark-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-dark-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-dark-text-emphasis);
}

.alert-tertiary {
  --bs-alert-color: var(--bs-tertiary-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-text-emphasis);
}

.alert-tertiary-light {
  --bs-alert-color: var(--bs-tertiary-light-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-light-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-light-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-light-text-emphasis);
}

.alert-tertiary-1 {
  --bs-alert-color: var(--bs-tertiary-1-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-1-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-1-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-1-text-emphasis);
}

.alert-tertiary-2 {
  --bs-alert-color: var(--bs-tertiary-2-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-2-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-2-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-2-text-emphasis);
}

.alert-tertiary-3 {
  --bs-alert-color: var(--bs-tertiary-3-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-3-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-3-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-3-text-emphasis);
}

.alert-tertiary-4 {
  --bs-alert-color: var(--bs-tertiary-4-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-4-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-4-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-4-text-emphasis);
}

.alert-tertiary-5 {
  --bs-alert-color: var(--bs-tertiary-5-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-5-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-5-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-5-text-emphasis);
}

.alert-tertiary-6 {
  --bs-alert-color: var(--bs-tertiary-6-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-6-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-6-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-6-text-emphasis);
}

.alert-tertiary-7 {
  --bs-alert-color: var(--bs-tertiary-7-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-7-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-7-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-7-text-emphasis);
}

.alert-tertiary-8 {
  --bs-alert-color: var(--bs-tertiary-8-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-8-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-8-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-8-text-emphasis);
}

.alert-tertiary-9 {
  --bs-alert-color: var(--bs-tertiary-9-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-9-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-9-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-9-text-emphasis);
}

.alert-tertiary-dark {
  --bs-alert-color: var(--bs-tertiary-dark-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-dark-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-dark-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-success-light {
  --bs-alert-color: var(--bs-success-light-text-emphasis);
  --bs-alert-bg: var(--bs-success-light-bg-subtle);
  --bs-alert-border-color: var(--bs-success-light-border-subtle);
  --bs-alert-link-color: var(--bs-success-light-text-emphasis);
}

.alert-success-1 {
  --bs-alert-color: var(--bs-success-1-text-emphasis);
  --bs-alert-bg: var(--bs-success-1-bg-subtle);
  --bs-alert-border-color: var(--bs-success-1-border-subtle);
  --bs-alert-link-color: var(--bs-success-1-text-emphasis);
}

.alert-success-2 {
  --bs-alert-color: var(--bs-success-2-text-emphasis);
  --bs-alert-bg: var(--bs-success-2-bg-subtle);
  --bs-alert-border-color: var(--bs-success-2-border-subtle);
  --bs-alert-link-color: var(--bs-success-2-text-emphasis);
}

.alert-success-3 {
  --bs-alert-color: var(--bs-success-3-text-emphasis);
  --bs-alert-bg: var(--bs-success-3-bg-subtle);
  --bs-alert-border-color: var(--bs-success-3-border-subtle);
  --bs-alert-link-color: var(--bs-success-3-text-emphasis);
}

.alert-success-4 {
  --bs-alert-color: var(--bs-success-4-text-emphasis);
  --bs-alert-bg: var(--bs-success-4-bg-subtle);
  --bs-alert-border-color: var(--bs-success-4-border-subtle);
  --bs-alert-link-color: var(--bs-success-4-text-emphasis);
}

.alert-success-5 {
  --bs-alert-color: var(--bs-success-5-text-emphasis);
  --bs-alert-bg: var(--bs-success-5-bg-subtle);
  --bs-alert-border-color: var(--bs-success-5-border-subtle);
  --bs-alert-link-color: var(--bs-success-5-text-emphasis);
}

.alert-success-6 {
  --bs-alert-color: var(--bs-success-6-text-emphasis);
  --bs-alert-bg: var(--bs-success-6-bg-subtle);
  --bs-alert-border-color: var(--bs-success-6-border-subtle);
  --bs-alert-link-color: var(--bs-success-6-text-emphasis);
}

.alert-success-7 {
  --bs-alert-color: var(--bs-success-7-text-emphasis);
  --bs-alert-bg: var(--bs-success-7-bg-subtle);
  --bs-alert-border-color: var(--bs-success-7-border-subtle);
  --bs-alert-link-color: var(--bs-success-7-text-emphasis);
}

.alert-success-8 {
  --bs-alert-color: var(--bs-success-8-text-emphasis);
  --bs-alert-bg: var(--bs-success-8-bg-subtle);
  --bs-alert-border-color: var(--bs-success-8-border-subtle);
  --bs-alert-link-color: var(--bs-success-8-text-emphasis);
}

.alert-success-9 {
  --bs-alert-color: var(--bs-success-9-text-emphasis);
  --bs-alert-bg: var(--bs-success-9-bg-subtle);
  --bs-alert-border-color: var(--bs-success-9-border-subtle);
  --bs-alert-link-color: var(--bs-success-9-text-emphasis);
}

.alert-success-dark {
  --bs-alert-color: var(--bs-success-dark-text-emphasis);
  --bs-alert-bg: var(--bs-success-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-success-dark-border-subtle);
  --bs-alert-link-color: var(--bs-success-dark-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-info-light {
  --bs-alert-color: var(--bs-info-light-text-emphasis);
  --bs-alert-bg: var(--bs-info-light-bg-subtle);
  --bs-alert-border-color: var(--bs-info-light-border-subtle);
  --bs-alert-link-color: var(--bs-info-light-text-emphasis);
}

.alert-info-1 {
  --bs-alert-color: var(--bs-info-1-text-emphasis);
  --bs-alert-bg: var(--bs-info-1-bg-subtle);
  --bs-alert-border-color: var(--bs-info-1-border-subtle);
  --bs-alert-link-color: var(--bs-info-1-text-emphasis);
}

.alert-info-2 {
  --bs-alert-color: var(--bs-info-2-text-emphasis);
  --bs-alert-bg: var(--bs-info-2-bg-subtle);
  --bs-alert-border-color: var(--bs-info-2-border-subtle);
  --bs-alert-link-color: var(--bs-info-2-text-emphasis);
}

.alert-info-3 {
  --bs-alert-color: var(--bs-info-3-text-emphasis);
  --bs-alert-bg: var(--bs-info-3-bg-subtle);
  --bs-alert-border-color: var(--bs-info-3-border-subtle);
  --bs-alert-link-color: var(--bs-info-3-text-emphasis);
}

.alert-info-4 {
  --bs-alert-color: var(--bs-info-4-text-emphasis);
  --bs-alert-bg: var(--bs-info-4-bg-subtle);
  --bs-alert-border-color: var(--bs-info-4-border-subtle);
  --bs-alert-link-color: var(--bs-info-4-text-emphasis);
}

.alert-info-5 {
  --bs-alert-color: var(--bs-info-5-text-emphasis);
  --bs-alert-bg: var(--bs-info-5-bg-subtle);
  --bs-alert-border-color: var(--bs-info-5-border-subtle);
  --bs-alert-link-color: var(--bs-info-5-text-emphasis);
}

.alert-info-6 {
  --bs-alert-color: var(--bs-info-6-text-emphasis);
  --bs-alert-bg: var(--bs-info-6-bg-subtle);
  --bs-alert-border-color: var(--bs-info-6-border-subtle);
  --bs-alert-link-color: var(--bs-info-6-text-emphasis);
}

.alert-info-7 {
  --bs-alert-color: var(--bs-info-7-text-emphasis);
  --bs-alert-bg: var(--bs-info-7-bg-subtle);
  --bs-alert-border-color: var(--bs-info-7-border-subtle);
  --bs-alert-link-color: var(--bs-info-7-text-emphasis);
}

.alert-info-8 {
  --bs-alert-color: var(--bs-info-8-text-emphasis);
  --bs-alert-bg: var(--bs-info-8-bg-subtle);
  --bs-alert-border-color: var(--bs-info-8-border-subtle);
  --bs-alert-link-color: var(--bs-info-8-text-emphasis);
}

.alert-info-9 {
  --bs-alert-color: var(--bs-info-9-text-emphasis);
  --bs-alert-bg: var(--bs-info-9-bg-subtle);
  --bs-alert-border-color: var(--bs-info-9-border-subtle);
  --bs-alert-link-color: var(--bs-info-9-text-emphasis);
}

.alert-info-dark {
  --bs-alert-color: var(--bs-info-dark-text-emphasis);
  --bs-alert-bg: var(--bs-info-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-info-dark-border-subtle);
  --bs-alert-link-color: var(--bs-info-dark-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-warning-light {
  --bs-alert-color: var(--bs-warning-light-text-emphasis);
  --bs-alert-bg: var(--bs-warning-light-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-light-border-subtle);
  --bs-alert-link-color: var(--bs-warning-light-text-emphasis);
}

.alert-warning-1 {
  --bs-alert-color: var(--bs-warning-1-text-emphasis);
  --bs-alert-bg: var(--bs-warning-1-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-1-border-subtle);
  --bs-alert-link-color: var(--bs-warning-1-text-emphasis);
}

.alert-warning-2 {
  --bs-alert-color: var(--bs-warning-2-text-emphasis);
  --bs-alert-bg: var(--bs-warning-2-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-2-border-subtle);
  --bs-alert-link-color: var(--bs-warning-2-text-emphasis);
}

.alert-warning-3 {
  --bs-alert-color: var(--bs-warning-3-text-emphasis);
  --bs-alert-bg: var(--bs-warning-3-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-3-border-subtle);
  --bs-alert-link-color: var(--bs-warning-3-text-emphasis);
}

.alert-warning-4 {
  --bs-alert-color: var(--bs-warning-4-text-emphasis);
  --bs-alert-bg: var(--bs-warning-4-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-4-border-subtle);
  --bs-alert-link-color: var(--bs-warning-4-text-emphasis);
}

.alert-warning-5 {
  --bs-alert-color: var(--bs-warning-5-text-emphasis);
  --bs-alert-bg: var(--bs-warning-5-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-5-border-subtle);
  --bs-alert-link-color: var(--bs-warning-5-text-emphasis);
}

.alert-warning-6 {
  --bs-alert-color: var(--bs-warning-6-text-emphasis);
  --bs-alert-bg: var(--bs-warning-6-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-6-border-subtle);
  --bs-alert-link-color: var(--bs-warning-6-text-emphasis);
}

.alert-warning-7 {
  --bs-alert-color: var(--bs-warning-7-text-emphasis);
  --bs-alert-bg: var(--bs-warning-7-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-7-border-subtle);
  --bs-alert-link-color: var(--bs-warning-7-text-emphasis);
}

.alert-warning-8 {
  --bs-alert-color: var(--bs-warning-8-text-emphasis);
  --bs-alert-bg: var(--bs-warning-8-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-8-border-subtle);
  --bs-alert-link-color: var(--bs-warning-8-text-emphasis);
}

.alert-warning-9 {
  --bs-alert-color: var(--bs-warning-9-text-emphasis);
  --bs-alert-bg: var(--bs-warning-9-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-9-border-subtle);
  --bs-alert-link-color: var(--bs-warning-9-text-emphasis);
}

.alert-warning-dark {
  --bs-alert-color: var(--bs-warning-dark-text-emphasis);
  --bs-alert-bg: var(--bs-warning-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-dark-border-subtle);
  --bs-alert-link-color: var(--bs-warning-dark-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-danger-light {
  --bs-alert-color: var(--bs-danger-light-text-emphasis);
  --bs-alert-bg: var(--bs-danger-light-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-light-border-subtle);
  --bs-alert-link-color: var(--bs-danger-light-text-emphasis);
}

.alert-danger-1 {
  --bs-alert-color: var(--bs-danger-1-text-emphasis);
  --bs-alert-bg: var(--bs-danger-1-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-1-border-subtle);
  --bs-alert-link-color: var(--bs-danger-1-text-emphasis);
}

.alert-danger-2 {
  --bs-alert-color: var(--bs-danger-2-text-emphasis);
  --bs-alert-bg: var(--bs-danger-2-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-2-border-subtle);
  --bs-alert-link-color: var(--bs-danger-2-text-emphasis);
}

.alert-danger-3 {
  --bs-alert-color: var(--bs-danger-3-text-emphasis);
  --bs-alert-bg: var(--bs-danger-3-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-3-border-subtle);
  --bs-alert-link-color: var(--bs-danger-3-text-emphasis);
}

.alert-danger-4 {
  --bs-alert-color: var(--bs-danger-4-text-emphasis);
  --bs-alert-bg: var(--bs-danger-4-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-4-border-subtle);
  --bs-alert-link-color: var(--bs-danger-4-text-emphasis);
}

.alert-danger-5 {
  --bs-alert-color: var(--bs-danger-5-text-emphasis);
  --bs-alert-bg: var(--bs-danger-5-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-5-border-subtle);
  --bs-alert-link-color: var(--bs-danger-5-text-emphasis);
}

.alert-danger-6 {
  --bs-alert-color: var(--bs-danger-6-text-emphasis);
  --bs-alert-bg: var(--bs-danger-6-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-6-border-subtle);
  --bs-alert-link-color: var(--bs-danger-6-text-emphasis);
}

.alert-danger-7 {
  --bs-alert-color: var(--bs-danger-7-text-emphasis);
  --bs-alert-bg: var(--bs-danger-7-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-7-border-subtle);
  --bs-alert-link-color: var(--bs-danger-7-text-emphasis);
}

.alert-danger-8 {
  --bs-alert-color: var(--bs-danger-8-text-emphasis);
  --bs-alert-bg: var(--bs-danger-8-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-8-border-subtle);
  --bs-alert-link-color: var(--bs-danger-8-text-emphasis);
}

.alert-danger-9 {
  --bs-alert-color: var(--bs-danger-9-text-emphasis);
  --bs-alert-bg: var(--bs-danger-9-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-9-border-subtle);
  --bs-alert-link-color: var(--bs-danger-9-text-emphasis);
}

.alert-danger-dark {
  --bs-alert-color: var(--bs-danger-dark-text-emphasis);
  --bs-alert-bg: var(--bs-danger-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-dark-border-subtle);
  --bs-alert-link-color: var(--bs-danger-dark-text-emphasis);
}

.alert-gray {
  --bs-alert-color: var(--bs-gray-text-emphasis);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text-emphasis);
}

.alert-gray-light {
  --bs-alert-color: var(--bs-gray-light-text-emphasis);
  --bs-alert-bg: var(--bs-gray-light-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-light-border-subtle);
  --bs-alert-link-color: var(--bs-gray-light-text-emphasis);
}

.alert-gray-1 {
  --bs-alert-color: var(--bs-gray-1-text-emphasis);
  --bs-alert-bg: var(--bs-gray-1-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-1-border-subtle);
  --bs-alert-link-color: var(--bs-gray-1-text-emphasis);
}

.alert-gray-2 {
  --bs-alert-color: var(--bs-gray-2-text-emphasis);
  --bs-alert-bg: var(--bs-gray-2-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-2-border-subtle);
  --bs-alert-link-color: var(--bs-gray-2-text-emphasis);
}

.alert-gray-3 {
  --bs-alert-color: var(--bs-gray-3-text-emphasis);
  --bs-alert-bg: var(--bs-gray-3-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-3-border-subtle);
  --bs-alert-link-color: var(--bs-gray-3-text-emphasis);
}

.alert-gray-4 {
  --bs-alert-color: var(--bs-gray-4-text-emphasis);
  --bs-alert-bg: var(--bs-gray-4-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-4-border-subtle);
  --bs-alert-link-color: var(--bs-gray-4-text-emphasis);
}

.alert-gray-5 {
  --bs-alert-color: var(--bs-gray-5-text-emphasis);
  --bs-alert-bg: var(--bs-gray-5-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-5-border-subtle);
  --bs-alert-link-color: var(--bs-gray-5-text-emphasis);
}

.alert-gray-6 {
  --bs-alert-color: var(--bs-gray-6-text-emphasis);
  --bs-alert-bg: var(--bs-gray-6-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-6-border-subtle);
  --bs-alert-link-color: var(--bs-gray-6-text-emphasis);
}

.alert-gray-7 {
  --bs-alert-color: var(--bs-gray-7-text-emphasis);
  --bs-alert-bg: var(--bs-gray-7-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-7-border-subtle);
  --bs-alert-link-color: var(--bs-gray-7-text-emphasis);
}

.alert-gray-8 {
  --bs-alert-color: var(--bs-gray-8-text-emphasis);
  --bs-alert-bg: var(--bs-gray-8-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-8-border-subtle);
  --bs-alert-link-color: var(--bs-gray-8-text-emphasis);
}

.alert-gray-9 {
  --bs-alert-color: var(--bs-gray-9-text-emphasis);
  --bs-alert-bg: var(--bs-gray-9-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-9-border-subtle);
  --bs-alert-link-color: var(--bs-gray-9-text-emphasis);
}

.alert-gray-dark {
  --bs-alert-color: var(--bs-gray-dark-text-emphasis);
  --bs-alert-bg: var(--bs-gray-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-dark-border-subtle);
  --bs-alert-link-color: var(--bs-gray-dark-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis);
}

.alert-black {
  --bs-alert-color: var(--bs-black-text-emphasis);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #FFFFFF;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #FFFFFF;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 320px) {
  .list-group-horizontal-xxs {
    flex-direction: row;
  }
  .list-group-horizontal-xxs > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxs > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxs > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxs > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxs > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 568px) {
  .list-group-horizontal-xs {
    flex-direction: row;
  }
  .list-group-horizontal-xs > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 667px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-primary-light {
  --bs-list-group-color: var(--bs-primary-light-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-light-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-light-text-emphasis);
}

.list-group-item-primary-1 {
  --bs-list-group-color: var(--bs-primary-1-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-1-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-1-text-emphasis);
}

.list-group-item-primary-2 {
  --bs-list-group-color: var(--bs-primary-2-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-2-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-2-text-emphasis);
}

.list-group-item-primary-3 {
  --bs-list-group-color: var(--bs-primary-3-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-3-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-3-text-emphasis);
}

.list-group-item-primary-4 {
  --bs-list-group-color: var(--bs-primary-4-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-4-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-4-text-emphasis);
}

.list-group-item-primary-5 {
  --bs-list-group-color: var(--bs-primary-5-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-5-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-5-text-emphasis);
}

.list-group-item-primary-6 {
  --bs-list-group-color: var(--bs-primary-6-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-6-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-6-text-emphasis);
}

.list-group-item-primary-7 {
  --bs-list-group-color: var(--bs-primary-7-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-7-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-7-text-emphasis);
}

.list-group-item-primary-8 {
  --bs-list-group-color: var(--bs-primary-8-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-8-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-8-text-emphasis);
}

.list-group-item-primary-9 {
  --bs-list-group-color: var(--bs-primary-9-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-9-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-9-text-emphasis);
}

.list-group-item-primary-dark {
  --bs-list-group-color: var(--bs-primary-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-dark-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-secondary-light {
  --bs-list-group-color: var(--bs-secondary-light-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-light-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-light-text-emphasis);
}

.list-group-item-secondary-1 {
  --bs-list-group-color: var(--bs-secondary-1-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-1-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-1-text-emphasis);
}

.list-group-item-secondary-2 {
  --bs-list-group-color: var(--bs-secondary-2-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-2-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-2-text-emphasis);
}

.list-group-item-secondary-3 {
  --bs-list-group-color: var(--bs-secondary-3-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-3-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-3-text-emphasis);
}

.list-group-item-secondary-4 {
  --bs-list-group-color: var(--bs-secondary-4-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-4-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-4-text-emphasis);
}

.list-group-item-secondary-5 {
  --bs-list-group-color: var(--bs-secondary-5-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-5-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-5-text-emphasis);
}

.list-group-item-secondary-6 {
  --bs-list-group-color: var(--bs-secondary-6-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-6-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-6-text-emphasis);
}

.list-group-item-secondary-7 {
  --bs-list-group-color: var(--bs-secondary-7-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-7-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-7-text-emphasis);
}

.list-group-item-secondary-8 {
  --bs-list-group-color: var(--bs-secondary-8-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-8-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-8-text-emphasis);
}

.list-group-item-secondary-9 {
  --bs-list-group-color: var(--bs-secondary-9-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-9-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-9-text-emphasis);
}

.list-group-item-secondary-dark {
  --bs-list-group-color: var(--bs-secondary-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-dark-text-emphasis);
}

.list-group-item-tertiary {
  --bs-list-group-color: var(--bs-tertiary-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-text-emphasis);
}

.list-group-item-tertiary-light {
  --bs-list-group-color: var(--bs-tertiary-light-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-light-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-light-text-emphasis);
}

.list-group-item-tertiary-1 {
  --bs-list-group-color: var(--bs-tertiary-1-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-1-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-1-text-emphasis);
}

.list-group-item-tertiary-2 {
  --bs-list-group-color: var(--bs-tertiary-2-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-2-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-2-text-emphasis);
}

.list-group-item-tertiary-3 {
  --bs-list-group-color: var(--bs-tertiary-3-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-3-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-3-text-emphasis);
}

.list-group-item-tertiary-4 {
  --bs-list-group-color: var(--bs-tertiary-4-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-4-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-4-text-emphasis);
}

.list-group-item-tertiary-5 {
  --bs-list-group-color: var(--bs-tertiary-5-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-5-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-5-text-emphasis);
}

.list-group-item-tertiary-6 {
  --bs-list-group-color: var(--bs-tertiary-6-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-6-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-6-text-emphasis);
}

.list-group-item-tertiary-7 {
  --bs-list-group-color: var(--bs-tertiary-7-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-7-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-7-text-emphasis);
}

.list-group-item-tertiary-8 {
  --bs-list-group-color: var(--bs-tertiary-8-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-8-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-8-text-emphasis);
}

.list-group-item-tertiary-9 {
  --bs-list-group-color: var(--bs-tertiary-9-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-9-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-9-text-emphasis);
}

.list-group-item-tertiary-dark {
  --bs-list-group-color: var(--bs-tertiary-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-dark-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-success-light {
  --bs-list-group-color: var(--bs-success-light-text-emphasis);
  --bs-list-group-bg: var(--bs-success-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-light-border-subtle);
  --bs-list-group-active-color: var(--bs-success-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-light-text-emphasis);
}

.list-group-item-success-1 {
  --bs-list-group-color: var(--bs-success-1-text-emphasis);
  --bs-list-group-bg: var(--bs-success-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-1-border-subtle);
  --bs-list-group-active-color: var(--bs-success-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-1-text-emphasis);
}

.list-group-item-success-2 {
  --bs-list-group-color: var(--bs-success-2-text-emphasis);
  --bs-list-group-bg: var(--bs-success-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-2-border-subtle);
  --bs-list-group-active-color: var(--bs-success-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-2-text-emphasis);
}

.list-group-item-success-3 {
  --bs-list-group-color: var(--bs-success-3-text-emphasis);
  --bs-list-group-bg: var(--bs-success-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-3-border-subtle);
  --bs-list-group-active-color: var(--bs-success-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-3-text-emphasis);
}

.list-group-item-success-4 {
  --bs-list-group-color: var(--bs-success-4-text-emphasis);
  --bs-list-group-bg: var(--bs-success-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-4-border-subtle);
  --bs-list-group-active-color: var(--bs-success-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-4-text-emphasis);
}

.list-group-item-success-5 {
  --bs-list-group-color: var(--bs-success-5-text-emphasis);
  --bs-list-group-bg: var(--bs-success-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-5-border-subtle);
  --bs-list-group-active-color: var(--bs-success-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-5-text-emphasis);
}

.list-group-item-success-6 {
  --bs-list-group-color: var(--bs-success-6-text-emphasis);
  --bs-list-group-bg: var(--bs-success-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-6-border-subtle);
  --bs-list-group-active-color: var(--bs-success-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-6-text-emphasis);
}

.list-group-item-success-7 {
  --bs-list-group-color: var(--bs-success-7-text-emphasis);
  --bs-list-group-bg: var(--bs-success-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-7-border-subtle);
  --bs-list-group-active-color: var(--bs-success-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-7-text-emphasis);
}

.list-group-item-success-8 {
  --bs-list-group-color: var(--bs-success-8-text-emphasis);
  --bs-list-group-bg: var(--bs-success-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-8-border-subtle);
  --bs-list-group-active-color: var(--bs-success-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-8-text-emphasis);
}

.list-group-item-success-9 {
  --bs-list-group-color: var(--bs-success-9-text-emphasis);
  --bs-list-group-bg: var(--bs-success-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-9-border-subtle);
  --bs-list-group-active-color: var(--bs-success-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-9-text-emphasis);
}

.list-group-item-success-dark {
  --bs-list-group-color: var(--bs-success-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-success-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-success-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-dark-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-info-light {
  --bs-list-group-color: var(--bs-info-light-text-emphasis);
  --bs-list-group-bg: var(--bs-info-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-light-border-subtle);
  --bs-list-group-active-color: var(--bs-info-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-light-text-emphasis);
}

.list-group-item-info-1 {
  --bs-list-group-color: var(--bs-info-1-text-emphasis);
  --bs-list-group-bg: var(--bs-info-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-1-border-subtle);
  --bs-list-group-active-color: var(--bs-info-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-1-text-emphasis);
}

.list-group-item-info-2 {
  --bs-list-group-color: var(--bs-info-2-text-emphasis);
  --bs-list-group-bg: var(--bs-info-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-2-border-subtle);
  --bs-list-group-active-color: var(--bs-info-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-2-text-emphasis);
}

.list-group-item-info-3 {
  --bs-list-group-color: var(--bs-info-3-text-emphasis);
  --bs-list-group-bg: var(--bs-info-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-3-border-subtle);
  --bs-list-group-active-color: var(--bs-info-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-3-text-emphasis);
}

.list-group-item-info-4 {
  --bs-list-group-color: var(--bs-info-4-text-emphasis);
  --bs-list-group-bg: var(--bs-info-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-4-border-subtle);
  --bs-list-group-active-color: var(--bs-info-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-4-text-emphasis);
}

.list-group-item-info-5 {
  --bs-list-group-color: var(--bs-info-5-text-emphasis);
  --bs-list-group-bg: var(--bs-info-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-5-border-subtle);
  --bs-list-group-active-color: var(--bs-info-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-5-text-emphasis);
}

.list-group-item-info-6 {
  --bs-list-group-color: var(--bs-info-6-text-emphasis);
  --bs-list-group-bg: var(--bs-info-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-6-border-subtle);
  --bs-list-group-active-color: var(--bs-info-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-6-text-emphasis);
}

.list-group-item-info-7 {
  --bs-list-group-color: var(--bs-info-7-text-emphasis);
  --bs-list-group-bg: var(--bs-info-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-7-border-subtle);
  --bs-list-group-active-color: var(--bs-info-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-7-text-emphasis);
}

.list-group-item-info-8 {
  --bs-list-group-color: var(--bs-info-8-text-emphasis);
  --bs-list-group-bg: var(--bs-info-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-8-border-subtle);
  --bs-list-group-active-color: var(--bs-info-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-8-text-emphasis);
}

.list-group-item-info-9 {
  --bs-list-group-color: var(--bs-info-9-text-emphasis);
  --bs-list-group-bg: var(--bs-info-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-9-border-subtle);
  --bs-list-group-active-color: var(--bs-info-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-9-text-emphasis);
}

.list-group-item-info-dark {
  --bs-list-group-color: var(--bs-info-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-info-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-info-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-dark-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-warning-light {
  --bs-list-group-color: var(--bs-warning-light-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-light-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-light-text-emphasis);
}

.list-group-item-warning-1 {
  --bs-list-group-color: var(--bs-warning-1-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-1-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-1-text-emphasis);
}

.list-group-item-warning-2 {
  --bs-list-group-color: var(--bs-warning-2-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-2-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-2-text-emphasis);
}

.list-group-item-warning-3 {
  --bs-list-group-color: var(--bs-warning-3-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-3-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-3-text-emphasis);
}

.list-group-item-warning-4 {
  --bs-list-group-color: var(--bs-warning-4-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-4-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-4-text-emphasis);
}

.list-group-item-warning-5 {
  --bs-list-group-color: var(--bs-warning-5-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-5-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-5-text-emphasis);
}

.list-group-item-warning-6 {
  --bs-list-group-color: var(--bs-warning-6-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-6-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-6-text-emphasis);
}

.list-group-item-warning-7 {
  --bs-list-group-color: var(--bs-warning-7-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-7-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-7-text-emphasis);
}

.list-group-item-warning-8 {
  --bs-list-group-color: var(--bs-warning-8-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-8-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-8-text-emphasis);
}

.list-group-item-warning-9 {
  --bs-list-group-color: var(--bs-warning-9-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-9-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-9-text-emphasis);
}

.list-group-item-warning-dark {
  --bs-list-group-color: var(--bs-warning-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-dark-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-danger-light {
  --bs-list-group-color: var(--bs-danger-light-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-light-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-light-text-emphasis);
}

.list-group-item-danger-1 {
  --bs-list-group-color: var(--bs-danger-1-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-1-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-1-text-emphasis);
}

.list-group-item-danger-2 {
  --bs-list-group-color: var(--bs-danger-2-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-2-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-2-text-emphasis);
}

.list-group-item-danger-3 {
  --bs-list-group-color: var(--bs-danger-3-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-3-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-3-text-emphasis);
}

.list-group-item-danger-4 {
  --bs-list-group-color: var(--bs-danger-4-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-4-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-4-text-emphasis);
}

.list-group-item-danger-5 {
  --bs-list-group-color: var(--bs-danger-5-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-5-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-5-text-emphasis);
}

.list-group-item-danger-6 {
  --bs-list-group-color: var(--bs-danger-6-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-6-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-6-text-emphasis);
}

.list-group-item-danger-7 {
  --bs-list-group-color: var(--bs-danger-7-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-7-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-7-text-emphasis);
}

.list-group-item-danger-8 {
  --bs-list-group-color: var(--bs-danger-8-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-8-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-8-text-emphasis);
}

.list-group-item-danger-9 {
  --bs-list-group-color: var(--bs-danger-9-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-9-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-9-text-emphasis);
}

.list-group-item-danger-dark {
  --bs-list-group-color: var(--bs-danger-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-dark-text-emphasis);
}

.list-group-item-gray {
  --bs-list-group-color: var(--bs-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-text-emphasis);
}

.list-group-item-gray-light {
  --bs-list-group-color: var(--bs-gray-light-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-light-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-light-text-emphasis);
}

.list-group-item-gray-1 {
  --bs-list-group-color: var(--bs-gray-1-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-1-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-1-text-emphasis);
}

.list-group-item-gray-2 {
  --bs-list-group-color: var(--bs-gray-2-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-2-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-2-text-emphasis);
}

.list-group-item-gray-3 {
  --bs-list-group-color: var(--bs-gray-3-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-3-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-3-text-emphasis);
}

.list-group-item-gray-4 {
  --bs-list-group-color: var(--bs-gray-4-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-4-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-4-text-emphasis);
}

.list-group-item-gray-5 {
  --bs-list-group-color: var(--bs-gray-5-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-5-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-5-text-emphasis);
}

.list-group-item-gray-6 {
  --bs-list-group-color: var(--bs-gray-6-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-6-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-6-text-emphasis);
}

.list-group-item-gray-7 {
  --bs-list-group-color: var(--bs-gray-7-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-7-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-7-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-7-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-7-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-7-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-7-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-7-text-emphasis);
}

.list-group-item-gray-8 {
  --bs-list-group-color: var(--bs-gray-8-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-8-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-8-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-8-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-8-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-8-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-8-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-8-text-emphasis);
}

.list-group-item-gray-9 {
  --bs-list-group-color: var(--bs-gray-9-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-9-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-9-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-9-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-9-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-9-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-9-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-9-text-emphasis);
}

.list-group-item-gray-dark {
  --bs-list-group-color: var(--bs-gray-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-dark-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis);
}

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text-emphasis);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-black-border-subtle);
  --bs-list-group-active-color: var(--bs-black-bg-subtle);
  --bs-list-group-active-bg: var(--bs-black-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-black-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 667px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 319.98px) {
  .modal-fullscreen-xxs-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxs-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxs-down .modal-header,
.modal-fullscreen-xxs-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxs-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 567.98px) {
  .modal-fullscreen-xs-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xs-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xs-down .modal-header,
.modal-fullscreen-xs-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xs-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 666.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1439.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header,
.modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFFFFF;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000000;
}

/* Page Styles */
/*Font*/
/*****************************************************************************************************/
/*Colors*/
/*****************************************************************************************************/
/*Font-Sizes*/
/*****************************************************************************************************/
/*Dimensions*/
/*****************************************************************************************************/
header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  max-width: 100%;
  z-index: 999;
  background-color: white;
  background-attachment: fixed;
  background-size: cover;
}

.nav-link.focused {
  font-weight: bold;
}

.skip-nav-link {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #916DD5;
  color: #FFFFFF;
  text-decoration: none;
  border: 2px solid #000;
  transition: background-color 1s ease;
  position: absolute;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.5333333333);
  transform: translateY(-120%);
}

.skip-nav-link:hover,
.skip-nav-link:focus {
  color: white;
  margin-left: 1vw;
  margin-top: 1vw;
  background-color: #000;
  transform: translateY(0);
}

.nav-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr max-content 1fr;
  display: grid;
}

@media (max-width: 1260px) {
  header {
    position: unset;
  }
}
.nav-side {
  display: flex;
  justify-content: space-around;
}

.nav-side-left {
  justify-content: end;
}

.nav-side-left > a {
  margin-inline: auto;
}

.nav-side-right {
  justify-content: start;
}

.nav-side-right > a {
  margin-inline: auto;
}

.nav-item {
  grid-column: span 2;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  text-transform: uppercase;
}

@media screen and (min-width: 1200px) {
  .navbar-nav .nav-link {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1350px) {
  .navbar-nav .nav-link {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 1450px) {
  .navbar-nav .nav-link {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1610px) {
  .navbar-nav .nav-link {
    font-size: 1.28rem;
  }
}
@media screen and (min-width: 1690px) {
  .navbar-nav .nav-link {
    font-size: 1.56rem;
    color: #000;
  }
}
.main-navbar {
  background-color: rgba(245, 245, 245, 0.6);
  padding-inline: 8vw;
  background-color: #FFFFFF;
}
.main-navbar .navbar-nav {
  height: 100%;
  list-style-type: none;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  height: 42px;
}

.nav-item a {
  color: #333333;
  font-weight: 300;
  font-size: 25px;
  font-family: Roboto Condensed, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, Proxima-Nova, Futura PT, Calibri, Helvetica, sans-serif;
  text-transform: uppercase;
  border-bottom: 3px solid transparent;
}
.nav-item a:hover {
  border-bottom: 3px solid transparent;
  color: #071630;
}

/*Mobile Menu*/
/*****************************************************************************************************/
.mobile-nav-menu {
  display: none;
  position: fixed;
  background-color: #916DD5;
  left: 0px;
  right: 0px;
  z-index: 999;
  flex-direction: column;
  height: 50vh;
  height: min-content;
  padding-block: 4rem;
}
.mobile-nav-menu .mobile-nav-item {
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 4vw;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mobile-nav-item.focused {
  text-align: center;
  width: 100%;
  color: #482e6e;
  font-size: 5vw;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1261px) {
  .mobile-nav-menu {
    display: none;
    position: unset;
  }
}
.mobile-close {
  display: none;
}

.mobile-button, .mobile-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 25px;
  width: 25px;
  padding-top: 0px;
  z-index: 1000;
  cursor: pointer;
}
.mobile-button img, .mobile-close img {
  width: 100%;
}

.mobile-brand {
  position: absolute;
  top: 2.5rem;
  left: calc(50% - 100px);
}

@media screen and (max-width: 1260px) {
  .main-navbar .navbar-nav {
    display: none;
  }
  header {
    background-color: transparent;
    background-image: none;
    height: 180px;
  }
}
@media screen and (max-width: 767px) {
  .navbar-brand img {
    max-width: 80%;
  }
}
body header .mobile-brand.dark,
body header .navbar-brand.dark {
  display: none;
}
body header .mobile-brand.light,
body header .navbar-brand.light {
  display: block;
}
body header .nav-link {
  color: #000;
  transition: color 200ms ease-in-out;
}

header .nav-link {
  color: #000;
}
header .nav-link:hover {
  filter: opacity(0.9);
}

@media (min-width: 992px) {
  header .nav-item + .nav-item {
    margin-inline-start: 1rem;
  }
}
/*Footer*/
/*****************************************************************************************************/
#footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 50px;
  margin-top: 0;
  max-width: 100vw;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#footer * {
  text-underline-position: under;
}
#footer .footer-link.focused {
  font-weight: bold;
}
#footer .footer-content {
  max-width: 100vw;
}
#footer a {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  transition: color 200ms ease-in-out;
}
#footer a:hover {
  filter: opacity(0.9);
  text-decoration: none;
}
#footer .footer-logo {
  width: 100%;
}
#footer .footer-logo img {
  height: 100px;
}
#footer .footer-links {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#footer .footer-links .footer-link {
  padding: 0 25px;
}
#footer .footer-copy p {
  font-size: 0.9375rem;
  color: #000;
  font-weight: 400;
  width: 100%;
  margin-bottom: 0px;
}
#footer .footer-copy a {
  font-size: 0.9375rem;
  font-weight: 400;
  text-decoration: underline;
}

@media screen and (max-width: 1060px) {
  #footer {
    height: 100px;
  }
  #footer .footer-content {
    max-width: 90vw;
  }
  #footer .footer-links {
    flex-direction: column;
  }
  #footer .footer-links .footer-link {
    padding: 0;
  }
  #footer .footer-copy p {
    width: 100%;
  }
}
body.light-bg footer#footer a:hover {
  filter: opacity(0.9);
}

/*General ***********************************************************************************/
/*Header ***********************************************************************************/
/*Home ***********************************************************************************/
/*Leadership ***********************************************************************************/
/*Leadership ***********************************************************************************/
/*Contact ***********************************************************************************/
/*RFP ***********************************************************************************/
/*Private Policy ***********************************************************************************/
/*Terms of Service ***********************************************************************************/
/*Terms of Service ***********************************************************************************/
/*Footer ***********************************************************************************/
/*Splash Page Styles*/
/*Mobile ***********************************************************************************/
/*Splash Page Styles*/
html {
  padding: 0;
  margin: 0;
  font-family: "motiva-sans";
  font-size: 15px;
  color: #1e2d47;
}

body {
  padding: 0;
  margin: 0;
  font-family: "motiva-sans";
  font-size: 15px;
  color: #1e2d47;
}

h2, .h2 {
  color: #fff;
  font-size: 52px;
  font-weight: bold;
}

h3, .h3 {
  color: #004088;
  font-size: 35px;
  font-weight: bold;
}

h4, .h4 {
  color: #004088;
  font-size: 22px;
}

h5, .h5 {
  color: #004088;
  font-size: 20px;
}

p a {
  color: #1E2D47;
}
p a:hover {
  color: #009ADE;
}
p a:active {
  color: #004088;
}

.small-container {
  max-width: 915px;
  margin: 0 auto;
}

.header-banner {
  width: 100%;
  height: 300px;
}
.header-banner h2, .header-banner .h2 {
  padding-top: 150px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.italicize {
  font-style: italic;
}

label {
  font-size: 20px;
  color: #004088;
}

.poseidon-btn {
  background-color: #004088;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
}
.poseidon-btn:hover {
  background-color: #668CB8;
  color: #fff;
}
.poseidon-btn:disabled {
  background-color: #B0B5BD;
  color: #fff;
}
.poseidon-btn:disabled:hover {
  background-color: #B0B5BD;
  color: #fff;
}

.poseidon-btn-bright {
  background-color: #009ADE;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
}
.poseidon-btn-bright:hover {
  background-color: #8BC1EB;
  color: #fff;
}
.poseidon-btn-bright:disabled {
  background-color: #B0B5BD;
}
.poseidon-btn-bright:disabled:hover {
  background-color: #B0B5BD;
}

.flex {
  display: flex;
}

.text-red {
  color: red;
}

.full-width {
  width: 100%;
}

#Header {
  position: fixed;
  top: 15px;
  left: 15px;
  right: 15px;
  background-color: #004088;
  z-index: 999;
}

nav a {
  color: #fff;
  border-bottom: 5px solid #004088;
}
nav a:hover {
  color: #fff;
  opacity: 0.75;
  border-bottom: 5px solid #FCD853;
}
nav .active a {
  border-bottom: 5px solid #FCD853;
}

.nav-item {
  padding: 0 15px;
}

.navbar-brand {
  width: 175px;
  height: auto;
}
.navbar-brand:hover {
  border-bottom: 5px solid #004088;
}
.navbar-brand img {
  max-height: 55px;
}

#MobileClose {
  display: none;
  height: 55px;
  width: 55px;
}

#MobileNav {
  display: none;
  background-color: rgba(0, 64, 136, 0.75);
  position: fixed;
  top: 114px;
  left: 0;
  right: 0;
}

#MobileMenu {
  height: 55px;
  width: 55px;
}

#HomeBanner {
  background: url("../images/Home/Home Page Main Image.jpg");
  background-size: cover;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#HomeBannerInfo {
  color: #004088;
  padding-bottom: 0px;
  padding-top: 90px;
  width: 100%;
}

.logo {
  width: 100%;
}
.logo img {
  width: 20%;
  max-width: 300px;
}

#LearnMore {
  margin-top: 10px;
}

#InfoBox {
  background-color: rgba(255, 255, 255, 0.75);
  width: 100%;
  max-width: 915px;
  padding-top: 50px;
  padding-bottom: 50px;
}
#InfoBox .register-here {
  font-size: 27px;
  color: #004088;
}
#InfoBox .register-here:hover {
  opacity: 0.75;
}

#About {
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.about-additional-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.about-additional-info > div {
  flex: 1;
}

.about-additional-info-logo {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.about-additional-info-logo > div {
  flex: 1;
}
.about-additional-info-logo img {
  width: 50%;
  height: auto;
}

.deerfield {
  margin-right: 30px;
}

.uc-san-diego {
  margin-left: 30px;
}

#FooterImage {
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
#FooterImage .footer-microscope {
  flex: 1;
  margin-right: 5px;
}
#FooterImage .footer-city {
  flex: 0.39;
  margin-left: 5px;
}

.footer-microscope img {
  width: 100%;
}

.footer-city img {
  width: 100%;
}

#LeadershipBanner {
  background-image: url("../images/Leadership/Leadership Banner.jpg");
  background-size: cover;
}

.read-more {
  color: #004088;
  border-bottom: 4px solid #FCD853;
  cursor: pointer;
}
.read-more:hover {
  color: #668cb8;
}

.members > .row {
  margin-top: 50px;
}

.member-info {
  height: 250px;
  min-height: 250px;
  overflow: hidden;
}
.member-info p {
  line-height: 2;
}

.member-image img {
  width: 347px;
  height: auto;
}

.members-mobile h3, .members-mobile .h3 {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  height: 145px;
}
.members-mobile img {
  width: 100%;
}
.members-mobile .row {
  margin-top: 50px;
}

.member-info-mobile {
  margin-top: 50px;
  text-align: center;
}

#NewsBanner {
  background-image: url("../images/News/News Banner.jpg");
  background-size: cover;
}

.Article1 {
  background-image: url("../images/News/article 1 cover photo.jpg");
  background-size: 100% 100%;
}

.Article2 {
  background-image: url("../images/News/Article Side Cover.png");
  background-size: 100% 100%;
}

.article-image img {
  max-width: 100%;
}

.article-option {
  height: 250px;
  margin-top: 25px;
  color: #fff;
  padding: 25px 15px;
  cursor: pointer;
}
.article-option h5, .article-option .h5 {
  color: #fff;
}

.article-wrapper h3 a, .article-wrapper .h3 a {
  color: #004088 !important;
  font-size: 35px;
  font-weight: bold;
}
.article-wrapper h3 a:hover, .article-wrapper .h3 a:hover {
  text-decoration: none;
}

.press-releases {
  padding-top: 25px;
  border-top: 5px solid #004088;
}

#ContactBanner {
  background-image: url("../images/Contact/Contact Us Banner.jpg");
  background-size: cover;
}

.g-recaptcha {
  display: inline-block;
}

#SubmitButton {
  margin-left: 25px;
}

.contact-form-error {
  display: none;
}

#Agree {
  display: inline-block;
  width: auto;
}

.agree-checkbox p {
  display: inline-block;
}

#RfpBanner {
  background-image: url("../images/Rfp/RFP Banner-17.jpg");
  background-size: cover;
}

#AltEmail {
  display: none;
}

#PpBanner {
  background-image: url("../images/PrivatePolicy/Privacy Policy Banner.jpg");
  background-size: cover;
}

#TosBanner {
  background-image: url("../images/TermsOfService/TermsofServiceBanner.jpg");
  background-size: cover;
}

#FaqBanner {
  background-image: url("../images/FAQ/FAQ Banner.jpg");
  background-size: cover;
}

.question-wrapper {
  margin-top: 50px;
}

#Footer {
  height: 140px;
  width: 100%;
  color: #fff;
  background-color: #004088;
  margin-top: 50px;
}

#FooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  align-content: center;
  min-height: 100%;
}

.footer-logo {
  width: 10%;
}
.footer-logo img {
  width: 100%;
  height: auto;
}

.footer-links {
  margin-top: 30px;
}
.footer-links a {
  color: #fff;
  padding: 0 15px;
}
.footer-links a:hover {
  text-decoration: none;
  opacity: 0.75;
}

.footer-link {
  font-weight: bold;
  text-decoration: underline;
}

.footer-text {
  margin-bottom: 20px;
}
.footer-text a {
  color: #fff;
}
.footer-text a:hover {
  opacity: 0.75;
}

#SplashBanner {
  background: url("../img/splash/PlaceholderBannerImage.png");
  background-size: cover;
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: white;
  font-size: 1.4vw;
}
#SplashBanner a {
  color: white;
  font-size: 1.4vw;
}
#SplashBanner h1, #SplashBanner .h1 {
  color: white;
  text-transform: uppercase;
  font-size: 3vw;
}

.pos-logo img {
  width: 26vw;
  margin-top: 10vh;
}

.pos-contact {
  color: white;
  font-size: 1.4vw;
  display: inline;
}

.sab-logo {
  color: white;
  font-size: 2vw;
}
.sab-logo img {
  width: 10vw;
  margin-top: 1vh;
}

.pos-footer {
  color: white;
  font-size: 0.8vw;
}

@media (max-width: 991px) {
  .deerfield {
    margin-right: 0;
    margin-left: 0;
  }
  .deerfield img {
    width: 50%;
  }
  .uc-san-diego {
    margin-right: 0;
    margin-left: 0;
  }
  .uc-san-diego img {
    width: 50%;
  }
  #HomeBanner {
    padding: 30px 15px;
  }
  .no-sm-container {
    max-width: 100%;
    padding: 15px 25px;
  }
  #Footer {
    height: 280px;
    text-align: center;
  }
  #FooterContainer {
    height: 280px;
    text-align: center;
  }
}
@media only screen and (max-device-width: 991px) {
  body {
    font-size: 2em;
  }
  h2, .h2 {
    color: #fff;
    font-size: 68px;
    font-weight: bold;
  }
  h3, .h3 {
    color: #004088;
    font-size: 52px;
    font-weight: bold;
  }
  h4, .h4 {
    color: #004088;
    font-size: 33px;
  }
  h5, .h5 {
    color: #004088;
    font-size: 30px;
  }
  label {
    font-size: 30px;
  }
  .p-lr-30-m {
    padding-left: 30px;
    padding-right: 30px;
  }
  .poseidon-btn {
    padding: 15px 45px;
    font-size: 30px;
  }
  .poseidon-btn-bright {
    padding: 15px 45px;
    font-size: 30px;
  }
  #Header {
    left: 0;
    right: 0;
    top: 0;
    height: 115px;
  }
  .nav-item {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 35px;
    width: 100%;
    text-align: center;
  }
  .navbar-nav {
    width: 100%;
    text-align: center;
  }
  .nav-link {
    width: 100%;
    text-align: center;
  }
  nav a {
    border: none;
  }
  nav a:hover {
    border: none;
  }
  nav .active a {
    border: none;
  }
  .navbar-brand {
    width: 300px;
    height: auto;
    margin-left: 15px;
  }
  #MobileMenu {
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
  #MobileClose {
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
  #HomeBanner {
    background-image: url("../images/Home/Home Page Main Image Mobile.jpg");
  }
  .logo img {
    width: 50%;
  }
  #About {
    height: 150vh;
    justify-content: center;
  }
  .deerfield {
    margin-top: 100px;
    margin-right: 0;
    margin-left: 0;
  }
  .about-additional-info-mobile img {
    width: 50%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .uc-san-diego {
    margin-right: 0;
    margin-left: 0;
  }
  #FooterImage {
    flex-direction: column;
  }
  #FooterImage .footer-city {
    margin: 0;
    margin-top: 50px;
    flex: 1;
    width: 100%;
  }
  #FooterImage .footer-microscope {
    flex: 1;
    width: 100%;
    margin: 0;
  }
  .article-option {
    width: 50%;
  }
  .press-releases {
    margin-top: 50px;
  }
  #Contact .italicize {
    text-align: center;
  }
  #Contact input {
    font-size: 30px;
    height: 60px;
  }
  #Contact textarea {
    font-size: 30px;
    height: 120px;
  }
  #Disclaimer .italicize {
    text-align: center;
  }
  #Footer {
    height: 280px;
    text-align: center;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-top: 10px;
  }
  .footer-links a {
    padding: 5px;
  }
  .footer-logo {
    width: 30%;
  }
  .footer-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  #SplashBanner {
    background-position: center;
    font-size: 16px;
  }
  #SplashBanner a {
    font-size: 26px;
  }
  #SplashBanner h1, #SplashBanner .h1 {
    font-size: 36px;
  }
  .pos-logo img {
    width: 50vw;
  }
  .pos-contact {
    font-size: 26px;
  }
  .sab-logo {
    color: white;
    font-size: 26px;
  }
  .sab-logo img {
    width: 20vw;
  }
  .pos-footer {
    color: white;
    font-size: 12px;
  }
}
@media screen and (max-height: 700px) {
  #HomeBanner {
    height: 150vh;
  }
}
@media only screen and (max-device-width: 767px) {
  #About {
    height: 150vh;
  }
}