/* Bootstrap */
@import './customize-bootstrap';
@import './bootstrap';

/* Page Styles */
@import 'site_variables';
@import 'header';
@import 'footer';

/*General ***********************************************************************************/
/*Header ***********************************************************************************/
/*Home ***********************************************************************************/
/*Leadership ***********************************************************************************/
/*Leadership ***********************************************************************************/
/*Contact ***********************************************************************************/
/*RFP ***********************************************************************************/
/*Private Policy ***********************************************************************************/
/*Terms of Service ***********************************************************************************/
/*Terms of Service ***********************************************************************************/
/*Footer ***********************************************************************************/
/*Splash Page Styles*/
/*Mobile ***********************************************************************************/
/*Splash Page Styles*/
html {
  padding: 0;
  margin: 0;
  font-family: "motiva-sans";
  font-size: 15px;
  color: #1e2d47;
}
body {
  padding: 0;
  margin: 0;
  font-family: "motiva-sans";
  font-size: 15px;
  color: #1e2d47;
}
h2 {
  color: #fff;
  font-size: 52px;
  font-weight: bold;
}
h3 {
  color: #004088;
  font-size: 35px;
  font-weight: bold;
}
h4 {
  color: #004088;
  font-size: 22px;
}
h5 {
  color: #004088;
  font-size: 20px;
}
p {
  a {
    color: #1E2D47;
    &:hover {
      color: #009ADE;
    }
    &:active {
      color: #004088;
    }
  }
}
.small-container {
  max-width: 915px;
  margin: 0 auto;
}
.header-banner {
  width: 100%;
  height: 300px;
  h2 {
    padding-top: 150px;
  }
}
.mt-25 {
  margin-top: 25px;
}
.mt-50 {
  margin-top: 50px;
}
.italicize {
  font-style: italic;
}
label {
  font-size: 20px;
  color: #004088;
}
.poseidon-btn {
  background-color: #004088;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
  &:hover {
    background-color: #668CB8;
    color: #fff;
  }
  &:disabled {
    background-color: #B0B5BD;
    color: #fff;
    &:hover {
      background-color: #B0B5BD;
      color: #fff;
    }
  }
}
.poseidon-btn-bright {
  background-color: #009ADE;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
  &:hover {
    background-color: #8BC1EB;
    color: #fff;
  }
  &:disabled {
    background-color: #B0B5BD;
    &:hover {
      background-color: #B0B5BD;
    }
  }
}
.flex {
  display: flex;
}
.text-red {
  color: red;
}
.full-width {
  width: 100%;
}
#Header {
  position: fixed;
  top: 15px;
  left: 15px;
  right: 15px;
  background-color: #004088;
  z-index: 999;
}
nav {
  a {
    color: #fff;
    border-bottom: 5px solid #004088;
    &:hover {
      color: #fff;
      opacity: 0.75;
      border-bottom: 5px solid #FCD853;
    }
  }
  .active {
    a {
      border-bottom: 5px solid #FCD853;
    }
  }
}
.nav-item {
  padding: 0 15px;
}
.navbar-brand {
  width: 175px;
  height: auto;
  &:hover {
    border-bottom: 5px solid #004088;
  }
  img {
    max-height: 55px;
  }
}
#MobileClose {
  display: none;
  height: 55px;
  width: 55px;
}
#MobileNav {
  display: none;
  background-color: rgba(0,64,136,0.75);
  position: fixed;
  top: 114px;
  left: 0;
  right: 0;
}
#MobileMenu {
  height: 55px;
  width: 55px;
}
#HomeBanner {
  background: url("../images/Home/Home Page Main Image.jpg");
  background-size: cover;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#HomeBannerInfo {
  color: #004088;
  padding-bottom: 0px;
  padding-top: 90px;
  width: 100%;
}
.logo {
  width: 100%;
  img {
    width: 20%;
    max-width: 300px;
  }
}
#LearnMore {
  margin-top: 10px;
}
#InfoBox {
  background-color: rgba(255,255,255,0.75);
  width: 100%;
  max-width: 915px;
  padding-top: 50px;
  padding-bottom: 50px;
  .register-here {
    font-size: 27px;
    color: #004088;
    &:hover {
      opacity: 0.75;
    }
  }
}
#About {
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.about-additional-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  >div {
    flex: 1;
  }
}
.about-additional-info-logo {
  display: flex;
  flex-direction: row;
  width: 100%;
  >div {
    flex: 1;
  }
  img {
    width: 50%;
    height: auto;
  }
}
.deerfield {
  margin-right: 30px;
}
.uc-san-diego {
  margin-left: 30px;
}
#FooterImage {
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  .footer-microscope {
    flex: 1;
    margin-right: 5px;
  }
  .footer-city {
    flex: 0.39;
    margin-left: 5px;
  }
}
.footer-microscope {
  img {
    width: 100%;
  }
}
.footer-city {
  img {
    width: 100%;
  }
}
#LeadershipBanner {
  background-image: url("../images/Leadership/Leadership Banner.jpg");
  background-size: cover;
}
.read-more {
  color: #004088;
  border-bottom: 4px solid #FCD853;
  cursor: pointer;
  &:hover {
    color: #668cb8;
  }
}
.members {
  >.row {
    margin-top: 50px;
  }
}
.member-info {
  height: 250px;
  min-height: 250px;
  overflow: hidden;
  p {
    line-height: 2;
  }
}
.member-image {
  img {
    width: 347px;
    height: auto;
  }
}
.members-mobile {
  h3 {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    height: 145px;
  }
  img {
    width: 100%;
  }
  .row {
    margin-top: 50px;
  }
}
.member-info-mobile {
  margin-top: 50px;
  text-align: center;
}
#NewsBanner {
  background-image: url("../images/News/News Banner.jpg");
  background-size: cover;
}
.Article1 {
  background-image: url("../images/News/article 1 cover photo.jpg");
  background-size: 100% 100%;
}
.Article2 {
  background-image: url("../images/News/Article Side Cover.png");
  background-size: 100% 100%;
}
.article-image {
  img {
    max-width: 100%;
  }
}
.article-option {
  height: 250px;
  margin-top: 25px;
  color: #fff;
  padding: 25px 15px;
  cursor: pointer;
  h5 {
    color: #fff;
  }
}
.article-wrapper {
  h3 {
    a {
      color: #004088 !important;
      font-size: 35px;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.press-releases {
  padding-top: 25px;
  border-top: 5px solid #004088;
}
#ContactBanner {
  background-image: url("../images/Contact/Contact Us Banner.jpg");
  background-size: cover;
}
.g-recaptcha {
  display: inline-block;
}
#SubmitButton {
  margin-left: 25px;
}
.contact-form-error {
  display: none;
}
#Agree {
  display: inline-block;
  width: auto;
}
.agree-checkbox {
  p {
    display: inline-block;
  }
}
#RfpBanner {
  background-image: url("../images/Rfp/RFP Banner-17.jpg");
  background-size: cover;
}
#AltEmail {
  display: none;
}
#PpBanner {
  background-image: url("../images/PrivatePolicy/Privacy Policy Banner.jpg");
  background-size: cover;
}
#TosBanner {
  background-image: url("../images/TermsOfService/TermsofServiceBanner.jpg");
  background-size: cover;
}
#FaqBanner {
  background-image: url("../images/FAQ/FAQ Banner.jpg");
  background-size: cover;
}
.question-wrapper {
  margin-top: 50px;
}
#Footer {
  height: 140px;
  width: 100%;
  color: #fff;
  background-color: #004088;
  margin-top: 50px;
}
#FooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  align-content: center;
  min-height: 100%;
}
.footer-logo {
  width: 10%;
  img {
    width: 100%;
    height: auto;
  }
}
.footer-links {
  margin-top: 30px;
  a {
    color: #fff;
    padding: 0 15px;
    &:hover {
      text-decoration: none;
      opacity: 0.75;
    }
  }
}
.footer-link {
  font-weight: bold;
  text-decoration: underline;
}
.footer-text {
  margin-bottom: 20px;
  a {
    color: #fff;
    &:hover {
      opacity: 0.75;
    }
  }
}
#SplashBanner {
  background: url("../img/splash/PlaceholderBannerImage.png");
  background-size: cover;
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: white;
  font-size: 1.4vw;
  a {
    color: white;
    font-size: 1.4vw;
  }
  h1 {
    color: white;
    text-transform: uppercase;
    font-size: 3vw;
  }
}
.pos-logo {
  img {
    width: 26vw;
    margin-top: 10vh;
  }
}
.pos-contact {
  color: white;
  font-size: 1.4vw;
  display: inline;
}
.sab-logo {
  img {
    width: 10vw;
    margin-top: 1vh;
  }
  color: white;
  font-size: 2vw;
}
.pos-footer {
  color: white;
  font-size: .8vw;
}
@media (max-width:991px) {
  .deerfield {
    margin-right: 0;
    margin-left: 0;
    img {
      width: 50%;
    }
  }
  .uc-san-diego {
    margin-right: 0;
    margin-left: 0;
    img {
      width: 50%;
    }
  }
  #HomeBanner {
    padding: 30px 15px;
  }
  .no-sm-container {
    max-width: 100%;
    padding: 15px 25px;
  }
  #Footer {
    height: 280px;
    text-align: center;
  }
  #FooterContainer {
    height: 280px;
    text-align: center;
  }
}
@media only screen and (max-device-width:991px) {
  body {
    font-size: 2em;
  }
  h2 {
    color: #fff;
    font-size: 68px;
    font-weight: bold;
  }
  h3 {
    color: #004088;
    font-size: 52px;
    font-weight: bold;
  }
  h4 {
    color: #004088;
    font-size: 33px;
  }
  h5 {
    color: #004088;
    font-size: 30px;
  }
  label {
    font-size: 30px;
  }
  .p-lr-30-m {
    padding-left: 30px;
    padding-right: 30px;
  }
  .poseidon-btn {
    padding: 15px 45px;
    font-size: 30px;
  }
  .poseidon-btn-bright {
    padding: 15px 45px;
    font-size: 30px;
  }
  #Header {
    left: 0;
    right: 0;
    top: 0;
    height: 115px;
  }
  .nav-item {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 35px;
    width: 100%;
    text-align: center;
  }
  .navbar-nav {
    width: 100%;
    text-align: center;
  }
  .nav-link {
    width: 100%;
    text-align: center;
  }
  nav {
    a {
      border: none;
      &:hover {
        border: none;
      }
    }
    .active {
      a {
        border: none;
      }
    }
  }
  .navbar-brand {
    width: 300px;
    height: auto;
    margin-left: 15px;
  }
  #MobileMenu {
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
  #MobileClose {
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
  #HomeBanner {
    background-image: url("../images/Home/Home Page Main Image Mobile.jpg");
  }
  .logo {
    img {
      width: 50%;
    }
  }
  #About {
    height: 150vh;
    justify-content: center;
  }
  .deerfield {
    margin-top: 100px;
    margin-right: 0;
    margin-left: 0;
  }
  .about-additional-info-mobile {
    img {
      width: 50%;
      height: auto;
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
  .uc-san-diego {
    margin-right: 0;
    margin-left: 0;
  }
  #FooterImage {
    flex-direction: column;
    .footer-city {
      margin: 0;
      margin-top: 50px;
      flex: 1;
      width: 100%;
    }
    .footer-microscope {
      flex: 1;
      width: 100%;
      margin: 0;
    }
  }
  .article-option {
    width: 50%;
  }
  .press-releases {
    margin-top: 50px;
  }
  #Contact {
    .italicize {
      text-align: center;
    }
    input {
      font-size: 30px;
      height: 60px;
    }
    textarea {
      font-size: 30px;
      height: 120px;
    }
  }
  #Disclaimer {
    .italicize {
      text-align: center;
    }
  }
  #Footer {
    height: 280px;
    text-align: center;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-top: 10px;
    a {
      padding: 5px;
    }
  }
  .footer-logo {
    width: 30%;
  }
  .footer-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  #SplashBanner {
    background-position: center;
    font-size: 16px;
    a {
      font-size: 26px;
    }
    h1 {
      font-size: 36px;
    }
  }
  .pos-logo {
    img {
      width: 50vw;
    }
  }
  .pos-contact {
    font-size: 26px;
  }
  .sab-logo {
    img {
      width: 20vw;
    }
    color: white;
    font-size: 26px;
  }
  .pos-footer {
    color: white;
    font-size: 12px;
  }
}
@media screen and (max-height: 700px) {
  #HomeBanner {
    height: 150vh;
  }
}
@media only screen and (max-device-width:767px) {
  #About {
    height: 150vh;
  }
}
